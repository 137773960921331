import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EstadisticasService {
  
  constructor(private http: HttpClient,
    private restangular: Restangular,) {}  
  
    obtenerTotalInstitucionesRegistrados(): Observable<any[]> {  
        return this.restangular.all('total-instituciones-registradas').get('');
    }
  
    obtenerTotalUsuariosRegistrados(): Observable<any[]> {  
        return this.restangular.all('total-usuarios-registrados').get('');
    }

    obtenerTotalCasosEvaluados(): Observable<any[]> {  
        return this.restangular.all('total-casos-evaluados').get('');
    }

    obtenerTotalCasos(): Observable<any[]> {  
        return this.restangular.all('total-casos').get('');
    }

    nombreRespuestaMayorPreguntaMultiple(numeroPregunta): Observable<any[]> {  
        return this.restangular.all('mayor-respuesta-pregunta-multiple/').get(numeroPregunta);
    }

    obtenerTotalRespuestaPregunta(numeroPregunta, numeroRespuesta): Observable<any[]> {  
        return this.restangular.all('total-respuesta-pregunta/').get(numeroPregunta+'/'+numeroRespuesta);
    }

    obtenerPromedioRespuestaPreguntaMultiple(numeroPregunta): Observable<any[]> {  
        return this.restangular.all('promedio-respuesta-pregunta-multiple/').get(numeroPregunta);
    }

    obtenerMayorRespuestaPreguntaMultiple(numeroPregunta): Observable<any[]> {  
        return this.restangular.all('mayor-respuesta-pregunta-multiple/').get(numeroPregunta);
    }

    obtenerPromedioRespuestaComplementaria(numeroPregunta): Observable<any[]> {  
        return this.restangular.all('promedio-respuesta-complementaria/').get(numeroPregunta);
    }

    obtenerPuntajePromedioDimensiones(): Observable<any[]> {  
        return this.restangular.all('puntaje-promedio-gestion-publica-local/').get('1');
    }

    obtenerPuntajePromedioEjeMedicion(dimension): Observable<any[]> {  
        return this.restangular.all('puntaje-promedio-eje-medicion/').get(dimension);
    }

    obtenerTotalRespuestaPreguntaPorRespuesta(numeroPregunta, numeroRespuesta): Observable<any[]> {  
        return this.restangular.all('total-respuesta-pregunta-por-respuesta/').get(numeroPregunta+'/'+numeroRespuesta);
    }
  
}