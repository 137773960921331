<div class="row">
  <div class="col-lg-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Basic accordions</h4>
        <p class="card-description">Use class <code>.accordion</code> for Basic Accordion</p>
        <ngb-accordion [closeOthers]="true" activeIds="static-1" class="accordion">
          <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
              <h6 class="mb-0">
                  How can I pay for an order I placed?
              </h6>
            </ng-template>
            <ng-template ngbPanelContent>
                You can pay for the product you have purchased using credit cards, debit cards, 
                or via online banking. We also provide cash-on-delivery services for most of our 
                products. You can also use your account wallet for payment.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
              <h6 class="mb-0">
                  I can’t sign in to my account
              </h6>
            </ng-template>
            <ng-template ngbPanelContent>
              If while signing in to your account you see an error message, you can do the following
              <ol class="pl-3 mt-4">
                <li>Check your network connection and try again</li>
                <li>Make sure your account credentials are correct while signing in</li>
                <li>Check whether your account is accessible in your region</li>
              </ol>
              <br>
              <p class="text-success">
                <i class="mdi mdi-alert-octagon mr-2"></i>If the problem persists, you can contact our support.
              </p>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3">
            <ng-template ngbPanelTitle>
              <h6 class="mb-0">
                  Can I add money to the wallet?
              </h6>
            </ng-template>
            <ng-template ngbPanelContent>
                You can add money to the wallet for any future transaction from your bank account using net-banking, or 
                credit/debit card transaction. The money in the wallet can be used for an easier and faster transaction.                
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
  <div class="col-lg-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Basic accordions</h4>
        <p class="card-description">Use class <code>.accordion-inverse-*</code> for inverse styling</p>
        <ngb-accordion [closeOthers]="true" activeIds="static-3" class="accordion accordion-inverse-primary">
          <ngb-panel id="static-4">
            <ng-template ngbPanelTitle>
              <h6 class="mb-0">
                  How can I pay for an order I placed?
              </h6>
            </ng-template>
            <ng-template ngbPanelContent>
                You can pay for the product you have purchased using credit cards, debit cards, 
                or via online banking. We also provide cash-on-delivery services for most of our 
                products. You can also use your account wallet for payment.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5">
            <ng-template ngbPanelTitle>
              <h6 class="mb-0">
                  I can’t sign in to my account
              </h6>
            </ng-template>
            <ng-template ngbPanelContent>
              If while signing in to your account you see an error message, you can do the following
              <ol class="pl-3 mt-4">
                <li>Check your network connection and try again</li>
                <li>Make sure your account credentials are correct while signing in</li>
                <li>Check whether your account is accessible in your region</li>
              </ol>
              <br>
              <p class="text-success">
                <i class="mdi mdi-alert-octagon mr-2"></i>If the problem persists, you can contact our support.
              </p>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5">
            <ng-template ngbPanelTitle>
              <h6 class="mb-0">
                  Can I add money to the wallet?
              </h6>
            </ng-template>
            <ng-template ngbPanelContent>
                You can add money to the wallet for any future transaction from your bank account using net-banking, or 
                credit/debit card transaction. The money in the wallet can be used for an easier and faster transaction.                
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
  <div class="col-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Basic accordions</h4>
        <p class="card-description">Use class <code>.bg-*</code> for <code>.card-header</code> element for filled header styling</p>
        <ngb-accordion [closeOthers]="true" activeIds="static-7" class="accordion accordion-danger">
          <ngb-panel id="static-7">
            <ng-template ngbPanelTitle>
              <h6 class="mb-0">
                  How can I pay for an order I placed?
              </h6>
            </ng-template>
            <ng-template ngbPanelContent>
                You can pay for the product you have purchased using credit cards, debit cards, 
                or via online banking. We also provide cash-on-delivery services for most of our 
                products. You can also use your account wallet for payment.
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-8">
            <ng-template ngbPanelTitle>
              <h6 class="mb-0">
                  I can’t sign in to my account
              </h6>
            </ng-template>
            <ng-template ngbPanelContent>
              If while signing in to your account you see an error message, you can do the following
              <ol class="pl-3 mt-4">
                <li>Check your network connection and try again</li>
                <li>Make sure your account credentials are correct while signing in</li>
                <li>Check whether your account is accessible in your region</li>
              </ol>
              <br>
              <p class="text-success">
                <i class="mdi mdi-alert-octagon mr-2"></i>If the problem persists, you can contact our support.
              </p>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-9">
            <ng-template ngbPanelTitle>
              <h6 class="mb-0">
                  Can I add money to the wallet?
              </h6>
            </ng-template>
            <ng-template ngbPanelContent>
                You can add money to the wallet for any future transaction from your bank account using net-banking, or 
                credit/debit card transaction. The money in the wallet can be used for an easier and faster transaction.                
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
</div>