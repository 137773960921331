import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { CasoInnovacionService } from "src/app/services/caso-innovacion.service";

@Injectable()  
export class DetalleCasoInnovacionCargarCasoResolve implements Resolve<any[]> {  

  constructor(private casoService: CasoInnovacionService,
              private route: ActivatedRoute) {
  }  
  
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {  
      console.log('En el resolve');
    return this.casoService.obtenerCaso(route.params['idCaso']);  
  }  
} 