<div class="row">
  <div class="col-md-4 col-sm-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Basic Paginations</h4>
        <p class="card-description">Default bordered pagination</p>
        <ngb-pagination [collectionSize]="40" [pageSize]="10" [page]="1" aria-label="Default pagination"></ngb-pagination>
      </div>
    </div>
  </div>
  <div class="col-md-4 col-sm-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Flat Pagination</h4>
        <p class="card-description">Add class <code>.pagination-flat</code></p>
        <ngb-pagination [collectionSize]="40" [pageSize]="10" [page]="1" aria-label="Default pagination" class="pagination-flat pagination-success"></ngb-pagination>        
      </div>
    </div>
  </div>
  <div class="col-md-4 col-sm-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Separated  Pagination</h4>
        <p class="card-description">Add class <code>.pagination-separated</code></p>
        <ngb-pagination [collectionSize]="40" [pageSize]="10" [page]="1" aria-label="Default pagination" class="pagination-separated pagination-danger"></ngb-pagination>
      </div>
    </div>
  </div>
  <div class="col-md-4 col-sm-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Bordered Rounded</h4>
        <p class="card-description">Add class <code>.pagination-rounded</code></p>
        <ngb-pagination [collectionSize]="40" [pageSize]="10" [page]="1" aria-label="Default pagination" class="pagination-rounded"></ngb-pagination>
      </div>
    </div>
  </div>
  <div class="col-md-4 col-sm-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Flat Rounded Pagination</h4>
        <p class="card-description">Add class <code>.pagination-rounded-flat</code></p>
        <ngb-pagination [collectionSize]="40" [pageSize]="10" [page]="1" aria-label="Default pagination" class="pagination-rounded-flat pagination-success"></ngb-pagination>
      </div>
    </div>
  </div>
  <div class="col-md-4 col-sm-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Separated Rounded</h4>
        <p class="card-description">Add class <code>.pagination-rounded-separated</code></p>
        <ngb-pagination [collectionSize]="40" [pageSize]="10" [page]="1" aria-label="Default pagination" class="pagination-rounded-separated pagination-danger"></ngb-pagination>
      </div>
    </div>
  </div>
  <div class="col-md-4 col-sm-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Left Position</h4>
        <p class="card-description">Left Pagination</p>
        <ngb-pagination [collectionSize]="40" [pageSize]="10" [page]="1" aria-label="Default pagination" class="d-flex justify-content-start"></ngb-pagination>
      </div>
    </div>
  </div>
  <div class="col-md-4 col-sm-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Center Position</h4>
        <p class="card-description">Centered Pagination</p>
        <ngb-pagination [collectionSize]="40" [pageSize]="10" [page]="1" aria-label="Default pagination" class="d-flex justify-content-center pagination-success"></ngb-pagination>
      </div>
    </div>
  </div>
  <div class="col-md-4 col-sm-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Right Position</h4>
        <p class="card-description">Right Pagination</p>
        <ngb-pagination [collectionSize]="40" [pageSize]="10" [page]="1" aria-label="Default pagination" class="d-flex justify-content-end pagination-danger"></ngb-pagination>
      </div>
    </div>
  </div>
</div>