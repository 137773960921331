<div class="container-xxl flex-grow-1 container-p-y">
              

  <div class="card">
    <div class="card-datatable table-responsive">
      <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
        <div class="row me-2">
          <div class="col-md-10">
            <div class="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0">
              <div class="dt-buttons btn-group flex-wrap">
                <button class="btn btn-secondary add-new btn-primary" 
                  (click)="crearCasoInnovacion()"
                        type="button">
                        <span>
                          <i class="ti ti-plus me-0 me-sm-1 ti-xs"></i>
                          <span class="d-none d-sm-inline-block">Ingresar caso de innovación</span>
                        </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <table class="datatables-users table dataTable no-footer dtr-column" id="DataTables_Table_0"
          aria-describedby="DataTables_Table_0_info" style="width: 1395px;">
        <thead class="border-top">
          <tr>
            <th class="sorting sorting_desc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
              style="width: 359px;" aria-label="Usuario: activate to sort column ascending" aria-sort="descending">Nombre Iniciativa</th>
            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style="width: 110px;"
              aria-label="Estado: activate to sort column ascending">Municipalidad</th>
              <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style="width: 110px;"
              aria-label="Estado: activate to sort column ascending">Fecha de creación</th>
              <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style="width: 110px;"
              aria-label="Estado: activate to sort column ascending">Estado</th>
            <th class="sorting_disabled" rowspan="1" colspan="1" style="width: 161px;" aria-label="Actions">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr class="odd" *ngFor="let caso of listadoCasos">
             <td><span class="fw-medium"> {{ caso.attributes.titulo | slice:0:50 }}   </span></td>
             <td><span class="fw-medium"> {{ caso.attributes.institucion[0].nombre }}  </span></td>
             <td><span class="fw-medium"> {{ caso.attributes.created_at | date:short}}   </span></td>
             <td><span class="badge bg-label-success" text-capitalized="">{{ caso.attributes.estado}}</span></td>
             <td>
                <div class="d-flex align-items-center">
                  <!-- <a class="text-body" (click)="editarMunicipalidad(institucion)"><i class="ti ti-edit ti-sm me-2"></i></a>
                  <a class="text-body delete-record" (click)="eliminarMunicipalidad(institucion)"><i class="ti ti-trash ti-sm mx-2"></i></a> -->
                  <a class="text-body delete-record" ngbTooltip="Detalle caso" (click)="abrirDetalleCasoModal(caso)"><i class="ti ti-plus ti-sm mx-2"></i></a>
                  <a class="text-body delete-record" data-bs-toggle="dropdown"><i class="ti ti-menu ti-sm mx-2"></i></a>
                  <ul class="dropdown-menu">
                    <li *ngIf="caso.attributes.estado == 'Nuevo'"><a class="dropdown-item" (click)="aprobarCaso(caso)">Admitir Caso</a></li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li *ngIf="caso.attributes.estado == 'En-validacion'"><a class="dropdown-item" (click)="enviarBancoExperiencias(caso)">Aceptar Caso</a></li>
                    <li *ngIf="caso.attributes.estado == 'En-validacion'"><a class="dropdown-item" (click)="enviarCasosEvaluados(caso)">Rechazar Caso</a></li>
                  </ul>
                </div>
             </td>
          </tr>
       </tbody>
      </table>
      </div>
    </div>
  </div>
</div>

<!-- <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Listado de casos</h4>
        <table class="table table-striped">
          <thead>
            <tr>
              <th> Usuario </th>
              <th> Nombre Iniciativa </th>
              <th> Progreso </th>
              <th> Municipalidad </th>
              <th> Fecha de creación </th>
              <th> Estado </th>
              <th> Acción </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let caso of listadoCasos">
              <td class="py-1">
                <img src="../../../assets/images/faces-clipart/pic-1.png" alt="image" />
              </td>
              <td class="title-table"> {{ caso.attributes.titulo | slice:0:50 }} </td>
              <td>
                <div class="progress">
                  <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </td>
              <td> {{ caso.attributes.institucion[0].nombre}} </td>
              <td> {{ caso.attributes.created_at | date:short}} </td>
              <td> {{ caso.attributes.estado}} </td>
              <td> 
                <div class="dropdown">
                    <button class="btn btn-warning icon-btn dropdown-toggle" type="button" id="dropdownMenuIconButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="mdi mdi-earth"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                      <h6 class="dropdown-header">Cambio estado</h6>
                      <a class="dropdown-item" href="#">Admitir caso</a>
                      <a class="dropdown-item" href="#">Aprobar caso</a>
                      <div class="dropdown-divider"></div>
                      <h6 class="dropdown-header">Enviar caso</h6>
                      <a class="dropdown-item" href="#">Casos evaluados</a>
                      <a class="dropdown-item" href="#">Banco de experiencias</a>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item" href="#">Encuesta de la iniciativa</a>
                    </div>
                  </div>
                </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    </div>
  </div> -->