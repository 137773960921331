import { ThrowStmt } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { Restangular } from "ngx-restangular";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {

  datosUsuario = {
    'nombres': '',
    'apellidos': '',
    'run': '',
    'institucion': ''
  };

  model: any[] = [];
  model2: any[] = [];
  menus: any[] = [];
  hijos: any[] = [];
  event: any;

  constructor(private restangular: Restangular) {}

  ngOnInit() {
    this.datosUsuario.nombres = JSON.parse(localStorage.getItem('usuario')).nombres;
        this.datosUsuario.apellidos = JSON.parse(localStorage.getItem('usuario')).apellidos;
        this.datosUsuario.run = JSON.parse(localStorage.getItem('usuario')).rut;
        this.datosUsuario.institucion = JSON.parse(localStorage.getItem('institucion')).nombre;

    const body = document.querySelector("body");

    this.cargarMenus();

    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    /*document.querySelectorAll(".sidebar .nav-item").forEach(function(el) {
      el.addEventListener("mouseover", function() {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function() {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });*/
    
  }

  /*public parentId = "";
  clickedMenu(event, menu) {
    var target = event.currentTarget;
    this.event = menu.icon;
    console.log(this.event);
    console.log(target.id);
    let parentId = target.id;
    if (parentId == this.parentId) {
      console.log('same');
      this.parentId = "";
    } else {
      console.log('not same');
      this.parentId = target.id;
    }
  }*/

  public cargarMenus(){
    let idUsuario = JSON.parse(localStorage.getItem('usuario')).id;
        let idAplicativo = localStorage.getItem('idAplicativo');
        console.log(idUsuario);
        this.restangular.all('usuario/menus/' + idUsuario+'/1').get('').subscribe(
            data => {
                console.log(data);
                this.menus = data;
                this.menus.forEach( menu => {
                  let menuTemp: any = {
                    // @ts-ignore
                    id: menu.id,
                    idPadre: menu.id_padre,
                    label: menu.nombre,
                    icon: menu.icono,
                    ruta: menu.ruta,
                    routerLink: [
                        menu.ruta
                    ]
                };
                  if(menu.tipo == 'PADRE'){
                      this.model.push(menuTemp);
                  } else {
                    this.hijos.push(menuTemp);
                  }

                });

                this.model.forEach(padre => {
                  this.hijos.sort((a, b) => (a.orden > b.orden) ? 1 : -1).forEach(hijo => {
                      if (padre.id == hijo.idPadre) {
                          if (!padre.items) {
                              padre.items = [];
                          }

                          let link2 = hijo.routerLink ? hijo.routerLink : '';
                          let tempMenu = {
                              label: hijo.label,
                              icon: hijo.icon,
                              routerLink: link2
                          };
                          padre.items.push(tempMenu);
                      }
                  });
              });
                console.log(this.model);
      });
  }
}
