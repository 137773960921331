
<div class="col-md-12 grid-margin stretch-card">
    <div class="card">
        <div class="card-body">
            <ngb-tabset class="tab-solid tab-solid-primary">
                <ngb-tab>
                    <ng-template ngbTabTitle>
                        <i class="mdi mdi-home-outline"></i>Cuestionario
                    </ng-template>
                    <ng-template ngbTabContent>
                        <div class="row">
                            <div class="col-md-12 d-flex align-items-stretch grid-margin">
                                <div class="row flex-grow">
                                    <div class="col-12 grid-margin">
                                        <div class="card">
                                            <div class="card-body">
                                                <form class="forms-sample">
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">¿Existe en su institución un
                                                            equipo o funcionario encargado (formalmente) de llevar a
                                                            cabo las iniciativas internas?</label>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio"
                                                                name="flexRadioDefault" id="flexRadioDefault1">
                                                            <label class="form-check-label" for="flexRadioDefault1">
                                                                Si
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio"
                                                                name="flexRadioDefault" id="flexRadioDefault2" checked>
                                                            <label class="form-check-label" for="flexRadioDefault2">
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="exampleTextarea1">Si su respuesta es "Si", declare
                                                            el nombre del área, equipo o funcionario. En caso de ser un
                                                            equipo, detalle a los miebros con nombre y profesión</label>
                                                        <textarea class="form-control" id="exampleTextarea1"
                                                            rows="4"></textarea>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="exampleInputName1">Si su respuesta es "No", ¿Quién
                                                            lidera las iniciativas de innovación?</label>
                                                        <input type="text" class="form-control" id="exampleInputName1"
                                                            placeholder="">
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">¿Existen instancias de
                                                            incorporación de diversos actiores internos y externos al
                                                            comenzar un proceso de innovación?</label>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio"
                                                                name="flexRadioDefault" id="flexRadioDefault1">
                                                            <label class="form-check-label"
                                                                for="flexRadioDefault1">Si</label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio"
                                                                name="flexRadioDefault" id="flexRadioDefault2" checked>
                                                            <label class="form-check-label"
                                                                for="flexRadioDefault2">No</label>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="exampleInputEmail1">¿Existen instancias formales
                                                            establecidas de incorporación de ciudadanos?</label>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio"
                                                                name="flexRadioDefault" id="flexRadioDefault1">
                                                            <label class="form-check-label"
                                                                for="flexRadioDefault1">Si</label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio"
                                                                name="flexRadioDefault" id="flexRadioDefault2" checked>
                                                            <label class="form-check-label"
                                                                for="flexRadioDefault2">No</label>
                                                        </div>
                                                    </div>
                                                    <ngb-pagination [collectionSize]="40" [pageSize]="10" [page]="1"
                                                        aria-label="Default pagination"
                                                        class="pagination-rounded d-flex justify-content-center">
                                                    </ngb-pagination>
                                                    <!-- <button type="submit"
                                                        class="btn btn-primary btn-fw">Siguiente</button> -->
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-template>
                </ngb-tab>
                <ngb-tab>
                    <ng-template ngbTabTitle>
                        <i class="mdi mdi-account-outline"></i>Evaluacion
                    </ng-template>
                    <ng-template ngbTabContent>
                        <div class="row">
                            <div class="col-6 col-md-6">
                                <div class="card">
                                    <div class="card-body d-flex flex-column">
                                        <div class="wrapper">
                                            <div class="mb-4" id="net-profit-legend">
                                                <div class="chartjs-legend">
                                                    <ul style="font-size: 10px;">
                                                        <li style="margin-right: 2%;"><span
                                                                style="background-color:rgba(53, 137, 238)"></span>Gestión publica
                                                        </li>
                                                        <li style="margin-right: 2%;"><span
                                                                style="background-color:rgba(209, 106, 64)"></span>Desarrollo territorial
                                                        </li>
                                                        <li style="margin-right: 2%;"><span
                                                            style="background-color:rgba(107, 211, 62 )"></span>Gobernanza democrática
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <canvas class="my-auto mx-auto" height="250" baseChart [chartType]="'radar'"
                                            [datasets]="netProfitData" [labels]="netProfitLabels"
                                            [options]="netProfitOptions" [legend]="false" [colors]="netProfitColors">
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-6">
                              <div class="row">
                                <div class="col-12">
                                    <div class="card" style="margin-bottom: 15px; padding: 0px;">
                                        <div class="card-body" style="padding: 0.9rem;">
                                            <h4 class="card-title" style="margin-bottom: 5px;">Gestión pública</h4>
                                            <div class="media">
                                                <i
                                                    class="mdi mdi-human-greeting icon-md text-info d-flex align-self-start mr-3"></i>
                                                <div class="media-body">
                                                    <p class="card-text">Praesent sit amet nunc in elit laoreet rhoncus.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card" style="margin-bottom: 15px">
                                        <div class="card-body" style="padding: 0.9rem;">
                                            <h4 class="card-title" style="margin-bottom: 5px;">Desarrollo territorial</h4>
                                            <div class="media">
                                                <i
                                                    class="mdi mdi-terrain icon-md text-info d-flex align-self-start mr-3"></i>
                                                <div class="media-body">
                                                    <p class="card-text">Praesent sit amet nunc in elit laoreet rhoncus. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card" style="margin-bottom: 15px">
                                        <div class="card-body" style="padding: 0.9rem;">
                                            <h4 class="card-title" style="margin-bottom: 5px;">Gobernanza democrática</h4>
                                            <div class="media">
                                                <i
                                                    class="mdi mdi-account-multiple-outline icon-md text-info d-flex align-self-start mr-3"></i>
                                                <div class="media-body">
                                                    <p class="card-text">Praesent sit amet nunc in elit laoreet rhoncus. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                    </ng-template>
                </ngb-tab>
                <ngb-tab>
                    <ng-template ngbTabTitle>
                        <i class="mdi mdi-message-text-outline"></i>Fin
                    </ng-template>
                    <ng-template ngbTabContent>
                        <div class="col-md-12 grid-margin stretch-card">
                            <div class="card">
                              <div class="card-body">
                                <h4 class="card-title" style="text-transform: none;">Se ha ingresado el caso correctamente</h4>
                                <p>
                                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                  text ever since the 1500s, when an unknown printer took a galley not only five centuries,
                                </p>
                                <button type="button" class="btn btn-primary btn-rounded btn-fw" routerLink="/casos-innovacion">Finalizar</button>
                              </div>
                            </div>
                          </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </div>
</div>