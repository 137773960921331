<!-- Content -->

<div class="authentication-wrapper authentication-cover authentication-bg">
  <div class="authentication-inner row">
    <!-- /Left Text -->
    <div class="d-none d-lg-flex col-lg-7 p-0">
      <div class="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
        <img
          src="../../../assets/imgs/labubb-login.jpg" style="border-radius: 20px;"
          alt="auth-login-cover"
          class="img-fluid my-1 auth-illustration"
          data-app-light-img="illustrations/auth-login-illustration-light.png"
          data-app-dark-img="illustrations/auth-login-illustration-dark.png" />

        
      </div>
    </div>
    <!-- /Left Text -->

    <!-- Login -->
    <div class="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
      <div class="w-px-400 mx-auto">
        <!-- Logo -->
        <div class="app-brand mb-4">
        <img src="../../../assets/imgs/logolab-transparente.png" width="260" height="auto" alt=""/> </div>
        <!-- /Logo -->
        <h3 class="mb-1 fw-bold">Bienvenido a la Plataforma de Métrica de Innovación Pública</h3>
        <p class="mb-4">Ingresa con tu usuario y contraseña</p>

        <form id="formAuthentication" class="mb-3" action="html/vertical-menu-template-no-customizer/index.html" method="POST">
          <div class="mb-3">
            <label for="email" class="form-label">Correo o usuario</label>
            <input
              type="text"
              class="form-control"
              placeholder="XXXXXXXX-X"
              id="username" 
              name="username" 
              [(ngModel)]="loginDatos.rut"
              autofocus />
          </div>
          <div class="mb-3 form-password-toggle">
            <div class="d-flex justify-content-between">
              <label class="form-label" for="password">Contraseña</label>
            </div>
            <div class="input-group input-group-merge">
              <input
                [type]="password"
                class="form-control"
                placeholder="*********"  
                id="password" 
                name="password" 
                [(ngModel)]="loginDatos.contrasena"
                aria-describedby="password" />
                <!-- <button (click)="mostrarContrasena()">
                  <i class="ti ti-eye" aria-hidden="true" *ngIf="!showPassword"></i>
                  <i class="ti ti-eye-off" aria-hidden="true" *ngIf="showPassword"></i>
                </button> -->
                
              <span aria-hidden="true" (click)="mostrarContrasena()" class="input-group-text cursor-pointer"><i aria-hidden="true" class="ti ti-eye" *ngIf="!showPassword"></i></span>
              <span aria-hidden="true" (click)="mostrarContrasena()" class="input-group-text cursor-pointer"><i aria-hidden="true" class="ti ti-eye-off" *ngIf="showPassword"></i></span>
            </div>
          </div>
          <button class="btn btn-primary d-grid w-100" (click)="login()">Ingresar</button>
          <br>
          <button class="btn btn-primary d-grid w-100" (click)="loginInvitado()">Ingresar como invitado</button>
        </form>

        <p class="text-center">
          <span>¿Nuevo en la plataforma?</span>
          <a (click)="abrirRegistroUsuario()">
            <span class="cursor-pointer"> Crear una cuenta</span>
          </a>
        </p>

     
      </div>
    </div>
    <!-- /Login -->
  </div>
</div>

<!-- / Content -->
  