import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';
import { Comentario } from 'src/app/api/modelos/Comentario';
import { PuntajeCasoComponent } from '../puntaje-caso/puntaje-caso.component';

@Component({
  selector: 'app-detalle-caso-innovacion',
  templateUrl: './detalle-caso-innovacion.component.html',
  styleUrls: ['./detalle-caso-innovacion.component.scss']
})
export class DetalleCasoInnovacionComponent implements OnInit {

  casoInnovacion: any;
  idCaso: any;

  nuevoComentario: Comentario = {
    titulo: '',
    detalle: '',
    estado: 'S',
    usuario_id: null,
    caso_innovacion_id: null
  };

  rolesUsuario: any[];

  listadoComentarios: any[];

  comentarioBase: Restangular;
  idUsuario: any;

  constructor(private route: ActivatedRoute,
    private restangular: Restangular,
    private toastr: ToastrService,
    private modalService: NgbModal, 
    public activeModal: NgbActiveModal) { 
     
  }

  ngOnInit(): void {

    this.casoInnovacion = this.route.snapshot.data.casoInnovacion.data[0];
    this.rolesUsuario = this.route.snapshot.data.rolesUsuario.data;
    this.comentarioBase = this.restangular.one('comentarios');
    this.idUsuario = JSON.parse(localStorage.getItem('usuario')).id;
    this.cargarRolUsuario();
    
      this.cargarComentarios();
      
  }

  cargarRolUsuario(){
    this.restangular.all('rolusuario?filter[usuario_id]='+this.idUsuario).get('').subscribe(data => {
      this.rolesUsuario = data.data;
      console.log(this.rolesUsuario);
    })
  }

  verPuntajeCasoModal(){
    const modalRef = this.modalService.open(PuntajeCasoComponent, {
      size: 'xl',
      centered: true
    });

    modalRef.componentInstance.casoInnovacion = this.casoInnovacion;

    modalRef.result.then( result => {
      console.log('Se ha cerrado el modal');
      //this.cargarMunicipalidades();
    })
  }

  guardarComentario(){
    let jsonApiWrapper = {
      data: {
          type: 'comentarios',
          attributes: {
              titulo: this.nuevoComentario.titulo,
              detalle: this.nuevoComentario.detalle,
              estado: 'S',
              usuario_id: JSON.parse(localStorage.getItem('usuario')).id,
              caso_innovacion_id: this.casoInnovacion.id
          }
        }
    };

    this.comentarioBase.post('', jsonApiWrapper).subscribe(
      caso => {
            //this.nuevaInstitucionCreada = caso.data;
            
            this.toastr.success('Se ha guardado correctamente el comentario', 'Ok');
            this.cargarComentarios();
            this.nuevoComentario = {
              titulo: '',
              detalle: '',
              estado: 'S',
              usuario_id: null,
              caso_innovacion_id: null
            };
            this.restangular.all('send-email/comentario-banco-experiencias').get('').subscribe(data => {
            });
      },
          error => {
            this.toastr.error('Hubo un error al guardar el comentario', 'Error');
          }
        );

  }

  cargarComentarios(){
    this.restangular.all('comentarios?filter[estado]=S&&filter[caso_innovacion_id]='+this.casoInnovacion.id).get('').subscribe(data => {
      this.listadoComentarios = data.data;
      console.log(this.listadoComentarios);
    })
  }
  

}
