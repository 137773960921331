import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { PreguntasServiceService } from "src/app/services/preguntas-service.service";

@Injectable()  
export class EncuestaIniciativaCuestionarioResolve implements Resolve<any[]> {
  idCaso: any = null;  
  constructor(private postService: PreguntasServiceService,
              private route: ActivatedRoute) {
                this.idCaso = this.route.snapshot.params['id']

              }  
  
  resolve(route: ActivatedRouteSnapshot,
          ): Observable<any[]> {  
      console.log(route.params['id']);
    return this.postService.getCuestionarioIniciativa(route.params['id']);  
  }  
} 