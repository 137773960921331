<div class="col-md-12 col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Eventos</h4>
        <div class="shedule-list d-flex align-items-center justify-content-between mb-3">
          <h3>18 de Mayo de 2021</h3>
          <small>4 Eventos</small>
        </div>
        <div class="event border-bottom py-3">
          <p class="mb-2 font-weight-medium">Primer evento </p>
          <div class="d-flex align-items-center">
            <div class="badge badge-success">3:45 AM</div>
            <small class="text-muted ml-2">Concepción, Chile</small>
            <div class="image-grouped ml-auto">
              <img src="assets/images/faces/face10.jpg" alt="profile image">
              <img src="assets/images/faces/face13.jpg" alt="profile image"> </div>
          </div>
        </div>
        <div class="event py-3 border-bottom">
          <p class="mb-2 font-weight-medium">Segundo evento</p>
          <div class="d-flex align-items-center">
            <div class="badge badge-primary">12.30 AM</div>
            <small class="text-muted ml-2">Talcahuano, Chile</small>
            <div class="image-grouped ml-auto">
              <img src="assets/images/faces/face20.jpg" alt="profile image">
              <img src="assets/images/faces/face17.jpg" alt="profile image">
              <img src="assets/images/faces/face14.jpg" alt="profile image"> </div>
          </div>
        </div>
        <div class="event py-3 border-bottom">
          <p class="mb-2 font-weight-medium">Tercer evento</p>
          <div class="d-flex align-items-center">
            <div class="badge badge-warning">12.30 AM</div>
            <small class="text-muted ml-2">Arauco, Chile</small>
            <div class="image-grouped ml-auto">
              <img src="assets/images/faces/face20.jpg" alt="profile image">
              <img src="assets/images/faces/face17.jpg" alt="profile image">
              <img src="assets/images/faces/face14.jpg" alt="profile image"> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Eventos</h4>
        <div class="shedule-list d-flex align-items-center justify-content-between mb-3">
          <h3>17 de Mayo de 2021</h3>
          <small>3 Eventos</small>
        </div>
        <div class="event border-bottom py-3">
          <p class="mb-2 font-weight-medium">Primer evento </p>
          <div class="d-flex align-items-center">
            <div class="badge badge-success">3:45 AM</div>
            <small class="text-muted ml-2">Chiguayante, Chile</small>
            <div class="image-grouped ml-auto">
              <img src="assets/images/faces/face10.jpg" alt="profile image">
              <img src="assets/images/faces/face13.jpg" alt="profile image"> </div>
          </div>
        </div>
        <div class="event py-3 border-bottom">
          <p class="mb-2 font-weight-medium">Segundo evento</p>
          <div class="d-flex align-items-center">
            <div class="badge badge-primary">12.30 AM</div>
            <small class="text-muted ml-2">Concepción, Chile</small>
            <div class="image-grouped ml-auto">
              <img src="assets/images/faces/face20.jpg" alt="profile image">
              <img src="assets/images/faces/face17.jpg" alt="profile image">
              <img src="assets/images/faces/face14.jpg" alt="profile image"> </div>
          </div>
        </div>
        <div class="event py-3 border-bottom">
          <p class="mb-2 font-weight-medium">Tercer evento</p>
          <div class="d-flex align-items-center">
            <div class="badge badge-warning">12.30 AM</div>
            <small class="text-muted ml-2">Santa Juana, Chile</small>
            <div class="image-grouped ml-auto">
              <img src="assets/images/faces/face20.jpg" alt="profile image">
              <img src="assets/images/faces/face17.jpg" alt="profile image">
              <img src="assets/images/faces/face14.jpg" alt="profile image"> </div>
          </div>
        </div>
      </div>
    </div>
  </div>

