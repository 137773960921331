import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Observable } from 'rxjs';
import { ResponseCuestionario } from '../api/modelos/ResponseCuestionario';

@Injectable({
  providedIn: 'root'
})
export class CatalogoService {
  
  constructor(private http: HttpClient,
    private restangular: Restangular,) {}  
  
  obtenerCatalogoPorNombre(nombre: string): Observable<any[]> {  
    return this.restangular.all('catalogos?filter[nombre]=' + nombre).get('');
  } 
  
}