import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-casos-evaluados',
  templateUrl: './casos-evaluados.component.html',
  styleUrls: ['./casos-evaluados.component.scss']
})
export class CasosEvaluadosComponent implements OnInit {

  listadoCasos: any[] = [];
  idInstitucion: any;
  estadoCasoAprobado = 'Finalizado';

  constructor(private restangular: Restangular,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) { 
    this.idInstitucion = JSON.parse(localStorage.getItem('institucion')).id;
  }

  ngOnInit(): void {
    this.cargarCasosEvaluados();
  }

  cargarCasosEvaluados(){
    this.restangular.all('casoinnovacions?filter[estado]='+this.estadoCasoAprobado).get('').subscribe(data => {
      this.listadoCasos = data.data;
      console.log(this.listadoCasos);
    })
  }
}
