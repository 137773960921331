<div toastContainer></div>
<div class="row">
  <!-- <div class="col-md-12 d-flex align-items-stretch grid-margin"> -->
  <!-- <div class="row flex-grow"> -->
  <div class="col-12 grid-margin">
    <div class="card">
      <div class="card-header" style="font-size: xx-large;">
        Encuesta iniciativa
      </div>
      <!-- <div class="col-lg-12 mx-auto"> -->
        <div class="card card-statistics social-card google-card card-colored">
          <div class="card-body"><p class="mb-2 comment font-weight-light">Para registrar tu experiencia de innovación debes completar obligatoriamente todos los
                  campos del presente formulario, para posterior efectuar la evaluación del grado de
                  innovación de la misma. Con estos elementos, tu experiencia ingresará al Banco.</p>
          </div>
        </div>
      <!-- </div> -->
      <button class="btn btn-success mr-2"style="background-color: #E15957; border-color: #E15957; margin-bottom: 10px;" onclick="window.location.href='../../../assets/cuestionarios/EncuestaIniciativa.docx'">Descargar encuesta</button>
      <button type="submit" class="btn btn-success mr-2" style="background-color: #E15957; border-color: #E15957;" (click)="borrarEncuesta()">Borrar respuestas</button>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</div>
<div *ngFor="let pregunta of preguntasLista">
  <div class="row" *ngIf="pregunta.attributes.dependencia.length > 0 || pregunta.attributes.dependencia == 1">
    <!-- <div class="col-md-12 d-flex align-items-stretch grid-margin"> -->
    <!-- <div class="row flex-grow"> -->
    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Pregunta {{ pregunta.attributes.nro_pregunta }}</h4>
          <p class="card-description">{{ pregunta.attributes.nombre }}</p>
          <form class="forms-sample">
            <div class="form-group">
              <div *ngIf="pregunta.attributes.tipo == 'CERRADA'">
                <div class="form-check" *ngFor="let item of pregunta.attributes.grupo_respuestas">
                  <input class="form-check-input" type="radio" name={{item.id}} id={{item.id}}
                   [(ngModel)]="respuestaValores[pregunta.attributes.orden].respuesta"
                    value={{item.id}}>
                  <label class="form-check-label" for={{item.id}}>
                    {{item.texto}}
                  </label>
                  <div class="form-group">
                    <input *ngIf="item.tipo == 'CHECKBOX-COMPLEMENTARIA' && respuestaValores[pregunta.attributes.orden].respuesta == item.id" type="text" name="pregunta.attributes.orden"
                    [(ngModel)]="respuestaValores[pregunta.attributes.orden].respuesta_complementaria" class="form-control form-control-lg">
                  </div>
                </div>
              </div>
              <div *ngIf="pregunta.attributes.tipo == 'ABIERTA'">
                <div class="form-group">
                  <textarea class="form-control" id="exampleTextarea1" rows="2" name="pregunta.attributes.orden"
                    [(ngModel)]="respuestaValores[pregunta.attributes.orden].respuesta"></textarea>
                </div>
              </div>
              <div *ngIf="pregunta.attributes.tipo == 'ABIERTA-MULTIPLE'">
                <div class="form-group" style="margin-left: 1.5%;">
                  <div *ngFor="let item of pregunta.attributes.grupo_respuestas">
                    <div *ngIf="item.tipo=='TEXTO'">
                      <p> {{item.texto}}</p><input id={{item.id}} type="text" name={{item.orden}} class="form-control"
                        [(ngModel)]="respuestaValoresMultiples[item.orden].respuesta_valor" />
                    </div>
                    <div *ngIf="item.tipo=='CHECKBOX'">
                      <input class="form-check-input" type="checkbox" name={{item.id}} id={{item.id}}
                        [(ngModel)]="respuestaValoresMultiples[item.orden].respuesta_valor" value={{item.id}}>
                      <label class="form-check-label" for={{item.id}}>
                        {{item.texto}}
                      </label>
                    </div>
                    <div *ngIf="item.tipo=='CHECKBOX-COMPLEMENTARIA'">
                      <div class="form-group">
                        <input class="form-check-input" type="checkbox" name={{item.id}} id={{item.id}}
                        [(ngModel)]="respuestaValoresMultiples[item.orden].respuesta_valor" value={{item.id}}>
                      <label class="form-check-label" for={{item.id}}>
                        {{item.texto}}
                      </label>
                      <input *ngIf="respuestaValoresMultiples[item.orden].respuesta_valor == '1'" type="text" name="{{item.id}}"
                  [(ngModel)]="respuestaValoresMultiples[item.orden].respuesta_complementaria" class="form-control form-control-lg">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <form *ngIf="respuestaValores[pregunta.attributes.orden].mostrar == 'SI'" class="forms-sample">
                <button type="submit" class="btn btn-primary btn-fw" style="background-color: #E15957; border-color: #E15957;" (click)="guardarRespuesta(pregunta)">Guardar</button>
              </form>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
  </div>
</div>
<div class="row">
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Finalizar y enviar cuestionario de la iniciativa</h4>
        <div class="template-demo">
          <button type="button" class="btn btn-primary btn-fw" style="background-color: #E15957; border-color: #E15957;" (click)="finalizarEncuesta()">Finalizar y enviar</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="row" *ngIf="true">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header" style="font-size: xx-large;">
        Resultado según dimensión
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="card" style="margin: 10px;">
            <div class="card-body">
              <p class="card-text" *ngFor="let item of puntajeDimension">Se obtuvieron <span
                  style="font-size: x-large;color: #FF0066;font-weight: bold;">{{ item.puntajeTotal }}</span> puntos de
                20 en la dimensión {{item.nombre}}</p>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card" style="margin: 10px;">
            <div class="card-body d-flex flex-column">
              <div class="wrapper">
                <h4 class="card-title mb-0">Equilibrio entre las dimensiones de la innovación pública local</h4>
              </div>
              <canvas class="my-auto mx-auto" height="100" baseChart [chartType]="'radar'" [datasets]="netProfitData"
                [labels]="netProfitLabels" [options]="netProfitOptions" [legend]="false" [colors]="netProfitColors">
              </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->