<div class="col-12 grid-margin">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Asignar perfiles</h4>
        <p class="card-description">Seleccione los perfiles que se asignarán para el usuario: {{ usuario.attributes.nombres + ' ' + usuario.attributes.apellidos }}</p>
        <div class="row">
          <div class="col-md-6 h-100">
            <div class="bg-light p-4">
              <h6 class="card-title">Perfiles disponibles</h6>
              <div *ngIf="perfilesDisponibles" dragula="profile-team" class="py-2" [(dragulaModel)]="perfilesDisponibles">
                <div class="card rounded mb-2" *ngFor="let perfil of perfilesDisponibles">
                  <div class="card-body p-3">
                    <div class="media">
                      <!-- <img src="assets/images/faces/face1.jpg" alt="image" class="img-sm mr-3 rounded-circle"> -->
                      <div class="media-body" style="cursor: pointer;">
                        <h6 class="mb-1">{{ perfil.nombre }}</h6>
                        <p class="mb-0 text-muted"> {{ perfil.descripcion }} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 h-100">
            <div class="bg-light p-4">
              <h6 class="card-title">Perfiles asignados</h6>
              <div *ngIf="perfilesAsignados" dragula="profile-team" class="py-2" [(dragulaModel)]="perfilesAsignados">
                <div class="card rounded mb-2" *ngFor="let perfil of perfilesAsignados">
                  <div class="card-body p-3">
                    <div class="media">
                      <!-- <img src="assets/images/faces/face5.jpg" alt="image" class="img-sm mr-3 rounded-circle"> -->
                      <div class="media-body">
                        <h6 class="mb-1">{{ perfil.nombre }}</h6>
                        <p class="mb-0 text-muted"> {{ perfil.descripcion }} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <br>
        <button style="margin-right: 10px;" type="submit" class="btn btn-primary btn-fw" (click)="guardarPerfiles()">Guardar</button>
            <button style="margin-right: 10px;" class="btn btn-primary btn-fw" (click)="activeModal.close('Close click')">Cancelar</button>
      </div>
    </div>
  </div>
