import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { PrivadoRoutingModule } from './privado-routing.module';
import { PrincipalComponent } from './principal/principal.component';
import { AuthGuard } from '../guards/AuthGuard';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { EventosComponent } from './eventos/eventos.component';
import { NgbActiveModal, NgbCollapseModule, NgbModal, NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CuestionaroCasoInnovacionComponent } from './cuestionaro-caso-innovacion/cuestionaro-caso-innovacion.component';
import { PreguntasServiceService } from '../services/preguntas-service.service';
import { CommonModule } from '@angular/common';
import { EncuestaInstitucionalComponent } from './encuesta-institucional/encuesta-institucional.component';
import { InicioComponent } from './inicio/inicio.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { ChartistComponent, ChartistModule } from 'ng-chartist';
import { ChartjsComponent } from '../charts/chartjs/chartjs.component';
import { EncuestaInstitucionalRespuestasMultiplesResolve } from './encuesta-institucional/encuesta-institucional-respuestas-multiples.resolve';
import { CrearCasoInnovacionResolve } from './crear-caso-innovacion/crear-caso-innovacion.resolver';
import { CrearCasoInnovacionComponent } from './crear-caso-innovacion/crear-caso-innovacion.component';
import { CatalogoResolve } from './crear-caso-innovacion/catalogo.resolver';
import { CatalogoProvinciasResolve } from './crear-caso-innovacion/catalogo-provincias.resolver';
import { CatalogoRegionesResolve } from './crear-caso-innovacion/catalogo-regiones.resolver';
import { EvaluarCasosComponent } from './evaluar-casos/evaluar-casos.component';
import { DetalleCasoInnovacionComponent } from './detalle-caso-innovacion/detalle-caso-innovacion.component';
import { DetalleEvaluarCasoComponent } from './detalle-evaluar-caso/detalle-evaluar-caso.component';
import { EncuestaIniciativaComponent } from './encuesta-iniciativa/encuesta-iniciativa.component';
import { EncuestaIniciativaCuestionarioResolve } from './encuesta-iniciativa/encuesta-iniciativa-cuestionario.resolve';
import { EncuestaIniciativaRespuestasMultiplesResolve } from './encuesta-iniciativa/encuesta-iniciativa.respuestas-multiples.resolve';
import { EncuestaIniciativaRespuestasResolve } from './encuesta-iniciativa/encuesta-iniciativa-respuestas-resolve';
import { EncuestaInstitucionalCuestionarioResolve } from './encuesta-institucional/encuesta-institucional-cuestionario.resolve';
import { EncuestaInstitucionalRespuestaValoresResolve } from './encuesta-institucional/encuesta-institucional-respuesta-valores.resolve';
import { AdmisionCasosComponent } from './admision-casos/admision-casos.component';
import { DetalleAdmisibilidadComponent } from './detalle-admisibilidad/detalle-admisibilidad.component';
import { CasosInnovacionComponent } from './casos-innovacion/casos-innovacion.component';
import { DetalleCasoInnovacionCargarCasoResolve } from './detalle-caso-innovacion/detalle-caso-innovacion.cargar-caso.resolve';
import { NgxGaugeModule } from 'ngx-gauge';
import { AprobarCasoComponent } from './aprobar-caso/aprobar-caso.component';
import { DetalleEvaluarCasoCargarCasoResolve } from './detalle-evaluar-caso/detalle-evaluar-caso.cargar-caso.resolve';
import { InstitucionesComponent } from './instituciones/instituciones.component';
import { AgregarInstitucionComponent } from './instituciones/agregar-institucion/agregar-institucion.component';
import { BancoExperienciasComponent } from './banco-experiencias/banco-experiencias.component';
import { EstadisticasService } from '../services/estadisticas.service';
import { TotalCasosResolve } from './inicio/total-casos-resolver';
import { TotalCasosEvaluadosResolve } from './inicio/total-casos-evaluados.resolver';
import { TotalUsuariosRegistradosResolve } from './inicio/total-usuarios-registrados-resolver';
import { TotalInstitucionesRegistradasResolve } from './inicio/total-instituciones-registradas.resolver';
import { TotalRespuestaPregunta11Resolve } from './inicio/total-respuesta-pregunta-11.resolver';
import { TotalRespuestaPregunta15Resolve } from './inicio/total-respuesta-pregunta-15.resolver';
import { TotalRespuestaPregunta26Resolve } from './inicio/total-respuesta-pregunta-26.resolve';
import { NombreRespuestaMayorPreguntaMultiple26Resolve } from './inicio/nombre-respuesta-mayor-pregunta-multiple-26.resolver';
import { PromedioRespuestaComplementaria25Resolve } from './inicio/promedio-respuesta-complementaria-25.resolver';
import { PuntajePromedioDimensionesResolve } from './inicio/puntaje-promedio-dimensiones.resolver';
import { PuntajePromedioEjeMedicionResolve } from './inicio/puntaje-promedio-eje-medicion.resolver';
import { TotalRespuestaPregunta5Resolve } from './inicio/total-respuesta-pregunta-5.resolver';
import { TotalRespuestaPregunta1Resolve } from './inicio/total-respuesta-pregunta-1.resolver';
import { TotalRespuestaPregunta10Resolve } from './inicio/total-respuesta-pregunta-10.resolver';
import { TotalRespuestaPregunta19Resolve } from './inicio/total-respuesta-pregunta-19.resolver';
import { TotalRespuestaPregunta39Resolve } from './inicio/total-respuesta-pregunta-39.resolver';
import { PuntajePromedioEjeMedicionGDResolve } from './inicio/puntaje-promedio-eje-medicion-GD.resolver';
import { PuntajeCasoComponent } from './puntaje-caso/puntaje-caso.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { CrearUsuariosComponent } from './usuarios/crear-usuarios/crear-usuarios.component';
import { AsignarPerfilesComponent } from './usuarios/asignar-perfiles/asignar-perfiles.component';
import { DragulaModule } from 'ng2-dragula';
import { UsuariosService } from '../services/usuarios.service';
import { CasosEvaluadosComponent } from './casos-innovacion/casos-evaluados/casos-evaluados.component';
import { ListadoCasosMunicipioComponent } from './casos-innovacion/listado-casos-municipio/listado-casos-municipio.component';
import { ListadoCasosComponent } from './casos-innovacion/listado-casos/listado-casos.component';
import { DetalleCasoComponent } from './casos-innovacion/detalle-caso/detalle-caso.component';
import { PuntajePromedioEjeMedicionDTResolve } from './inicio/puntaje-promedio-eje-medicion-DT.resolver';
import { DetalleCasoInnovacionCargarRolesResolve } from './detalle-caso-innovacion/detalle-caso-innovacion.cargar-roles.resolve';
import { DescubreInnovacionComponent } from './descubre-innovacion/descubre-innovacion.component';
import { EstadoInnovacionComponent } from './estado-innovacion/estado-innovacion.component';
import { ComoSeMideComponent } from './como-se-mide/como-se-mide.component';
import { ListadoCasosInnovacionComponent } from './listado-casos-innovacion/listado-casos-innovacion.component';
import { ContactoComponent } from './contacto/contacto.component';
import { PerfilUsuarioComponent } from './usuarios/perfil-usuario/perfil-usuario.component';
import { PuntajesComponent } from './encuesta-institucional/puntajes/puntajes.component';
import { ResultadosEiComponent } from './encuesta-institucional/resultados-ei/resultados-ei.component';
import { PerfilUsuarioCargarUsuarioResolve } from './usuarios/perfil-usuario/perfil-usuario.cargar-usuario.resolve';


@NgModule({
  declarations: [
    PrincipalComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    EventosComponent,
    CuestionaroCasoInnovacionComponent,
    EncuestaInstitucionalComponent,
    InicioComponent,
    CrearCasoInnovacionComponent,
    EvaluarCasosComponent,
    DetalleCasoInnovacionComponent,
    DetalleEvaluarCasoComponent,
    EncuestaIniciativaComponent,
    AdmisionCasosComponent,
    DetalleAdmisibilidadComponent,
    CasosInnovacionComponent,
    AprobarCasoComponent,
    InstitucionesComponent,
    AgregarInstitucionComponent,
    ListadoCasosComponent,
    BancoExperienciasComponent,
    PuntajeCasoComponent,
    UsuariosComponent,
    CrearUsuariosComponent,
    AsignarPerfilesComponent,
    CasosEvaluadosComponent,
    ListadoCasosMunicipioComponent,
    DetalleCasoComponent,
    DescubreInnovacionComponent,
    EstadoInnovacionComponent,
    ComoSeMideComponent,
    ListadoCasosInnovacionComponent,
    ContactoComponent,
    PerfilUsuarioComponent,
    PuntajesComponent,
    ResultadosEiComponent
  ],
  imports: [
    PrivadoRoutingModule,
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    ChartistModule,
    NgxGaugeModule,
    NgbModalModule,
    DragulaModule.forRoot(),
  ],
  providers: [
    AuthGuard, 
    PreguntasServiceService,
    UsuariosService,
    CrearCasoInnovacionResolve,
    CatalogoResolve,
    CatalogoProvinciasResolve,
    CatalogoRegionesResolve,
    EncuestaIniciativaCuestionarioResolve,
    EncuestaIniciativaRespuestasResolve,
    EncuestaIniciativaRespuestasMultiplesResolve,
    EncuestaInstitucionalCuestionarioResolve,
    EncuestaInstitucionalRespuestaValoresResolve,
    EncuestaInstitucionalRespuestasMultiplesResolve,
    DetalleCasoInnovacionCargarCasoResolve,
    DetalleEvaluarCasoCargarCasoResolve,
    NgbActiveModal,
    AgregarInstitucionComponent,
    EstadisticasService,
    TotalCasosResolve,
    TotalCasosEvaluadosResolve,
    TotalUsuariosRegistradosResolve,
    TotalInstitucionesRegistradasResolve,
    TotalRespuestaPregunta11Resolve,
    TotalRespuestaPregunta15Resolve,
    TotalRespuestaPregunta26Resolve,
    NombreRespuestaMayorPreguntaMultiple26Resolve,
    PromedioRespuestaComplementaria25Resolve,
    PuntajePromedioDimensionesResolve,
    PuntajePromedioEjeMedicionResolve,
    TotalRespuestaPregunta5Resolve,
    TotalRespuestaPregunta1Resolve,
    TotalRespuestaPregunta10Resolve,
    TotalRespuestaPregunta19Resolve,
    TotalRespuestaPregunta39Resolve,
    PuntajePromedioEjeMedicionGDResolve,
    PuntajePromedioEjeMedicionDTResolve,
    DetalleCasoInnovacionCargarRolesResolve,
    PerfilUsuarioCargarUsuarioResolve
]
  })
export class PrivadoModule { }
