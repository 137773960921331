import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import jsPDF from 'jspdf';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

@Component({
  selector: 'app-puntaje-caso',
  templateUrl: './puntaje-caso.component.html',
  styleUrls: ['./puntaje-caso.component.scss']
})
export class PuntajeCasoComponent implements OnInit {

  @Input() public casoInnovacion;
  @ViewChild('exportPdf') exportPdf: ElementRef;

  gaugeType = "semi";
  gaugeValue = 28.3;
  gaugeLabel = "Puntaje";
  gaugeForegroundColor = "#62d896";
  gaugeSize = "180";
  gaugeThickness = "20";
  maxValue = "30";

  netProfitData = [
    {
      label: "Puntaje",
      borderWidth: 3,
      fill: false,
      radius: 0,
      pointRadius: 0,
      borderColor: "#FF0066",
      pointBorderWidth: 0,
      pointHoverRadius: 10,
      pointHitRadius: 5,
      data: [0, 0, 0]
    }
  ];

  netProfitColors = [
    {
      backgroundColor: "rgba(88, 208, 222,0.8)",
      pointBackgroundColor: "rgba(88, 208, 222,0.8)"
    },
    /* {
      backgroundColor: "rgba(150, 77, 247,1)",
      pointBackgroundColor: "rgba(150, 77, 247,1)"
    } */
  ];
  netProfitLabels = ["Gestión publica local", "Desarrollo territorial", "Gobernanza democrática"];
  netProfitOptions = {
    scale: {
      ticks: {
        beginAtZero: true,
        min: 0,
        max: 10,
        stepSize: 5,
        display: true
      },
      pointLabels: {
        fontSize: 14,
        fontColor: "#7C9E62",
        color: "#b9c0d3"
      },
      angleLines: {
        color: "#b9c0d3",
        zeroLineColor: "#b9c0d3"
      },
      gridLines: {
        color: "#b9c0d3",
        zeroLineColor: "#b9c0d3"
      }
    },
    legend: false
  };

  realtimeStatisticsLabels = [
    "Insumos y Recursos",
    "Procesos y Estrategias",
    "Impacto en la Innovación"
  ];
  variablesLabels = [
    "Innovación Abierta",
    "Descentralización",
    "Participación Ciudadana",
    "Asociatividad",
    "Capital Humano",
    "Competitividad",
  ];
  realtimeStatisticsOptions = {
    responsive: true,
    maintainAspectRatio: true,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },
    scales: {
      yAxes: [
        {
          display: true,
          gridLines: {
            display: true
          }
        }
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: false,
            fontColor: "#354168"
          },
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
            display: false
          },
          barPercentage: 0.1
        }
      ]
    },
    elements: {
      point: {
        radius: 0
      }
    }
  };

  variablesOptions = {
    responsive: true,
    maintainAspectRatio: true,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },
    scales: {
      yAxes: [
        {
          display: true,
          gridLines: {
            display: true
          }
        }
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: false,
            fontColor: "#354168"
          },
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
            display: false
          },
          barPercentage: 0.1
        }
      ]
    },
    elements: {
      point: {
        radius: 0
      }
    }
  };

  realtimeStatisticsData = [
    {
      label: "Puntaje obtenido",
      data: [0, 0, 0]
    },
    {
      label: "Puntaje total",
      data: [0, 0, 0]
    }
  ];

  variablesData = [
    {
      label: "Puntaje obtenido",
      data: [0, 0, 0, 0, 0, 0]
    },
    {
      label: "Puntaje total",
      data: [0, 0, 0, 0, 0, 0]
    }
  ];
  realtimeStatisticsColors = [
    {
      backgroundColor: "#3096f3"
    },
    {
      backgroundColor: "rgba(238, 242, 245, 1)"
    }
  ];

  variablesColors = [
    {
      backgroundColor: "#62d896" 
    },
    {
      backgroundColor: "rgba(238, 242, 245, 1)"
    }
  ];

  puntajeGlobalTotal: any;
  puntajeDimension: any[];
  puntajeEjeMedicion: any[];
  puntajeVariable: any[];

  comentarioBase: Restangular;

  constructor(private route: ActivatedRoute,
    private restangular: Restangular,
    private toastr: ToastrService,
    private modalService: NgbModal, 
    public activeModal: NgbActiveModal) { 
      //this.casoInnovacion = this.route.snapshot.data.casoInnovacion.data[0];
  }

  ngOnInit(): void {
    this.cargarPuntajeGlobal();
      this.cargarPuntajeDimension();
      this.cargarPuntajesEjeMedicion();
      this.cargarPuntajesVariables();
  }

  llenarGraficoDimension(){
    this.netProfitData[0].data = [
      this.puntajeDimension[0].puntajeTotal,
      this.puntajeDimension[1].puntajeTotal,
      this.puntajeDimension[2].puntajeTotal
    ]
  }

  llenarGraficoEjeMedicion(){
    this.realtimeStatisticsData[0].data = [
      this.puntajeDimension[0].puntajeTotal,
      this.puntajeDimension[1].puntajeTotal,
      this.puntajeDimension[2].puntajeTotal
    ]

    this.realtimeStatisticsData[1].data = [
      this.puntajeDimension[0].puntaje_maximo,
      this.puntajeDimension[1].puntaje_maximo,
      this.puntajeDimension[2].puntaje_maximo
    ]
  }

  llenarGraficoVariable(){
    this.variablesData[0].data = [
      this.puntajeVariable[0].puntajeTotal,
      this.puntajeVariable[1].puntajeTotal,
      this.puntajeVariable[2].puntajeTotal,
      this.puntajeVariable[3].puntajeTotal,
      this.puntajeVariable[4].puntajeTotal,
      this.puntajeVariable[5].puntajeTotal
    ]

    this.variablesData[1].data = [
      this.puntajeVariable[0].puntaje_maximo,
      this.puntajeVariable[1].puntaje_maximo,
      this.puntajeVariable[2].puntaje_maximo,
      this.puntajeVariable[3].puntaje_maximo,
      this.puntajeVariable[4].puntaje_maximo,
      this.puntajeVariable[5].puntaje_maximo
    ]
  }

  cargarPuntajeGlobal(){
    this.restangular.all('puntaje-global-caso').get('2/'+this.casoInnovacion.id).subscribe( data => {
      console.log(data);
      this.puntajeGlobalTotal = data[0].puntajeTotal;
      //this.llenarGrafico();
      console.log(this.puntajeGlobalTotal);
    });
  }

  cargarPuntajeDimension(){
    this.restangular.all('puntaje-dimension-caso').get('2/'+this.casoInnovacion.id).subscribe( data => {
      console.log(data);
      this.puntajeDimension = data;
      setTimeout(() => {
        this.llenarGraficoDimension();
      },500
      );
      
    });
  }

  cargarPuntajesEjeMedicion(){
    this.restangular.all('puntaje-eje-medicion-caso').get('2/'+this.casoInnovacion.id).subscribe( data => {
      console.log(data);
      this.puntajeEjeMedicion = data;
      setTimeout(() => {
        this.llenarGraficoEjeMedicion();
      },500
      );
      
    });
  }

  cargarPuntajesVariables(){
    this.restangular.all('puntaje-variable-caso').get('2/'+this.casoInnovacion.id).subscribe( data => {
      console.log(data);
      this.puntajeVariable = data;
      setTimeout(() => {
        this.llenarGraficoVariable();
      },500
      );
      
    });
  }

  descargarPuntaje2(){
    const doc = new jsPDF();
   
    const exportPdf = this.exportPdf.nativeElement;
   
    var html = htmlToPdfmake(exportPdf.innerHTML);
     
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();

  }

  descargarPuntaje(){
    let data = document.getElementById('exportPDF');
      
      html2canvas(data, {scale:2}).then(canvas => {
          
          let docWidth = 210;
          let docHeight = canvas.height * docWidth / canvas.width;
          
          const contentDataURL = canvas.toDataURL('image/jpeg')
          let doc = new jsPDF('p', 'mm', 'a4');
          doc.setFillColor('245');
          let position = 0;
          doc.addImage(contentDataURL, 'JPEG', 0, position, docWidth, docHeight)
          
          doc.save('puntajes-encuesta.pdf');
      });
  }


}
