import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbDropdownConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { CrearUsuariosComponent } from "../usuarios/crear-usuarios/crear-usuarios.component";
import { Usuario } from "src/app/api/modelos/Usuario";
import { Restangular } from "ngx-restangular";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public iconOnlyToggled = false;
  public sidebarToggled = false;

  nombresUsuario: any;
  apellidosUsuario: any;
  emailUsuario: any;

  usuario: Usuario = JSON.parse(localStorage.getItem('usuario')).id;
  usuarioStorage: any;
  usuario2;

  toggleRightSidebar() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  toggleIconOnlySidebar() {
    this.iconOnlyToggled = !this.iconOnlyToggled;
    if (this.iconOnlyToggled) {
      document.querySelector("body").classList.add("sidebar-icon-only");
    } else {
      document.querySelector("body").classList.remove("sidebar-icon-only");
    }
  }

  constructor(config: NgbDropdownConfig,
    private modalService: NgbModal,
    private restangular: Restangular) {
    config.placement = "bottom-right";
  }
  ngOnInit() {
    this.cargarUsuario();
    this.nombresUsuario = JSON.parse(localStorage.getItem('usuario')).nombres;
    this.apellidosUsuario = JSON.parse(localStorage.getItem('usuario')).apellidos;
    this.emailUsuario = JSON.parse(localStorage.getItem('usuario')).email;
    this.usuarioStorage = JSON.parse(localStorage.getItem('usuario')).id;

  }

  cerrarSesion() {
    console.log('cerrar');
    
    localStorage.clear();
    window.location.href = environment.urlFront + '/';
}

cargarUsuario(){
  this.restangular.all('usuarios?filter[id]=1').get('').subscribe(data => {
    this.usuario2 = data.data;
    console.log(this.usuario2);
  })
}

editarUsuario(usuario){
  console.log(usuario);
  
  this.restangular.all('usuarios?filter[id]='+ usuario.id).get('').subscribe(data => {
    this.usuario2 = data.data;
    console.log(this.usuario2);
    const modalRef = this.modalService.open(CrearUsuariosComponent, {
      size: '50%',
      centered: true
    });
    modalRef.componentInstance.usuario = this.usuario2[0];
  
    modalRef.result.then( result => {
      console.log('Se ha cerrado el modal');
      // this.cargarUsuarios();
    })
  })
  

}
}
