import { Component, Directive, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';
import { PreguntaRespuestas } from 'src/app/api/modelos/PreguntaRespuestas';
import { PreguntasServiceService } from 'src/app/services/preguntas-service.service';
import { PuntajeCasoComponent } from '../puntaje-caso/puntaje-caso.component';
import { PuntajesComponent } from './puntajes/puntajes.component';
import { ResultadosEiComponent } from './resultados-ei/resultados-ei.component';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-encuesta-institucional',
  templateUrl: './encuesta-institucional.component.html',
  styleUrls: ['./encuesta-institucional.component.scss']
})

export class EncuestaInstitucionalComponent implements OnInit {

  

  preguntasLista: any[] = [];
  pregunta1: any;
  respuesta1: any[];
  preguntaValor1: any;
  respuestaValores: any[] = [];
  respuestaValoresMultiples: any[] = [];

  puntajeTotal: any = 0;

  puntajeDimension: any[] = [];
  puntajeVariable: any[] = [];
  puntajeEjeMedicion: any[] = [];

  puntajeVariableGestionPublica: any[];
  puntajeVariableGobernanzaDemocratica: any[];
  puntajeVariableDesarrolloTerritorial: any[];

  idInstitucion: any;


  respuestaPreguntaBase: Restangular;

  cuestionarioId: any;
  nombreInstitucion: any;

  finalizarCuestionario: boolean = false;

  sumaPuntajeEjeMedicion: any[];

  realtimeStatisticsData = [
    [
      {
        label: "Puntaje promedio",
        data: []
      },
      {
        label: "Puntaje máximo",
        data: []
      }
    ]
  ];

  realtimeStatisticsLabels = [
    "Insumos y Recursos",
    "Procesos y Estrategias",
    "Impacto en la innovación"
  ];

  realtimeStatisticsOptions = {
    responsive: true,
    maintainAspectRatio: true,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },
    scales: {
      yAxes: [
        {
          display: false,
          gridLines: {
            display: false
          }
        }
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            fontColor: "#354168"
          },
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
            display: false
          },
          barPercentage: 0.4
        }
      ]
    },
    elements: {
      point: {
        radius: 0
      }
    }
  };

  realtimeStatisticsColors = [
    {
      backgroundColor: "#3096f3"
    },
    {
      backgroundColor: "rgba(238, 242, 245, 1)"
    }
  ];


  netProfitData = [
    {
      label: "Puntaje",
      borderWidth: 3,
      fill: false,
      radius: 0,
      pointRadius: 0,
      borderColor: "#FF0066",
      pointBorderWidth: 0,
      pointHoverRadius: 10,
      pointHitRadius: 5,
      data: [0, 0, 0]
    },
    /* {
      label: "Orders",
      borderWidth: 0,
      fill: true,
      radius: 0,
      pointRadius: 0,
      pointBorderWidth: 0,
      pointHoverRadius: 10,
      pointHitRadius: 5,
      data: [65, 75, 70]
    } */
  ];

  netProfitColors = [
    {
      backgroundColor: "rgba(88, 208, 222,0.8)",
      pointBackgroundColor: "rgba(88, 208, 222,0.8)"
    },
    /* {
      backgroundColor: "rgba(150, 77, 247,1)",
      pointBackgroundColor: "rgba(150, 77, 247,1)"
    } */
  ];
  netProfitLabels = ["Desarrollo territorial", "Gestión publica local", "Gobernanza democrática"];
  netProfitOptions = {
    scale: {
      ticks: {
        beginAtZero: true,
        min: 0,
        max: 20,
        stepSize: 5,
        display: false
      },
      pointLabels: {
        fontSize: 14,
        fontColor: "#7C9E62",
        color: "#b9c0d3"
      },
      angleLines: {
        color: "#b9c0d3",
        zeroLineColor: "#b9c0d3"
      },
      gridLines: {
        color: "#b9c0d3",
        zeroLineColor: "#b9c0d3"
      }
    },
    legend: false
  };

  
  constructor(private restangular: Restangular,
              private toastr: ToastrService,
              private route: ActivatedRoute,
              private preguntasService: PreguntasServiceService,
              private modalService: NgbModal
              ) { 

    this.pregunta1 = 'N/A';
    this.respuesta1 = null;
    this.preguntaValor1 = 'NA';
    this.respuestaPreguntaBase = this.restangular.one('preguntarespuestas');
    this.cuestionarioId = localStorage.getItem('cuestionario');
    this.nombreInstitucion = JSON.parse(localStorage.getItem('institucion')).nombre;
    this.idInstitucion = JSON.parse(localStorage.getItem('institucion')).id;

  }

  ngOnInit(): void {
    //this.cargarPreguntasCuestionario();
    this.preguntasLista = this.route.snapshot.data.preguntas.data;
    this.respuestaValores = this.route.snapshot.data.respuestaValores;
    this.respuestaValoresMultiples = this.route.snapshot.data.respuestasMultiples;
    
    /* this.preguntasLista.forEach( (x, index) => {
        // Si no se ha cargado datos de la respuesta ya ingresada se debe crear un objeto vacio para la pregunta respuesta
        if(!this.respuestaValores[x.attributes.orden]){
          let respuestaValoresNuevo = {
            orden: null,
            respuesta: 0
      }
          respuestaValoresNuevo.orden = x.attributes.orden;
          respuestaValoresNuevo.respuesta = 0;
          this.respuestaValores[x.attributes.orden] = respuestaValoresNuevo;
        }
    }); */
    this.cargarPuntajes();
    this.sumaPuntajeEjeMedicionInsitucion();

  }

  llenarGrafico(){
    this.netProfitData[0].data = [
      this.puntajeDimension[0].puntajeTotal,
      this.puntajeDimension[1].puntajeTotal,
      this.puntajeDimension[2].puntajeTotal
    ]
  }

  cargarPreguntasCuestionario(){
    this.restangular.all('preguntas?filter[cuestionario_id]=1').get('').subscribe(
      data => {
          console.log(data.data);
          this.preguntasLista = data.data;
          this.preguntasLista.forEach( item => {
            console.log(item);
            //this.respuestaValores[item.attributes.orden] = '1';
            this.restangular.all('preguntas').get('1/'+item.attributes.tipo+'/'+item.id).subscribe( data => {
              console.log(data);
              if(data.length > 0){
                this.respuestaValores[data.id] = data[0].respuesta.toString();
                console.log(this.respuestaValores);
              }
            });
          });
          this.cargarPuntajes();
      });
  }

  obtenerRespuestaPregunta(pregunta: number){
    this.restangular.all('preguntas').get('respuestas/'+pregunta).subscribe( data => {
      console.log(data);
      this.respuesta1 = data;
      console.log(this.respuesta1);
    })
  }

  guardarRespuesta(pregunta){
    console.log(pregunta);
    console.log(this.respuestaValores);

    let jsonApiWrapper = {
      data: {
          type: 'preguntarespuestas',
          attributes: null
      }

    };

    let preguntaRespuestaNueva: PreguntaRespuestas = {
      cuestionario_id: 1,
      pregunta_id: null,
      respuesta_id: null,
      respuesta_valor: null,
      puntaje: 0,
      institucion_id: null,
      respuesta_complementaria: null
    }
    
    let preguntaTemp = this.preguntasLista.find( elemento => elemento.id == pregunta.id); 

    //Selecciono la respuesta para rescatar el puntaje (solo para preguntas cerradas)   
    let respuestaTemp = preguntaTemp.attributes.grupo_respuestas.find(ele => ele.id == this.respuestaValores[pregunta.attributes.orden].respuesta);
    if(respuestaTemp){
      preguntaRespuestaNueva.puntaje = respuestaTemp.puntaje;
    }

    if(pregunta.attributes.tipo == 'ABIERTA-MULTIPLE'){
      //Se recorren el grupo de respuestas para asignar por cada respuesta una valor
      preguntaTemp.attributes.grupo_respuestas.forEach(element => {
        preguntaRespuestaNueva.puntaje = 0;
        //Se envía el id 2 de la respuesta por defecto para las preguntas de tipo abierta
        preguntaRespuestaNueva.cuestionario_id = 1;
        preguntaRespuestaNueva.pregunta_id = parseInt(pregunta.id);
        preguntaRespuestaNueva.respuesta_id = element.id;
        preguntaRespuestaNueva.respuesta_valor = this.respuestaValoresMultiples[element.orden].respuesta_valor;
        preguntaRespuestaNueva.respuesta_complementaria = this.respuestaValoresMultiples[element.orden].respuesta_complementaria;
        preguntaRespuestaNueva.institucion_id = this.idInstitucion;
        if(preguntaRespuestaNueva.respuesta_valor != null){
          preguntaRespuestaNueva.puntaje = this.respuestaValoresMultiples[element.orden].puntaje_grupo_respuesta;
        }
        jsonApiWrapper.data.attributes = preguntaRespuestaNueva;

        this.respuestaPreguntaBase.post('', jsonApiWrapper).subscribe(
          data => {
              console.log(data);
              
              this.restangular.all('preguntas?filter[cuestionario_id]=1&&sort=orden&&institucion='+this.idInstitucion).get('').subscribe(
                data => {
                    console.log(data.data);
                    this.preguntasLista = data.data;
                    this.restangular.all('preguntas/institucional').get('1/'+this.idInstitucion).subscribe( lista => {
                      this.respuestaValores = lista
                    });
                    this.cargarPuntajes();
                    if(this.preguntasLista[pregunta.attributes.orden + 1].attributes.dependencia.length == 0){
                      if(this.preguntasLista[pregunta.attributes.orden + 2].attributes.dependencia.length > 0){
                        this.finalizarCuestionario = false;
                        console.log(this.finalizarCuestionario);
                      } else {
                        this.finalizarCuestionario = true;
                      }
                    }
                    console.log(this.finalizarCuestionario);
                });
          }
      );
      });
      this.toastr.success('Se ha guardado correctamente la respuesta', 'Ok');
    }
    if(pregunta.attributes.tipo == 'CERRADA'){
      preguntaRespuestaNueva.cuestionario_id = 1;
      preguntaRespuestaNueva.pregunta_id = parseInt(pregunta.id);
      preguntaRespuestaNueva.respuesta_id = Number(this.respuestaValores[pregunta.attributes.orden].respuesta);
      preguntaRespuestaNueva.respuesta_complementaria = this.respuestaValores[pregunta.attributes.orden].respuesta_complementaria; 
      preguntaRespuestaNueva.institucion_id = this.idInstitucion;

      jsonApiWrapper.data.attributes = preguntaRespuestaNueva;

    this.respuestaPreguntaBase.post('', jsonApiWrapper).subscribe(
      data => {
          console.log(data);
          this.toastr.success('Se ha guardado correctamente la respuesta', 'Ok');
          this.restangular.all('preguntas?filter[cuestionario_id]=1&&sort=orden&&institucion='+this.idInstitucion).get('').subscribe(
            data => {
                console.log(data.data);
                this.preguntasLista = data.data;
                console.log(this.preguntasLista);
                this.restangular.all('preguntas/institucional').get('1/'+this.idInstitucion).subscribe( lista => {
                  this.respuestaValores = lista;
                  console.log(this.preguntasLista[pregunta.attributes.orden + 1].dependencia);
                });
                
                this.cargarPuntajes();
                console.log(this.preguntasLista);
                // Se agrega esta condición para comprobar la finalización del cuestionario
                if((this.preguntasLista.length -1) !=  pregunta.attributes.orden){
                  if(this.preguntasLista[pregunta.attributes.orden + 1].attributes.dependencia.length == 0){
                    if(this.preguntasLista[pregunta.attributes.orden + 2].attributes.dependencia.length > 0){
                      this.finalizarCuestionario = false;
                      console.log(this.finalizarCuestionario);
                    } else {
                      this.finalizarCuestionario = true;
                      this.restangular.all('send-email/evaluacion-institucional-completa').get('').subscribe(data => {
                      });
                    }
                  }
                } else {
                  this.finalizarCuestionario = true;
                  this.restangular.all('send-email/evaluacion-institucional-completa').get('').subscribe(data => {
                  });
                }
                
                console.log(this.finalizarCuestionario);
            });
      }
    );
    }

    if(pregunta.attributes.tipo == 'ABIERTA') {
      //Se envía el id 2 de la respuesta por defecto para las preguntas de tipo abierta
      preguntaRespuestaNueva.cuestionario_id = 1;
      preguntaRespuestaNueva.pregunta_id = parseInt(pregunta.id);
      preguntaRespuestaNueva.respuesta_id = 2;
      preguntaRespuestaNueva.puntaje = pregunta.attributes.puntaje;
      preguntaRespuestaNueva.respuesta_valor = this.respuestaValores[pregunta.attributes.orden].respuesta;
      preguntaRespuestaNueva.institucion_id = this.idInstitucion;
      jsonApiWrapper.data.attributes = preguntaRespuestaNueva;

      if(preguntaRespuestaNueva.respuesta_valor != null){
        this.respuestaPreguntaBase.post('', jsonApiWrapper).subscribe(
          data => {
              console.log(data);
              this.toastr.success('Se ha guardado correctamente la respuesta', 'Ok');
              this.restangular.all('preguntas?filter[cuestionario_id]=1&&sort=orden&&institucion='+this.idInstitucion).get('').subscribe(
                data => {
                    console.log(data.data);
                    this.preguntasLista = data.data;
                    this.restangular.all('preguntas/institucional').get('1/'+this.idInstitucion).subscribe( lista => {
                      this.respuestaValores = lista;
                    });
                    this.cargarPuntajes();
                    if(this.preguntasLista[pregunta.attributes.orden + 1].attributes.dependencia.length == 0){
                      if(this.preguntasLista[pregunta.attributes.orden + 2].attributes.dependencia.length > 0){
                        this.finalizarCuestionario = false;
                        console.log(this.finalizarCuestionario);
                      } else {
                        this.finalizarCuestionario = true;
                      }
                    }
                    console.log(this.finalizarCuestionario);
                });
          }
      );
      } else {
        this.toastr.error('Es necesario ingresar una respuesta antes de continuar', 'Error');
      }
        
    } 
  }

  cargarPuntajes(){
    this.restangular.all('puntaje-dimension').get('1/'+this.idInstitucion).subscribe( data => {
      console.log(data);
      this.puntajeDimension = data;
      this.llenarGrafico();
    });
    
    this.restangular.all('puntaje-variable').get('1/1/'+this.idInstitucion).subscribe( data => {
      console.log(data);
      this.puntajeVariableGestionPublica = data;
    });

    this.restangular.all('puntaje-variable').get('1/2/'+this.idInstitucion).subscribe( data => {
      console.log(data);
      this.puntajeVariableGobernanzaDemocratica = data;
    });

    this.restangular.all('puntaje-variable').get('1/3/'+this.idInstitucion).subscribe( data => {
      console.log(data);
      this.puntajeVariableDesarrolloTerritorial = data;
    });

    this.restangular.all('puntaje-eje-medicion').get('1').subscribe( data => {
      console.log(data);
      this.puntajeEjeMedicion = data;
    }); 
  }

  onChange(pregunta){
    console.log(this.respuestaValores);
    console.log(pregunta);
  }

  borrarEncuesta(){
    this.restangular.all('cuestionario/borrar/1/'+this.idInstitucion).get('').subscribe(
      data => {
          console.log(data.data);
          this.preguntasLista = data.data;
          this.cargarPuntajes();
          this.restangular.all('preguntas?filter[cuestionario_id]=1&&sort=orden&&institucion='+this.idInstitucion).get('').subscribe( data => {
            this.preguntasLista = data.data;
            this.restangular.all('preguntas/institucional').get('1/'+this.idInstitucion).subscribe( respuestas => {
              this.respuestaValores = respuestas;
            })
          })
          /* this.preguntasService.getPosts().subscribe( preguntas => {
            this.preguntasLista = preguntas.data;
            this.preguntasService.getRespuestas().subscribe(respuestas => {
              this.respuestaValores = respuestas;
            });
          }); */
      });
  }

  trackByIdGrupoRespuestas(index: number, item: any){
    return item.id;
  }

  downloadPdf() {
    const pdfUrl = '../../../assets/encuestas/EncuestaInstitucional.docx';
    const pdfName = 'EncuestaInstitucional';
    FileSaver.saveAs(pdfUrl, pdfName);
  }

  descargarEncuesta(){
    window.location.href='../../../assets/cuestionarios/EncuestaInstitucional.docx';
  }

  verPuntajes(){
    const modalRef = this.modalService.open(PuntajesComponent, {
      size: 'xl',
      centered: true
    });

    modalRef.componentInstance.puntajeVariableGestionPublica = this.puntajeVariableGestionPublica;
    modalRef.componentInstance.sumaPuntajeEjeMedicion = this.sumaPuntajeEjeMedicion;
    modalRef.componentInstance.puntajeVariableGobernanzaDemocratica = this.puntajeVariableGobernanzaDemocratica;
    modalRef.componentInstance.puntajeVariableDesarrolloTerritorial = this.puntajeVariableDesarrolloTerritorial;

    modalRef.result.then( result => {
      console.log('Se ha cerrado el modal');
    })
    
  }

  verResultados() {
    const modalRef = this.modalService.open(ResultadosEiComponent, {
      size: 'xl',
      centered: true
    });

    modalRef.componentInstance. puntajeVariableGestionPublica = this.puntajeVariableGestionPublica;

    modalRef.result.then( result => {
      console.log('Se ha cerrado el modal');
    })
  }

  sumaPuntajeEjeMedicionInsitucion(){
    this.restangular.all('puntaje-promedio-eje-medicion/1/').get(this.idInstitucion).subscribe( data => {
      console.log(data);

      this.sumaPuntajeEjeMedicion = data;

        /*this.realtimeStatisticsData[0][0].data[0] = this.sumaPuntajeEjeMedicion[0].suma_puntaje;
        this.realtimeStatisticsData[0][0].data[1] = this.sumaPuntajeEjeMedicion[1].suma_puntaje;
        this.realtimeStatisticsData[0][0].data[2] = this.sumaPuntajeEjeMedicion[2].suma_puntaje;

        this.realtimeStatisticsData[0][1].data[0] = this.sumaPuntajeEjeMedicion[0].puntaje_maximo;
        this.realtimeStatisticsData[0][1].data[1] = this.sumaPuntajeEjeMedicion[1].puntaje_maximo;
        this.realtimeStatisticsData[0][1].data[2] = this.sumaPuntajeEjeMedicion[2].puntaje_maximo;

        this.realtimeStatisticsData[1][0].data[3] = this.sumaPuntajeEjeMedicion[3].suma_puntaje;
        this.realtimeStatisticsData[1][0].data[4] = this.sumaPuntajeEjeMedicion[4].suma_puntaje;
        this.realtimeStatisticsData[1][0].data[5] = this.sumaPuntajeEjeMedicion[5].suma_puntaje;

        this.realtimeStatisticsData[1][1].data[3] = this.sumaPuntajeEjeMedicion[3].puntaje_maximo;
        this.realtimeStatisticsData[1][1].data[4] = this.sumaPuntajeEjeMedicion[4].puntaje_maximo;
        this.realtimeStatisticsData[1][1].data[5] = this.sumaPuntajeEjeMedicion[5].puntaje_maximo;*/
      
      setTimeout(() => {
        
      },1000
      );
      
    });
  }

}