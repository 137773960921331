import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-como-se-mide',
  templateUrl: './como-se-mide.component.html',
  styleUrls: ['./como-se-mide.component.scss']
})
export class ComoSeMideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
