<!-- Content -->

<div class="container-xxl flex-grow-1 container-p-y">

    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-pills flex-column flex-md-row mb-4">
          <li class="nav-item">
            <a class="nav-link active" href="javascript:void(0);"
              ><i class="ti-xs ti ti-users me-1"></i> Cuenta de usuario</a
            >
          </li>
        </ul>
        <div class="card mb-4">
          <!-- Account -->
          <div class="card-body">
            <div class="d-flex align-items-start align-items-sm-center gap-4">
              <img
                src="../../assets/img/avatars/14.png"
                alt="user-avatar"
                class="d-block w-px-100 h-px-100 rounded"
                id="uploadedAvatar" />
              <div class="button-wrapper">
                <label for="upload" class="btn btn-primary me-2 mb-3" tabindex="0">
                  <span class="d-none d-sm-block">Subir fotografía</span>
                  <i class="ti ti-upload d-block d-sm-none"></i>
                  <input
                    type="file"
                    id="upload"
                    class="account-file-input"
                    hidden
                    accept="image/png, image/jpeg" />
                </label>

                <div class="text-muted">Allowed JPG, GIF or PNG. Max size of 800K</div>
              </div>
            </div>
          </div>
          <hr class="my-0" />
          <div class="card-body">
            <form id="formAccountSettings" method="POST" onsubmit="return false">
              <div class="row">
                <div class="mb-3 col-md-6">
                  <label for="firstName" class="form-label">Nombres</label>
                  <input
                    class="form-control"
                    type="text"
                    id="firstName"
                    name="firstName"
                    autofocus [(ngModel)]="usuario.nombres"/>
                </div>
                <div class="mb-3 col-md-6">
                  <label for="lastName" class="form-label">Apellidos</label>
                  <input class="form-control" type="text" name="lastName" id="lastName" [(ngModel)]="usuario.apellidos" />
                </div>
                <div class="mb-3 col-md-6">
                  <label for="lastName" class="form-label">RUN</label>
                  <input class="form-control" type="text" name="lastName" id="lastName" [(ngModel)]="usuario.rut" />
                </div>
                <div class="mb-3 col-md-6">
                  <label for="lastName" class="form-label">Contraseña</label>
                  <input class="form-control" type="text" name="lastName" id="lastName" />
                </div>
                <div class="mb-3 col-md-6">
                  <label for="email" class="form-label">E-mail</label>
                  <input
                    class="form-control"
                    type="text"
                    id="email"
                    name="email"
                    [(ngModel)]="usuario.email"
                    placeholder="john.doe@example.com" />
                </div>
                <div class="mb-3 col-md-6">
                  <label class="form-label" for="country">Institución</label>
                  <select id="country" class="select2 form-select">
                    <option value="">Select</option>
                    <option value="Australia">Australia</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Brazil">Brazil</option>
                    <option value="Canada">Canada</option>
                    <option value="China">China</option>
                    <option value="France">France</option>
                    <option value="Germany">Germany</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Japan">Japan</option>
                    <option value="Korea">Korea, Republic of</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Russia">Russian Federation</option>
                    <option value="South Africa">South Africa</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">United Arab Emirates</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                  </select>
                </div>
              </div>
              <div class="mt-2">
                <button type="submit" class="btn btn-primary me-2">Guardar cambios</button>
                <button type="reset" class="btn btn-label-secondary">Cancelar</button>
              </div>
            </form>
          </div>
          <!-- /Account -->
        </div>
      </div>
    </div>
  </div>
  <!-- / Content -->