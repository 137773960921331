import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';
import { Usuario } from 'src/app/api/modelos/Usuario';
import * as bcrypt from 'bcryptjs';
import { Institucion } from 'src/app/api/modelos/Institucion';

@Component({
  selector: 'app-crear-usuarios',
  templateUrl: './crear-usuarios.component.html',
  styleUrls: ['./crear-usuarios.component.scss']
})
export class CrearUsuariosComponent implements OnInit {

  nuevoUsuario: Usuario = {
    nombre_usuario: '',
    apellidos: '',
    contrasena: '',
    email: '',
    nombres: '',
    rut: '',
    estado: 'S',
    tipo: 'Usuario',
    discapacidad: 'N',
    dv: '0',
    institucion_id: null
  }

  @Input() public usuario;

  usuarioBase: Restangular;

  listadoInstituciones: any[];
  institucionSeleccionada: any;

  constructor(public activeModal: NgbActiveModal,
              private restangular: Restangular,
              private toastr: ToastrService) { 
                this.usuarioBase = this.restangular.one('usuarios');
              }

  ngOnInit(): void {
    this.cargarDatosUsuario();
    
  }

  

  guardarUsuario(){
    if(this.usuario){
      let jsonApiWrapper = {
        data: {
            type: 'usuarios',
            attributes: {
                nombres: this.nuevoUsuario.nombres,
                apellidos: this.nuevoUsuario.apellidos,
                rut: this.nuevoUsuario.rut,
                email: this.nuevoUsuario.email,
                institucion_id: this.institucionSeleccionada.id
            },
            id: ''
          }
      };
   jsonApiWrapper.data.id = this.usuario.id;
    this.restangular.all('usuarios/' + this.usuario.id).patch(jsonApiWrapper).subscribe(
      data => {
        this.toastr.success('el usuarios se ha editado correctamente', 'Ok');
        this.activeModal.close();
      },
      error => {
        this.toastr.error('Hubo un error al editar el usuario', 'Error');
      }
  );
    } else {
      console.log('creando');
      let jsonApiWrapper = {
        data: {
            type: 'usuarios',
            attributes: null
        }
      };

      const salt = bcrypt.genSaltSync(10);
      this.nuevoUsuario.contrasena = bcrypt.hashSync(this.nuevoUsuario.contrasena, salt);
      /*this.loginDatos.contrasena = bcrypt.hashSync(this.loginDatos.contrasena, salt);*/

      this.nuevoUsuario.institucion_id = this.institucionSeleccionada.id;
      
  
      jsonApiWrapper.data.attributes = this.nuevoUsuario;
  
      this.usuarioBase.post('', jsonApiWrapper).subscribe(
        usuario => {
              this.toastr.success('Se ha guardado correctamente el usuario', 'Ok');
              this.activeModal.close();
              this.restangular.all('send-email/crear-usuario/'+usuario.id).get('').subscribe(data => {
              });
        },
        error => {
          this.toastr.error('No se ha creado correctamente el usuario', 'Ok');
        }
          );
    }
    
        
  }

  cargarDatosUsuario(){
    this.restangular.all('institucions?filter[estado]=S').get('').subscribe(data => {
      this.listadoInstituciones = data.data;
      console.log(this.listadoInstituciones);
      if(this.usuario){
        this.nuevoUsuario = this.usuario.attributes; 
        //this.institucionSeleccionada = this.listadoInstituciones[0];
        //this.cargarInstitucionSeleccionada();
        console.log(this.usuario);
        this.institucionSeleccionada = this.listadoInstituciones.find( institucion => institucion.id == this.usuario.attributes.institucion[0].id);
        console.log(this.institucionSeleccionada);
      }
    })
  }

  seleccionarInstitucion(target){
    console.log(this.institucionSeleccionada);
  }

  cargarInstitucionSeleccionada(){
    console.log(this.usuario);
    this.restangular.all('institucions/').get(this.usuario.attributes.institucion[0].id).subscribe(data => {
      this.institucionSeleccionada = data.data;
      console.log(this.institucionSeleccionada);
    })
  }

  formato_rut(rut)
{
  console.log(rut);
    var sRut1 = rut.value;      //contador de para saber cuando insertar el . o la -
    var nPos = 0; //Guarda el rut invertido con los puntos y el guión agregado
    var sInvertido = ""; //Guarda el resultado final del rut como debe ser
    var sRut = "";
    for(var i = sRut1.length - 1; i >= 0; i-- )
    {
        sInvertido += sRut1.charAt(i);
        if (i == sRut1.length - 1 )
            sInvertido += "-";
        else if (nPos == 3)
        {
            sInvertido += ".";
            nPos = 0;
        }
        nPos++;
    }
    for(var j = sInvertido.length - 1; j >= 0; j-- )
    {
        if (sInvertido.charAt(sInvertido.length - 1) != ".")
            sRut += sInvertido.charAt(j);
        else if (j != sInvertido.length - 1 )
            sRut += sInvertido.charAt(j);
    }
    //Pasamos al campo el valor formateado
    rut.value = sRut.toUpperCase();
    console.log(rut);
}

uploadFile(){
  console.log('Subiendo archivo');
  
}
}
