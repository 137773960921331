<div class="row">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Crear Municipalidad</h4>
                <p class="card-description">
                  Ingrese los siguientes datos:
                </p>
                  <div class="form-group">
                    <label for="nombre">Nombre municipalidad</label>
                    <input type="text"  class="form-control" id="nombre" [(ngModel)]="nuevaInstitucion.nombre" placeholder="Nombre">
                  </div>
                  <br>
                  <div class="form-group">
                    <label for="descripcion">Descripción municipalidad</label>
                    <input type="text" class="form-control" id="descripcion" [(ngModel)]="nuevaInstitucion.descripcion" placeholder="Descripción">
                  </div>
                  <br>
                  <br>
                  <button style="margin-right: 10px;" type="submit" class="btn btn-primary btn-fw" (click)="guardarMunicipalidad()">Guardar</button>
                  <button style="margin-right: 10px;" class="btn btn-primary btn-fw" (click)="activeModal.close('Close click')">Cancelar</button>
              </div>
            </div>
</div>