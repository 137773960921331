import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Restangular } from 'ngx-restangular';
import { Observable } from 'rxjs';
import { ResponseCuestionario } from '../api/modelos/ResponseCuestionario';

@Injectable({
  providedIn: 'root'
})
export class PreguntasServiceService {

  private url = "http://backend.fic.test:8085/api/v1/preguntas?filter[cuestionario_id]=1";  
  private cuestionarioId = null;
  
  constructor(private http: HttpClient,
    private route: ActivatedRoute,
    private restangular: Restangular,) {

    }  
  
  getCuestionarioInstitucional(idInstitucion: number): Observable<ResponseCuestionario[]> {  
    this.cuestionarioId = localStorage.getItem('cuestionario');
    return this.restangular.all('preguntas?filter[cuestionario_id]=1&&sort=orden&&institucion=' + idInstitucion).get('');
  } 

  getCuestionarioIniciativa(idCaso: any): Observable<ResponseCuestionario[]> {  
    this.cuestionarioId = localStorage.getItem('cuestionario');
    return this.restangular.all('preguntas?filter[cuestionario_id]=2&&sort=orden&&institucion=2&&caso='+idCaso).get('');
  } 
  
  getRespuestasInstitucionales(idInstitucion: number): Observable<any[]>{
    return this.restangular.all('preguntas/institucional').get('1/'+idInstitucion);
    /* return this.restangular.one("preguntas", 1).customGET("CERRADA/1"); */
  }

  getRespuestasIniciativas(idCaso: any): Observable<any[]>{
    return this.restangular.all('preguntas').get('iniciativa/2/'+idCaso);
    /* return this.restangular.one("preguntas", 1).customGET("CERRADA/1"); */
  }

  getRespuestasAbiertasMultiplesInstitucionales(idInstitucion: number): Observable<any[]>{
    return this.restangular.all('preguntas').get('valores-multiples/institucional/1/'+idInstitucion);
    /* return this.restangular.one("preguntas", 1).customGET("CERRADA/1"); */
  }

  getRespuestasAbiertasMultiplesIniciativas(idCaso): Observable<any[]>{
    return this.restangular.all('preguntas').get('valores-multiples/iniciativa/2/'+idCaso);
    /* return this.restangular.one("preguntas", 1).customGET("CERRADA/1"); */
  }
}
