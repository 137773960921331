import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { CasoInnovacionService } from "src/app/services/caso-innovacion.service";
import { UsuariosService } from "src/app/services/usuarios.service";

@Injectable()  
export class PerfilUsuarioCargarUsuarioResolve implements Resolve<any[]> {  

  constructor(private usuarioService: UsuariosService,
              private route: ActivatedRoute) {
  }  
  
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {  
      console.log('En el resolve');
      console.log(route.params['idUsuario']);
      
    return this.usuarioService.obtenerUsuario(route.params['idUsuario']);  
  }  
} 