import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-puntajes',
  templateUrl: './puntajes.component.html',
  styles: [
  ]
})
export class PuntajesComponent implements OnInit {

  @Input() public puntajeVariableGestionPublica;
  @Input() public puntajeVariableGobernanzaDemocratica;   
  @Input() public puntajeVariableDesarrolloTerritorial;
  @Input() public sumaPuntajeEjeMedicion;
  constructor() { }

  ngOnInit(): void {
  }

}
