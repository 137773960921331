<div class="row">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Crear usuario</h4>
                <p class="card-description">
                  Ingrese los siguientes datos:
                </p>
                  <div class="form-group">
                    <label for="nombre">Nombres</label>
                    <input type="text"  class="form-control" id="nombre" [(ngModel)]="nuevoUsuario.nombres" placeholder="Nombre">
                  </div>
                  <br>
                  <div class="form-group">
                    <label for="apellidos">Apellidos</label>
                    <input type="text" class="form-control" id="apellidos" [(ngModel)]="nuevoUsuario.apellidos" placeholder="Apellidos">
                  </div>
                  <br>
                  <div class="form-group">
                    <label for="rut">RUN</label>
                    <input type="text" class="form-control" id="rut" [(ngModel)]="nuevoUsuario.rut" placeholder="XXXXXXXX-X">
                  </div>
                  <br>
                  <div class="form-group">
                    <label for="contrasena">Contraseña</label>
                    <input type="password" class="form-control" id="contrasena" [(ngModel)]="nuevoUsuario.contrasena" placeholder="Contraseña">
                  </div>
                  <br>
                  <div class="form-group">
                    <label for="email">E-mail</label>
                    <input type="text" class="form-control" id="email" [(ngModel)]="nuevoUsuario.email" placeholder="E-mail">
                  </div>
                  <br>
                  <div class="form-group">
                    <label for="email">Institución</label>
                    <input type="text" class="form-control" id="email" [(ngModel)]="nuevoUsuario.nombre_institucion" placeholder="Nombre institucion">
                  </div>
                  <br>
                  <br>
                        <button type="submit" style="margin-right: 10px;" class="btn btn-primary btn-fw" (click)="guardarUsuario()">Guardar</button>
                        <button style="margin-right: 10px;" class="btn btn-primary btn-fw" (click)="activeModal.close('Close click')">Cancelar</button>                        
              </div>
            </div>
</div>