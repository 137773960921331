<div class="row">
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Basic Tab</h4>
        <p class="card-description">Use class <code>.tab-basic</code> for basic styling</p>
        <ngb-tabset class="tab-basic">
          <ngb-tab title="Home">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/4.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Profile">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/7.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Contact">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/2.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Tab With Icon</h4>
        <p class="card-description">Use class <code>.tab-basic</code></p>
        <ngb-tabset class="tab-basic">
          <ngb-tab>
            <ng-template ngbTabTitle>
              <i class="mdi mdi-home-outline"></i>Home
            </ng-template>
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/1.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle>
              <i class="mdi mdi-account-outline"></i>Profile
            </ng-template>
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/6.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle>
              <i class="mdi mdi-message-text-outline"></i>Contact
            </ng-template>
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/3.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Vertical Basic Tab</h4>
        <p class="card-description">Use class <code>.vertical-tab</code> for vertical tab</p>
        <ngb-tabset class="tab-basic vertical-tab">
          <ngb-tab title="Home">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/9.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Profile">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/10.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Contact">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/11.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Vertical Tab With Icon</h4>
        <p class="card-description">Use class <code>.vertical-tab</code> for vertical tab</p>
        <ngb-tabset class="tab-basic vertical-tab">
          <ngb-tab>
            <ng-template ngbTabTitle>
              <i class="mdi mdi-home-outline"></i>Home
            </ng-template>
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/14.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle>
              <i class="mdi mdi-account-outline"></i>Profile
            </ng-template>
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/5.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle>
              <i class="mdi mdi-message-text-outline"></i>Contact
            </ng-template>
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/2.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Colored Tab</h4>
        <p class="card-description">Use class <code>.tab-solid</code> with <code>.tab-solid-*</code> for solid styled tab</p>
        <ngb-tabset class="tab-solid tab-solid-danger">
          <ngb-tab title="Home">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/3.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Profile">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/8.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Contact">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/10.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Colored Tab With Icons</h4>
        <p class="card-description">Use class <code>.tab-solid</code> with <code>.tab-solid-*</code> for solid styled tab</p>
        <ngb-tabset class="tab-solid tab-solid-primary">
          <ngb-tab>
            <ng-template ngbTabTitle>
              <i class="mdi mdi-home-outline"></i>Home
            </ng-template>
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/13.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle>
              <i class="mdi mdi-account-outline"></i>Profile
            </ng-template>
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/9.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle>
              <i class="mdi mdi-message-text-outline"></i>Contact
            </ng-template>
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-4"><img class="img-fluid w-100 rounded" src="assets/images/samples/300x300/8.jpg" alt=""></div>
                <div class="col-md-8">
                  <h5 class="mb-3">Heading</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi molestias iusto qui eos, vero provident ipsa neque! Molestiae, aperiam</p>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod ipsa odio dolore perspiciatis laudantium repellat quae, facere, laboriosam omnis dolorem quaerat quis assumenda!</p>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
</div>