<div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <!-- <h4 class="card-title">Colored Tab With Icons</h4> -->
        <!-- <p class="card-description">Use class <code>.tab-solid</code> with <code>.tab-solid-*</code> for solid styled tab</p> -->
        <ngb-tabset class="tab-solid tab-solid-primary">
          <ngb-tab>
            <ng-template ngbTabTitle>
              <i class="mdi mdi-home-outline"></i>Identificación de la iniciativa
            </ng-template>
            <ng-template ngbTabContent>
              <div class="col-md-12 grid-margin">
                <div class="card">
                  <div class="card-body">
                    <!-- <h4 class="card-title">Typeahead</h4> -->
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Nombre o título de la experiencia de innovación pública</label>
                          <input type="text" class="form-control form-control-lg"
                            placeholder="(indique aquí el nombre de la experiencia o iniciativa de innovación pública)"
                            aria-label="Username" [(ngModel)]="casoInnovacion.attributes.titulo">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <p>Periodo o tiempo de desarrollo de la experiencia</p>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="text" class="form-control form-control-lg"
                                placeholder="(Indique año de inicio)" aria-label="Username" [(ngModel)]="casoInnovacion.attributes.tiempo_desarrollo_desde">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <input type="text" class="form-control form-control-lg"
                                placeholder="(Indique año de término)" aria-label="Username" [(ngModel)]="casoInnovacion.attributes.tiempo_desarrollo_hasta">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
  
                    <div class="row">
                      <div class="col-md-6">
                        <p>Clasificación de la experiencia</p>
                        <div class="form-group">
                          <div class="form-check">
                            <input type="radio" class="form-check-input" aria-label="Username" name="clasificacion"
                              id="clasificacion" value="1">
                            <label class="form-check-label">Innovación en servicios (aquella donde se introduce un
                              nuevo servicio o una mejora de la calidad de un servicio existente)</label>
                          </div>
                          <div class="form-check">
                            <input type="radio" class="form-check-input" aria-label="Username" name="clasificacion"
                              id="clasificacion" value="2">
                            <label class="form-check-label">2. Innovación en la prestación los servicios (cambios en la
                              forma de proveer servicios públicos)</label>
                          </div>
                          <div class="form-check">
                            <input type="radio" class="form-check-input" aria-label="Username" name="clasificacion"
                              id="clasificacion" value="3">
                            <label class="form-check-label">3. Innovación administrativa (cambios en las estructuras
                              organizativas, prácticas de gestión, rutinas del personal)</label>
                          </div>
                          <div class="form-check">
                            <input type="radio" class="form-check-input" aria-label="Username" name="clasificacion"
                              id="clasificacion" value="3">
                            <label class="form-check-label">4. Innovación en las políticas (cambio en el pensamiento o
                              intención del comportamiento, modificándose políticas públicas, programas o
                              reformas)</label>
                          </div>
                          <div class="form-check">
                            <input type="radio" class="form-check-input" aria-label="Username" name="clasificacion"
                              id="clasificacion" value="4">
                            <label class="form-check-label">5. Innovación sistémica (nuevas o mejores formas de acción
                              entre organización o fuentes de conocimiento)</label>
                          </div>
                          <div class="form-check">
                            <input type="radio" class="form-check-input" aria-label="Username" name="clasificacion"
                              id="clasificacion" value="5">
                            <label class="form-check-label">6. Otra Clasificación. Especifique</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <p>Temática de la experiencia de innovación pública</p>
                        <div class="form-group">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" aria-label="Username">
                            <label class="form-check-label">1. Innovación en servicios (aquella donde se introduce un
                              nuevo servicio o una mejora de la calidad de un servicio existente)</label>
                          </div>
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" aria-label="Username">
                            <label class="form-check-label">2. Innovación en la prestación los servicios (cambios en la
                              forma de proveer servicios públicos)</label>
                          </div>
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" aria-label="Username">
                            <label class="form-check-label">3. Innovación administrativa (cambios en las estructuras
                              organizativas, prácticas de gestión, rutinas del personal)</label>
                          </div>
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" aria-label="Username">
                            <label class="form-check-label">4. Innovación en las políticas (cambio en el pensamiento o
                              intención del comportamiento, modificándose políticas públicas, programas o
                              reformas)</label>
                          </div>
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" aria-label="Username">
                            <label class="form-check-label">5. Innovación sistémica (nuevas o mejores formas de acción
                              entre organización o fuentes de conocimiento)</label>
                          </div>
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" aria-label="Username">
                            <label class="form-check-label">6. Otra Clasificación. Especifique</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle>
              <i class="mdi mdi-account-outline"></i>Identificación de la institución
            </ng-template>
            <ng-template ngbTabContent>
              <div class="col-md-12 grid-margin">
                <div class="card">
                  <div class="card-body">
                    <!-- <h4 class="card-title">Typeahead</h4> -->
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Nombre de la Institución</label>
                          <input type="text" class="form-control form-control-lg"
                            placeholder="(indique aquí el nombre de la institución municipal)" aria-label="Username" [(ngModel)]="casoInnovacion.attributes.nombre_institucion">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <p>Caracterización del municipio en que tuvo lugar la experiencia</p>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Categoría de Municipalidad según SUBDERE</label>
                              <input type="text" class="form-control form-control-lg"
                                placeholder="(indique aquí la categoría SUBDERE)" aria-label="Username" [(ngModel)]="casoInnovacion.attributes.categoria_muni_subdere">
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Caracterización general de la comuna</label>
                              <input type="text" class="form-control form-control-lg"
                                placeholder="(indique aquí la categoría SUBDERE)" aria-label="Username" [(ngModel)]="casoInnovacion.attributes.caraterizacion_general_comuna">
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Presupuesto Anual Municipal ($)</label>
                              <input type="text" class="form-control form-control-lg"
                                placeholder="(indique aquí el presupuesto)" aria-label="Username" [(ngModel)]="casoInnovacion.attributes.presupuesto_anual_municipal">
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Otros:</label>
                              <input type="text" class="form-control form-control-lg"
                                placeholder="(indique aquí otros elementos de relevantes que caractericen al municipio, que no fueron mencionados anteriormente)"
                                aria-label="Username" [(ngModel)]="casoInnovacion.attributes.caracterizacion_otros">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Provincia</label>
                          <input type="text" class="form-control form-control-lg"
                            placeholder="(indique aquí la provincia)" aria-label="Username" [(ngModel)]="casoInnovacion.attributes.provincia">
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Región</label>
                          <input type="text" class="form-control form-control-lg" placeholder="(indique aquí la región)"
                            aria-label="Username" [(ngModel)]="casoInnovacion.attributes.region">
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Habitantes de la comuna</label>
                          <input type="text" class="form-control form-control-lg"
                            placeholder="(indique aquí el número de habitantes de su comuna)" aria-label="Username" [(ngModel)]="casoInnovacion.attributes.cantidad_habitantes_comuna">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Nombre del (la) encargado(a) de completar este formulario</label>
                          <input type="text" class="form-control form-control-lg"
                            placeholder="(indique aquí la provincia)" aria-label="Username" [(ngModel)]="casoInnovacion.attributes.encargado_formulario">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <p>Datos de Contacto</p>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Teléfono: </label>
                              <input type="text" class="form-control form-control-lg"
                                placeholder="(indique número telefónico de contacto)" aria-label="Username" [(ngModel)]="casoInnovacion.attributes.telefonos_contacto">
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>E-mail</label>
                              <input type="text" class="form-control form-control-lg"
                                placeholder="(indique e-mail de contacto)" aria-label="Username" [(ngModel)]="casoInnovacion.attributes.email">
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Sitio Web</label>
                              <input type="text" class="form-control form-control-lg"
                                placeholder="(indique sitio web institucional)" aria-label="Username" [(ngModel)]="casoInnovacion.attributes.sitio_web">
                            </div>
                          </div>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle>
              <i class="mdi mdi-message-text-outline"></i>Caracterización de la iniciativa
            </ng-template>
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Objetivos propuestos de la experiencia de innovación pública </label>
                    <textarea class="form-control" id="exampleTextarea1" rows="10" 
                    placeholder="¿Qué buscaban realizar/lograr con la experiencia y quienes son los beneficiados directos?"
                    [(ngModel)]="casoInnovacion.attributes.objetivos_propuestos"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Describa el problema/necesidad/oportunidad que buscó abordar la experiencia</label>
                    <textarea class="form-control" id="exampleTextarea1" rows="10" placeholder="-	Describa el contexto e hitos. 
  -	Indique el origen de la iniciativa (desde la ciudadanía, funcionarios, otra entidad, etc.)
  -	Mencione y caracterice los elementos que intervinieron, hechos y circunstancias que rodeaban al problema/necesidad u oportunidad. 
  -	Identifique quiénes eran los afectados por este problema/necesidad/oportunidad
                    " [(ngModel)]="casoInnovacion.attributes.problema_necesidad"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Describa los resultados/impactos esperados del desarrollo de esta iniciativa</label>
                    <textarea class="form-control" id="exampleTextarea1" rows="10" 
                    placeholder="-	Mencione y caracterice los resultados o impactos esperados y/o observados" 
                    [(ngModel)]="casoInnovacion.attributes.resultados_impactos"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Caracterización de la situación previa a la experiencia</label>
                    <textarea class="form-control" id="exampleTextarea1" rows="10" 
                    placeholder="-	¿Cuál era el contexto antes del desarrollo de la experiencia de innovación? 
  -	¿Cómo se veía afectado el público objetivo?"
  [(ngModel)]="casoInnovacion.attributes.situacion_previa"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Etapas o fases del desarrollo de la experiencia de innovación pública</label>
                    <textarea class="form-control" id="exampleTextarea1" rows="10" placeholder="-	¿Cuántas y cuales etapas o fases del desarrollo de la experiencia fueron definidas? 
  Por ejemplo: 
      - Fase de Identificación e investigación del problema
      - Fase de Diseño de propuesta de solución (prototipo, testeo y evaluación)
      - Fase de Desarrollo de piloto (diseño, marcha blanca y evaluación)
      - Fase de Implementación de la solución
      - Fase de Control y evaluación de la implementación.
  -	¿Cuáles etapas se pudieron desarrollar?" [(ngModel)]="casoInnovacion.attributes.fases_desarrollo"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Caracterización y organización del equipo de trabajo que llevó a cabo la experiencia</label>
                    <textarea class="form-control" id="exampleTextarea1" rows="10" placeholder="-	¿Qué unidad municipal lideró esta iniciativa?
  -	¿Cuántos funcionarios integraron el equipo de trabajo?
  -	Entre los miembros del equipo de trabajo, ¿Había expertos en temáticas de innovación
  -	¿Participaron funcionarios de otras áreas o unidades del mismo municipio? Identifique esas unidades.
  -	¿Participaron funcionarios públicos de otras instituciones? Identifique dichas instituciones
  -	¿Existía un responsable o líder en el equipo?
  -	¿Cómo se organizaron y dividieron el trabajo?" [(ngModel)]="casoInnovacion.attributes.organizacion_equipo"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Describa cómo elaboraron y desarrollaron la experiencia para el problema detectado</label>
                    <textarea class="form-control" id="exampleTextarea1" rows="10" placeholder="-	¿Cómo fue el proceso de elaboración y desarrollo de la iniciativa? 
  -	¿Hubo un trabajo colaborativo y de retroalimentación interna con funcionarios de otras unidades del municipio? ¿En qué fases del desarrollo participaron estas unidades?
  -	¿La iniciativa contó con respaldo de otras instituciones o entidades? De ser así ¿En qué fases del desarrollo participaron estas entidades? ¿Qué tipo de apoyo recibieron? ¿surgieron convenios? ¿se ofrecieron o entregaron aportes pecuniarios y/o valorados? 
  -	¿Con cuáles actores clave, directos o indirectos, tuvieron que relacionarse para ejecutar y desarrollar la experiencia? (desde la sociedad civil, academia, Instituciones privadas o públicas)
  -	¿Existió presupuesto para el desarrollo de la iniciativa? ¿Cuál fue el origen del presupuesto?" [(ngModel)]="casoInnovacion.attributes.elaboracion_desarrollo"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Participación ciudadana</label>
                    <textarea class="form-control" id="exampleTextarea1" rows="10" 
                    placeholder="-	¿En qué etapas del desarrollo de la iniciativa participó la ciudadanía? Por ejemplo: ¿participó la ciudadanía en la identificación del problema, en la co-creación de soluciones, en el testeo del prototipo?
  -	¿Qué mecanismos o instancias de participación ciudadana se implementaron? 
  -	¿Cómo fue el proceso?" [(ngModel)]="casoInnovacion.attributes.participacion_ciudadana"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>¿Cómo se llevó a cabo el proceso de implementación de la experiencia?</label>
                    <textarea class="form-control" id="exampleTextarea1" rows="10" placeholder="-	¿Cuáles fueron las gestiones y procesos administrativos que se realizaron para formalizar y hacer parte de la gestión municipal a la iniciativa? 
  -	¿Con cuáles se relacionaron para este proceso?
  -	¿En qué grado ha sido lograda la implementación de la iniciativa? " [(ngModel)]="casoInnovacion.attributes.implementacion"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Evaluación del impacto de la experiencia</label>
                    <textarea class="form-control" id="exampleTextarea1" rows="10" placeholder="-	¿El desarrollo de esta iniciativa está mejorando los procesos de gestión pública?
  -	¿Qué cambios generó en el quehacer municipal? ¿Y en el público objetivo? ¿Y en el territorio?
  -	¿La ciudadanía manifestó que su percepción de la calidad del servicio mejoró?
  -	¿Se realizó una evaluación de satisfacción dirigida a los usuarios beneficiados con la iniciativa?
  -	¿La iniciativa recibió algún tipo reconocimiento público?" [(ngModel)]="casoInnovacion.attributes.impacto"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Aprendizajes del desarrollo de la experiencia</label>
                    <textarea class="form-control" id="exampleTextarea1" rows="10" 
                    placeholder="-	¿Qué aprendizaje institucional significó el desarrollo de la experiencia? (en términos de gestión, estrategias políticas, identificación de déficit de capacidad institucional, etc.)"
                    [(ngModel)]="casoInnovacion.attributes.aprendizaje"></textarea>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>