<!-- Layout wrapper -->
<div class="layout-wrapper layout-content-navbar">
  <div class="layout-container"> 
    <app-sidebar></app-sidebar>

    <!-- Layout container -->
    <div class="layout-page"> 
      <app-navbar></app-navbar>
      <!-- Content wrapper -->
      <div class="content-wrapper"> 
        <router-outlet></router-outlet>
        <app-footer></app-footer>
        <div class="content-backdrop fade"></div>
      </div>
    </div>
  </div>
  <!-- Overlay -->
  <div class="layout-overlay layout-menu-toggle"></div>
  
  <!-- Drag Target Area To SlideIn Menu On Small Screens -->
  <div class="drag-target"></div>
</div>


<!-- / Layout page -->
<!-- Content wrapper -->
<!--<div class="container-scroller">
  <app-navbar></app-navbar>
  <div class="container-fluid page-body-wrapper">
    <app-sidebar></app-sidebar>
    <div class="main-panel">
      <div class="content-wrapper">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>-->