<div class="card col-md-12">
    <img src="../../../assets/images/1.jpg" class="card-img-top" alt="...">
    <div class="card-body">
        <h3 class="card-title">Descubre la Innovación Pública Local</h3>
        <p class="card-description">
            ¡Caminemos juntos hacia el fortalecimiento de una gestión Pública local y hacia la instalación de una
            cultura de Innovación!
        </p>
        <p>¿Por qué medir la innovación pública?
            La plataforma de medición de innovación pública Métrica Pública, busca que los
            municipios puedan medir el nivel de innovación pública en su gestión.</p>
        <h3 class="card-title">Pero, ¿cuál es la finalidad de ello? ¡La cultura de la innovación!</h3>
        <p class="card-description">
            Al medir la innovación pública, se busca que los municipios se hagan preguntas
esenciales que le permitan aumentar sus capacidades de innovación y así transitar hacia
la instalación de una cultura de la innovación al interior de su organización.
        </p>
        <p>Al utilizar esta herramienta de medición los gobiernos locales podrán:</p>
        <ul>
            <li>Visualizar los resultados de la medición de la innovación pública, tanto del
                municipio, como de iniciativas implementadas</li>
            <li>Comprender cuáles son las fortalezas y/o debilidades en su gestión.</li>
            <li>Hacerse preguntas claves, que les permitan avanzar en mejoras en su gestión.</li>
            <li>Implementar acciones de mejora.</li>
            <li>Planificar con enfoque innovador, considerando los resultados de la medición</li>
        </ul>
        <p>La plataforma generará un análisis del estado de la medición, podrás saber en qué
            puedes mejorar. También podrás visibilizar resultados globales de los municipios que han
            medido la innovación en su gestión.</p>
    </div>
</div>