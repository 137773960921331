import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  
  constructor(private http: HttpClient,
    private restangular: Restangular,) {}  
  
    obtenerPerfilesDisponiblesUsuario(usuario): Observable<any[]> {  
        return this.restangular.all('usuario/rolesDisponibles/'+usuario.id).get('');
    }

    obtenerPerfilesAsignadosUsuario(usuario): Observable<any[]> {  
        return this.restangular.all('usuario/roles/'+usuario.id).get('');
    }

    obtenerPerfilesAsignadosUsuariosRestangular(usuario): Observable<any[]> {
      return this.restangular.all('rolusuario?filter[usuario_id]='+usuario.id).get('');
    }

    obtenerUsuario(usuario): Observable<any[]> {
      return this.restangular.all('usuarios/'+usuario).get('');
    }
}