import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';


const routes: Routes = [{
  path: 'login', component: LoginComponent,
  /*children: [
      /!*{ path: 'pacientes', loadChildren: '../pacientes/pacientes.module#PacientesModule', canActivate: [AuthGuard]},
      { path: 'solicitudes',  loadChildren: '../solicitudes/solicitudes.module#SolicitudesModule', canActivate: [AuthGuard]},
      { path: 'cajas', loadChildren: '../cajas/cajas.module#CajasModule', canActivate: [AuthGuard]},
      { path: 'mantenedores', loadChildren: '../mantenedores/mantenedores.module#MantenedoresModule', canActivate: [AuthGuard]},
      { path: 'usuario', loadChildren: '../usuario/usuario.module#UsuarioModule', canActivate: [AuthGuard] }*!/
  ]*/
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicoRoutingModule { }
