import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';
import { CasoInnovacions } from 'src/app/api/modelos/CasoInnovacion';
import { Catalogo } from 'src/app/api/modelos/Catalogo';

@Component({
  selector: 'app-crear-caso-innovacion',
  templateUrl: './crear-caso-innovacion.component.html',
  styleUrls: ['./crear-caso-innovacion.component.scss']
})
export class CrearCasoInnovacionComponent implements OnInit {

  listaInstituciones: any[] = [];
  institucionSeleccionada: any;

  listaTematicas: any[] = [];
  tematicaSeleccionada: Catalogo;

  listaProvincias: Catalogo[] = [];
  provinciaSeleccionada: Catalogo;

  listaRegiones: Catalogo[] = [];
  regionSeleccionada: Catalogo;

  titulo: string;
  anioCaso: number;
  habitantesComuna: number;
  sitioWebCaso: string;
  emailCaso: string;
  telefonosCaso: string;

  casoInnovacion: CasoInnovacions = {
    nombre: "",
    usuario_id: 1,
    anio: null,
    aprendizaje: "",
    cantidad_habitantes_comuna: null,
    caracterizacion_otros: "",
    caraterizacion_general_comuna: "",
    categoria_muni_subdere: "",
    clasificacion_experiencia: null,
    clasificacion_experiencia_otra: "",
    descripcion: "",
    elaboracion_desarrollo: "",
    email: "",
    encargado_formulario: "",
    fases_desarrollo: "",
    impacto: "",
    implementacion: "",
    nombre_institucion: "",
    objetivos_propuestos: "",
    organizacion_equipo: "",
    participacion_ciudadana: "",
    presupuesto_anual_municipal: null,
    problema_necesidad: "",
    provincia: "",
    region: "",
    resultados_impactos: "",
    sitio_web: "",
    situacion_previa: "",
    telefonos_contacto: "",
    tematica: "",
    tiempo_desarrollo_desde:null,
    tiempo_desarrollo_hasta: null,
    titulo: "",
    institucion_id: null,
    estado: "Nuevo"
  }

  casoInnovacionBase: Restangular;
  cuestionarioCasoBase: Restangular;
  idInstitucion: number;

  form: FormGroup;
  ordersData = [
    { id: 'Gestión Interna', name: 'Gestión Interna' },
    { id: 'Migrantes', name: 'Migrantes' },
    { id: 'Medio Ambiente', name: 'Medio Ambiente' },
    { id: 'Salud', name: 'Salud' },
    { id: 'Educación', name: 'Educación' },
    { id: 'Comunicaciones', name: 'Comunicaciones' },
    { id: 'Desarrollo Territorial', name: 'Desarrollo Territorial' },
    { id: 'Planificación comunal', name: 'Planificación comunal' },
    { id: 'Desarrollo comunitario', name: 'Desarrollo comunitario' },
    { id: 'Vivienda', name: 'Vivienda' },
    { id: 'Jóvenes', name: 'Jóvenes' },
    { id: 'Asociatividad', name: 'Asociatividad' },
    { id: 'Organizaciones comunitaria', name: 'Organizaciones comunitaria' },
    { id: 'Otros(s)', name: 'Otros(s)' },
  ];


  constructor(public activeModal: NgbActiveModal,
              private restangular: Restangular,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private router: Router,
              private formBuilder: FormBuilder) {
                this.casoInnovacionBase = this.restangular.one('casoinnovacions');
                this.cuestionarioCasoBase = this.restangular.one('cuestionariocasos');
                this.form = this.formBuilder.group({
                  orders: new FormArray([])
                });

                this.addCheckboxes();
               }

  ngOnInit(): void {


    this.listaInstituciones = this.route.snapshot.data.instituciones.data;
    this.listaTematicas = this.route.snapshot.data.tematicas.data;
    this.listaProvincias = this.route.snapshot.data.provincias.data;
    this.listaRegiones = this.route.snapshot.data.regiones.data;
    this.idInstitucion = JSON.parse(localStorage.getItem('institucion')).id;
    // this.cargarInstituciones();
  }

  get ordersFormArray() {
    return this.form.controls.orders as FormArray;
  }

  private addCheckboxes() {
    this.ordersData.forEach(() => this.ordersFormArray.push(new FormControl(false)));
  }

  cargarInstituciones(){
    this.restangular.all('institucions?filter[estado]=S').get('').subscribe(data => {
      this.listaInstituciones = data.data;
    });
  }

  seleccionarRegion(){
    console.log(this,this.provinciaSeleccionada);
    this.restangular.all('catalogos?filter[nombre]=REGIONES&&filter[key2]=' + this.provinciaSeleccionada.attributes.key3).get('').subscribe(data => {
      this.regionSeleccionada = data.data[0];
    },
    () => {

    });
  }

  guardarCaso(){
    this.idInstitucion = JSON.parse(localStorage.getItem('institucion')).id;
    let jsonApiWrapper = {
      data: {
          type: 'casoinnovacions',
          attributes: null
      }
    };

    if(this.casoInnovacion.clasificacion_experiencia == 'Otra'){
      this.casoInnovacion.clasificacion_experiencia = this.casoInnovacion.clasificacion_experiencia_otra;
    }

    /* const selectedOrderIds = this.form.value.orders
      .map((checked, i) => checked ? this.ordersData[i].id : null)
      .filter(v => v !== null);
    console.log(selectedOrderIds.toString());

    if(selectedOrderIds){
      this.casoInnovacion.tematica = selectedOrderIds.toString();
    } */

    this.casoInnovacion.institucion_id = this.idInstitucion;
    jsonApiWrapper.data.attributes = this.casoInnovacion;

    this.casoInnovacionBase.post('', jsonApiWrapper).subscribe(
      caso => {
        let jsonApiWrapper = {
          data: {
            type: 'cuestionariocasos',
            attributes: {
              cuestionario_id: 2,
              caso_innovacion_id: caso.data.id
            }
          }
        }
        this.cuestionarioCasoBase.post('', jsonApiWrapper).subscribe(
          data => {
            this.toastr.success('Se ha guardado correctamente la iniciativa', 'Ok');
            this.activeModal.close();
            this.restangular.all('send-email/caso-creado').get('').subscribe(data => {
            });
            /* this.router.navigateByUrl('listado-casos-municipio'); */
          }
        )
        
      },
      () => {
        // this.toastr.success('Se ha guardado correctamente la iniciativa', 'Ok');
      }); 

  }

}
