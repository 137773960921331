import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';
import { DetalleCasoComponent } from '../casos-innovacion/detalle-caso/detalle-caso.component';
import { PuntajeCasoComponent } from '../puntaje-caso/puntaje-caso.component';

@Component({
  selector: 'app-evaluar-casos',
  templateUrl: './evaluar-casos.component.html',
  styleUrls: ['./evaluar-casos.component.scss']
})
export class EvaluarCasosComponent implements OnInit {

  listadoCasos: any[] = [];
  idInstitucion: any;
  estadoCasoAprobado = 'Aprobado';

  constructor(private restangular: Restangular,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal, 
    public activeModal: NgbActiveModal) {
      this.idInstitucion = JSON.parse(localStorage.getItem('institucion')).id;
     }

  ngOnInit(): void {
    this.cargarCasos();
  }

  cargarCasos(){
    this.restangular.all('casoinnovacions?filter[institucion_id]='+this.idInstitucion+'&&filter[estado]='+this.estadoCasoAprobado).get('').subscribe(data => {
      this.listadoCasos = data.data;
    })
  }

  goEncuestaIniciativa(caso){
    this.router.navigate(['/encuesta-iniciativa',caso.id]);
  }

  abrirDetalleCaso(caso){
    this.router.navigate(['/detalle-evaluar-caso',caso.id]);
  }

  abrirDetalleCasoModal(caso){
    const modalRef = this.modalService.open(DetalleCasoComponent, {
      size: '500',
      centered: true
    });

    modalRef.componentInstance.caso = caso;

    modalRef.result.then( result => {
      console.log('Se ha cerrado el modal');
      //this.cargarMunicipalidades();
    })
  };

  verPuntajeCasoModal(casoInnovacion){
    const modalRef = this.modalService.open(PuntajeCasoComponent, {
      size: 'xl',
      centered: true
    });

    modalRef.componentInstance.casoInnovacion = casoInnovacion;

    modalRef.result.then( result => {
      console.log('Se ha cerrado el modal');
      //this.cargarMunicipalidades();
    })
  }

}
