import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flag-icons',
  templateUrl: './flag-icons.component.html',
  styleUrls: ['./flag-icons.component.scss']
})
export class FlagIconsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
