import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { DashboardComponent } from "./screens/dashboard/dashboard.component";
import { Dashboard2Component } from "./screens/dashboard-2/dashboard-2.component";
import { WidgetsComponent } from "./screens/widgets/widgets.component";

import { ButtonsComponent } from "./ui-elements/buttons/buttons.component";
import { TablesComponent } from "./ui-elements/tables/tables.component";
import { TypographyComponent } from "./ui-elements/typography/typography.component";
import { AlertsComponent } from "./ui-elements/alerts/alerts.component";
import { AccordionsComponent } from "./ui-elements/accordions/accordions.component";
import { BadgesComponent } from "./ui-elements/badges/badges.component";
import { ProgressbarComponent } from "./ui-elements/progressbar/progressbar.component";
import { BreadcrumbsComponent } from "./ui-elements/breadcrumbs/breadcrumbs.component";
import { PaginationComponent } from "./ui-elements/pagination/pagination.component";
import { DropdownComponent } from "./ui-elements/dropdown/dropdown.component";
import { TooltipsComponent } from "./ui-elements/tooltips/tooltips.component";
import { CarouselComponent } from "./ui-elements/carousel/carousel.component";
import { TabsComponent } from "./ui-elements/tabs/tabs.component";

import { DragulaComponent } from "./advanced-elements/dragula/dragula.component";
import { LoaderComponent } from "./advanced-elements/loader/loader.component";

import { EmailComponent } from "./apps/email/email.component";
import { TodoListComponent } from "./apps/todo-list/todo-list.component";

import { FlagIconsComponent } from "./icons/flag-icons/flag-icons.component";
import { FontAwesomeComponent } from "./icons/font-awesome/font-awesome.component";
import { SimpleLineIconsComponent } from "./icons/simple-line-icons/simple-line-icons.component";
import { MdiComponent } from "./icons/mdi/mdi.component";

import { ChartjsComponent } from "./charts/chartjs/chartjs.component";
import { ChartistComponent } from "./charts/chartist/chartist.component";

import { InvoiceComponent } from "./sample-pages/invoice/invoice.component";
import { PricingTableComponent } from "./sample-pages/pricing-table/pricing-table.component";
import { LoginComponent } from "./publico/login/login.component";
import { RegisterComponent } from "./sample-pages/register/register.component";
import { Page404Component } from "./sample-pages/page404/page404.component";
import { Page500Component } from "./sample-pages/page500/page500.component";
import { InicioComponent } from "./privado/inicio/inicio.component";
import { ModuleWithProviders } from "@angular/compiler/src/core";

const routes: Routes = [
  {path: '', loadChildren: './privado/privado.module#PrivadoModule'},
  //{ path: "", redirectTo: "/login-component", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "dashboard-2", component: Dashboard2Component },
  { path: "widgets", component: WidgetsComponent },
  { path: "buttons", component: ButtonsComponent },
  { path: "tables", component: TablesComponent },
  { path: "flag-icon", component: FlagIconsComponent },
  { path: "font-awesome", component: FontAwesomeComponent },
  { path: "mdi", component: MdiComponent },
  { path: "simple-line", component: SimpleLineIconsComponent },
  { path: "typography", component: TypographyComponent },
  { path: "alerts", component: AlertsComponent },
  { path: "accordions", component: AccordionsComponent },
  { path: "badges", component: BadgesComponent },
  { path: "progressbar", component: ProgressbarComponent },
  { path: "breadcrumbs", component: BreadcrumbsComponent },
  { path: "pagination", component: PaginationComponent },
  { path: "dropdowns", component: DropdownComponent },
  { path: "tooltips", component: TooltipsComponent },
  { path: "carousel", component: CarouselComponent },
  { path: "tabs", component: TabsComponent },
  { path: "dragula", component: DragulaComponent },
  { path: "loaders", component: LoaderComponent },
  { path: "email", component: EmailComponent },
  { path: "todo-list", component: TodoListComponent },
  { path: "chartjs", component: ChartjsComponent },
  { path: "chartist", component: ChartistComponent },
  { path: "invoice", component: InvoiceComponent },
  { path: "pricing-table", component: PricingTableComponent },
  //{ path: "login", component: LoginComponent },
  { path: "register", component: RegisterComponent },
  { path: "404-page", component: Page404Component },
  { path: "500-page", component: Page500Component }
];

/*@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})*/
//export class AppRoutingModule {}
export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled',useHash: true});
