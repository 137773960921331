import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';
import { AgregarInstitucionComponent } from './agregar-institucion/agregar-institucion.component';

@Component({
  selector: 'app-instituciones',
  templateUrl: './instituciones.component.html',
  styleUrls: ['./instituciones.component.css']
})
export class InstitucionesComponent implements OnInit {

  listadoInstituciones: any[] = [];
  idInstitucion: any;
  institucionSeleccionada: any;

  constructor(private restangular: Restangular,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal, 
    public activeModal: NgbActiveModal) {
      this.idInstitucion = JSON.parse(localStorage.getItem('institucion')).id;
     }

  ngOnInit(): void {
    this.cargarMunicipalidades();
  }

  cargarMunicipalidades(){
    this.restangular.all('institucions?filter[estado]=S').get('').subscribe(data => {
      this.listadoInstituciones = data.data;
      console.log(this.listadoInstituciones);
    })
  }

  crearMunicipalidad(){
    const modalRef = this.modalService.open(AgregarInstitucionComponent, {
      size: '50%',
      centered: true
    });

    modalRef.result.then( result => {
      console.log('Se ha cerrado el modal');
      this.cargarMunicipalidades();
    })

  }

  editarMunicipalidad(institucion){
    const modalRef = this.modalService.open(AgregarInstitucionComponent, {
      size: '50%',
      centered: true
    });
    modalRef.componentInstance.institucion = institucion;

  }

  eliminarMunicipalidad(institucion) {
    if(confirm("¿Desea eliminar la municipalidad?")) {
    console.log('eliminando');
      let jsonApiWrapper = {
        data: {
            type: 'institucions',
            attributes: {
              estado: 'N'
          },
            id: ''
          }
      };
   jsonApiWrapper.data.id = institucion.id;
    this.restangular.all('institucions/' + institucion.id).patch(jsonApiWrapper).subscribe(
      data => {
        console.log(data);
        this.toastr.success('La municipalidad se ha eliminado correctamente', 'Ok');
        //this.activeModal.close();
        this.cargarMunicipalidades();
      },
      error => {
        this.toastr.error('Hubo un error al eliminar la municipalidad', 'Error');
      }
  );
  }
  }

}
