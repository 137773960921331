import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';
import { Usuario } from 'src/app/api/modelos/Usuario';
import * as bcrypt from 'bcryptjs';
import { RolUsuario } from 'src/app/api/modelos/RolUsuario';
import { MensajeValidacion } from 'src/app/api/modelos/MensajeValidacion';

@Component({
  selector: 'app-registro-usuario',
  templateUrl: './registro-usuario.component.html',
  styleUrls: ['./registro-usuario.component.scss']
})
export class RegistroUsuarioComponent implements OnInit {

  nuevoUsuario: Usuario = {
    nombre_usuario: '',
    apellidos: '',
    contrasena: '',
    email: '',
    nombres: '',
    rut: '',
    estado: 'S',
    tipo: 'Usuario',
    discapacidad: 'N',
    dv: '0',
    institucion_id: 1
  }

  respuesta: MensajeValidacion = {
    tipo_respuesta: 'Error',
    codigo: false,
    mensaje: 'Error en la creación de usuario'
  };


  usuarioBase: Restangular;
  rolUsuarioBase: Restangular;

  listadoInstituciones: any[];
  institucionSeleccionada: any;

  cantidadUsuario: number;

  constructor(public activeModal: NgbActiveModal,
              private restangular: Restangular,
              private toastr: ToastrService) { 
                this.usuarioBase = this.restangular.one('usuarios');
                this.rolUsuarioBase = this.restangular.one('rolusuario');
              }
              
  ngOnInit(): void {
    
  }

  guardarUsuario2(){
    this.formato_rut(this.nuevoUsuario.rut);
  }

  guardarUsuario(){ 
   /*  this.validarDatos(this.nuevoUsuario); */
    var mensajeTemp = '';
    this.restangular.all('usuario/existeRut/').get(this.nuevoUsuario.rut).subscribe(data => {
      if(data[0].cantidad == 0) {
        if(this.nuevoUsuario.nombres == ''){
          mensajeTemp += 'El nombre del usuario es obligatorio ' + '</br>';
          
        }
        if(this.nuevoUsuario.apellidos == ''){
          mensajeTemp += 'Los apellidos del usuario son obligatorios ' + '</br>';
          
        }
        if(this.nuevoUsuario.rut == ''){
          mensajeTemp += 'El rut del usuario es obligatorio ' + '</br>';
          
        }
        if(this.nuevoUsuario.contrasena == ''){
          mensajeTemp += 'La contraseña del usuario es obligatoria ' + '</br>';
          
        }
        if(this.nuevoUsuario.email == ''){
          mensajeTemp += 'El email del usuario es obligatorio ' + '</br>';
          
        }
      } else {
        mensajeTemp += 'El usuario ingresado ya existe en la base de datos ' + '</br>';
      }

      if(mensajeTemp == ''){
        this.respuesta.codigo = true;
      } else {
        this.respuesta.codigo = false;
        this.respuesta.mensaje = mensajeTemp;
      }

      if(this.respuesta.codigo) {
        console.log('creando');
        let jsonApiWrapper = {
          data: {
              type: 'usuarios',
              attributes: null
          }
        };
  
        const salt = bcrypt.genSaltSync(10);
        this.nuevoUsuario.contrasena = bcrypt.hashSync(this.nuevoUsuario.contrasena, salt);
        /*this.loginDatos.contrasena = bcrypt.hashSync(this.loginDatos.contrasena, salt);*/
    
        jsonApiWrapper.data.attributes = this.nuevoUsuario;
    
        this.usuarioBase.post('', jsonApiWrapper).subscribe(
          usuario => {
                this.toastr.success('Se ha guardado correctamente el usuario', 'Ok');
                let jsonApiWrapperRolUsuario = {
                  data: {
                      type: 'rolusuario',
                      attributes: null
                  }
                };
                let rolUsuario: RolUsuario = {
                  rol_id: 4,
                  usuario_id: Number(usuario.data.id)
                }
                jsonApiWrapperRolUsuario.data.attributes = rolUsuario;
                this.rolUsuarioBase.post('', jsonApiWrapperRolUsuario).subscribe(
                  data => {
                    this.activeModal.close();
                    this.restangular.all('send-email/crear-usuario/'+usuario.data.id).get('').subscribe(data => {
                    });
                  }
                );
                
          },
          error => {
            this.toastr.error('No se ha creado correctamente el usuario', 'Ok');
          }
            );
      } else {
        this.toastr.error(this.respuesta.mensaje, this.respuesta.tipo_respuesta, { closeButton: true, timeOut: 4000, progressBar: true, enableHtml: true });
      }

    });

    
      
    
        
  }

  formato_rut(rut)
  {
    console.log(rut);
      var sRut1 = rut;      //contador de para saber cuando insertar el . o la -
      var nPos = 0; //Guarda el rut invertido con los puntos y el guión agregado
      var sInvertido = ""; //Guarda el resultado final del rut como debe ser
      var sRut = "";
      for(var i = sRut1.length - 1; i >= 0; i-- )
      {
          sInvertido += sRut1.charAt(i);
          if (i == sRut1.length - 1 )
              sInvertido += "-";
          else if (nPos == 3)
          {
              sInvertido += ".";
              nPos = 0;
          }
          nPos++;
      }
      for(var j = sInvertido.length - 1; j >= 0; j-- )
      {
          if (sInvertido.charAt(sInvertido.length - 1) != ".")
              sRut += sInvertido.charAt(j);
          else if (j != sInvertido.length - 1 )
              sRut += sInvertido.charAt(j);
      }
      //Pasamos al campo el valor formateado
      rut = sRut.toUpperCase();
      console.log(rut);
  }

  validarDatos(usuario: Usuario){
    var mensajeTemp = '';
    this.restangular.all('usuario/existeRut/').get(this.nuevoUsuario.rut).subscribe(data => {
      if(data[0].cantidad > 0) {
        if(usuario.nombres == ''){
          mensajeTemp += 'El nombre del usuario es obligatorio ' + '</br>';
          
        }
        if(usuario.apellidos == ''){
          mensajeTemp += 'Los apellidos del usuario son obligatorios ' + '</br>';
          
        }
        if(usuario.rut == ''){
          mensajeTemp += 'El rut del usuario es obligatorio ' + '</br>';
          
        }
        if(usuario.contrasena == ''){
          mensajeTemp += 'La contraseña del usuario es obligatoria ' + '</br>';
          
        }
        if(usuario.email == ''){
          mensajeTemp += 'El email del usuario es obligatorio ' + '</br>';
          
        }
      } else {
        mensajeTemp += 'El usuario ingresado ya existe en la base de datos ' + '</br>';
      }

      if(mensajeTemp == ''){
        this.respuesta.codigo = true;
      } else {
        this.respuesta.codigo = false;
        this.respuesta.mensaje = mensajeTemp;
      }

    });
      
  }

  validaRut(rutCompleto) {

    
		if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
			return false;
		var tmp 	= rutCompleto.split('-');
		var digv	= tmp[1]; 
		var rut 	= tmp[0];
		if ( digv == 'K' ) digv = 'k' ;
		return (this.dv(rut) == digv );
	}
	dv(T: number){
		var M=0,S=1;
		for(;T;T=Math.floor(T/10))
			S=(S+T%10*(9-M++%6))%11;
		return S?S-1:'k';
	}

  existeRut(rut){
    this.restangular.all('usuario/existeRut/').get(this.nuevoUsuario.rut).subscribe(data => {
      
      this.cantidadUsuario = data[0].cantidad;
      /* console.log(this.cantidadUsuario); */
      return this.cantidadUsuario;
    });
    
  }

}
