<div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Revisión admisibilidad de casos</h4>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>
                  Usuario
                </th>
                <th>
                  Título
                </th>
                <th>
                  Municipio
                </th>
                <th>
                  Fecha
                </th>
                <th>
                  Estado
                </th>
                <th>
                  Acción
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let caso of listadoCasos">
                <td class="py-1">
                  <img src="assets/images/faces-clipart/pic-1.png" alt="image" />
                </td>
                <td>
                    {{ caso.attributes.titulo}}
                </td>
                <td>
                  {{ caso.attributes.institucion[0].nombre}}
              </td>
                <td>
                    {{ caso.attributes.created_at | date:short}}
                </td>
                <td>
                    {{ caso.attributes.estado}}
                </td>
                <td> 
                  <button style="margin-right: 5px; background-color: #E15957; border-color: #E15957;" type="button" class="btn btn-primary" ngbTooltip="Detalle caso" (click)="abrirDetalleCasoModal(caso)"><i class="fa fa-file-text-o"></i></button>
                  <button style="margin-right: 5px; background-color: #E15957; border-color: #E15957;" type="button" class="btn btn-primary" ngbTooltip="Admitir caso" (click)="aprobarCaso(caso)"><i class="fa fa-check"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</div>