<div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Detalle del caso</h4>
          <div class="form-group">
            <label for="exampleInputName1">Título</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.titulo" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Periodo o tiempo de desarrollo de la experiencia</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.tiempo_desarrollo_desde + ' - ' + caso.attributes.tiempo_desarrollo_hasta" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Clasificación de la experiencia</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.clasificacion_experiencia" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Temática de la experiencia de innovación pública</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.tematica" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Nombre de la Institución</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.nombre_institucion" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Categoría de Municipalidad según SUBDERE</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.categoria_muni_subdere" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Caracterización general de la comuna</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.caraterizacion_general_comuna" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Presupuesto Anual Municipal ($)</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.presupuesto_anual_municipal" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Otros</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.caracterizacion_otros" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Provincia</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.provincia" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Región</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.region" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Habitantes de la comuna</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.cantidad_habitantes_comuna" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Nombre del (la) encargado(a) de completar este formulario</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.encargado_formulario" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Teléfono</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.telefonos_contacto" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">E-mail</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.email" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Sitio Web</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.sitio_web" disabled>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Objetivos propuestos de la experiencia de innovación pública</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.objetivos_propuestos" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Describa el problema/necesidad/oportunidad que buscó abordar la experiencia</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.problema_necesidad" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Describa los resultados/impactos esperados del desarrollo de esta iniciativa</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.resultados_impactos" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Caracterización de la situación previa a la experiencia</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.situacion_previa" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Etapas o fases del desarrollo de la experiencia de innovación pública</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.fases_desarrollo" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Caracterización y organización del equipo de trabajo que llevó a cabo la experiencia</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.organizacion_equipo" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Describa cómo elaboraron y desarrollaron la experiencia para el problema detectado</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.elaboracion_desarrollo" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Participación ciudadana</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.participacion_ciudadana" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">¿Cómo se llevó a cabo el proceso de implementación de la experiencia?</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.implementacion" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Evaluación del impacto de la experiencia</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.impacto" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Aprendizajes del desarrollo de la experiencia</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.aprendizaje" disabled></textarea>
          </div>
      </div>
    </div>
  </div>