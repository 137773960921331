import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Restangular } from "ngx-restangular";
import { Login } from "src/app/api/modelos/Login";
import * as bcrypt from "bcryptjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RegistroUsuarioComponent } from "./registro-usuario/registro-usuario.component";
import { CrearUsuariosComponent } from "src/app/privado/usuarios/crear-usuarios/crear-usuarios.component";
import { ToastrService } from "ngx-toastr";
import { LoginInvitadoComponent } from "./login-invitado/login-invitado.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginDatos: Login = {
    rut: "",
    contrasena: "",
  };
  token: any;
  loginBase: Restangular;

  public showPassword: boolean = false;
  password = 'password';

  constructor(private route: Router, private restangular: Restangular,
    private modalService: NgbModal,
    private toastr: ToastrService) {
    this.loginBase = this.restangular.one("login");
  }

  ngOnInit(): void {}

  login() {
    const salt = bcrypt.genSaltSync(10);
    /*this.loginDatos.contrasena = bcrypt.hashSync(this.loginDatos.contrasena, salt);*/
    console.log(bcrypt.hashSync(this.loginDatos.contrasena, salt));
    this.loginBase.post("", this.loginDatos).subscribe(
      (data) => {
        console.log(data);
        this.token = data;
        localStorage.setItem("token", this.token.token);
        localStorage.setItem("usuario", JSON.stringify(this.token.usuario));
        localStorage.setItem(
          "institucion",
          JSON.stringify(this.token.institucion)
        );
        localStorage.setItem("cuestionario", "1");
        if (this.token != null) {
          //this.route.navigate(['']);
          this.route.navigate([""]);
        }
      },
      (err) => {
        this.toastr.error(err.statusText, 'Error');
        // console.log(err.statusText);
      }
    );
  }

  abrirRegistroUsuario(){
    console.log('Abriendo registro de usuario');
    const modalRef = this.modalService.open(RegistroUsuarioComponent, {
      size: 'xl',
      centered: true
    });

    //modalRef.componentInstance.casoInnovacion = caso;

    modalRef.result.then( result => {
      console.log('Se ha cerrado el modal');
      //this.cargarMunicipalidades();
    })
  }

  loginInvitado(){
    console.log('Abriendo login invitado');
    const modalRef = this.modalService.open(LoginInvitadoComponent, {
      size: 'xl',
      centered: true
    });

    //modalRef.componentInstance.casoInnovacion = caso;

    modalRef.result.then( result => {
      console.log('Se ha cerrado el modal');
      //this.cargarMunicipalidades();
    })
  }

  mostrarContrasena(){
    if (this.password === 'password') {
      this.password = 'text';
      this.showPassword = true;
    } else {
      this.password = 'password';
      this.showPassword = false;
    }
  }
}
