import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalle-caso',
  templateUrl: './detalle-caso.component.html',
  styleUrls: ['./detalle-caso.component.scss']
})
export class DetalleCasoComponent implements OnInit {

  
  @Input() public caso;
  
  constructor() { }

  ngOnInit(): void {
  }

}
