<!-- Menu -->
<aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
  <div class="app-brand demo"> <a href="index.html" class="app-brand-link"> <span
        class="app-brand-text demo menu-text fw-bold"><img src="../../../assets/imgs/logolab-transparente.png"
          width="160" height="auto" alt="" /></span> </a> <a href="javascript:void(0);"
      class="layout-menu-toggle menu-link text-large ms-auto"> <i
        class="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle"></i> <i
        class="ti ti-x d-block d-xl-none ti-sm align-middle"></i> </a> </div>
  <div class="menu-inner-shadow"></div>
  <ul class="menu-inner py-1">
    <!-- Page -->

    <li *ngFor="let menu of model" class="menu-item"> 
      <a *ngIf="menu.items !== undefined" href="javascript:void(0);" class="menu-link menu-toggle"> 
        <i class="menu-icon tf-icons ti ti-smart-home"></i>
        <div data-i18n="{{ menu.label }} "> {{ menu.label }}</div>
      </a>
      <a *ngIf="menu.items == undefined" class="menu-link" (click)="clickedMenu($event, menu)"
      routerLink="{{ menu.routerLink }}" routerLinkActive="active" [id]="menu.icon">
      <i class="menu-icon tf-icons ti ti-smart-home"></i>
      <div data-i18n="{{ menu.label }} "> {{ menu.label }}</div>
      </a>
      <ul class="menu-sub">
        <li *ngFor="let subMenuItem of menu.items" class="menu-item"> 
          <a [routerLink]="subMenuItem.routerLink" class="menu-link">
            <div data-i18n="{{ subMenuItem.label }}">{{ subMenuItem.label }}
            </div>
          </a> 
        </li>
      </ul>
    </li>
  </ul>
</aside>
<!-- / Menu -->
<!--<nav class="sidebar sidebar-offcanvas" style="background: #20364C;" id="sidebar">
  <ul class="nav">
    <li class="nav-item nav-profile">
      <a class="nav-link" (click)="clickedMenu($event, null)" routerLink="#">
        <div class="profile-image">
          <img class="img-xs rounded-circle" src="assets/images/faces/default.png" alt="profile image">
          <div class="dot-indicator bg-success"></div>
        </div>
        <div class="text-wrapper">
          <p class="profile-name"> {{ datosUsuario.nombres }}</p>
        </div>
      </a>
    </li>
    <li *ngFor="let menu of model" class="nav-item">
      <a *ngIf="menu.items == undefined" class="nav-link" (click)="clickedMenu($event, menu)"
      routerLink="{{ menu.routerLink }}" routerLinkActive="active" [id]="menu.icon">
        <i class="menu-icon typcn typcn-mail"></i>
        <span class="menu-title"> {{ menu.label }}</span>
        <i *ngIf="menu.items != undefined" class="menu-arrow"></i>
      </a>
      <a *ngIf="menu.items !== undefined" class="nav-link" data-toggle="collapse" href="#{{ menu.label }}" aria-expanded="false" aria-controls="ui-basic">
        <i class="menu-icon typcn typcn-mail"></i>
        <span class="menu-title"> {{ menu.label }}</span>
        <i *ngIf="menu.items != undefined" class="menu-arrow"></i>
      </a>
      <div class="collapse" id="{{ menu.label }}">
        <ul class="nav flex-column sub-menu">
          <li *ngFor="let item of menu.items" class="nav-item">
            <a class="nav-link" routerLink="{{ item.routerLink }}" routerLinkActive="active"
              ><span [innerHTML]="item.label"></span></a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</nav>-->