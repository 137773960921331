import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublicoRoutingModule } from './publico-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RegistroUsuarioComponent } from './login/registro-usuario/registro-usuario.component';
import { LoginInvitadoComponent } from './login/login-invitado/login-invitado.component';


@NgModule({
  declarations: [LoginComponent, RegistroUsuarioComponent, LoginInvitadoComponent],
  imports: [
    CommonModule,
    PublicoRoutingModule,
    FormsModule,
    NgbModule
  ]
})
export class PublicoModule { }
