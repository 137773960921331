import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';
import { AsignarPerfilesComponent } from './asignar-perfiles/asignar-perfiles.component';
import { CrearUsuariosComponent } from './crear-usuarios/crear-usuarios.component';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  listadoUsuarios: any[];

  perfilesDisponibles: any[];
  perfilesAsignados: any[];


  constructor(private restangular: Restangular,
              private toastr: ToastrService,
              private modalService: NgbModal, 
              public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.cargarUsuarios();
  }

  cargarUsuarios(){
    this.restangular.all('usuarios?filter[estado]=S').get('').subscribe(data => {
      this.listadoUsuarios = data.data;
    })
  }

  crearUsuario(){
    const modalRef = this.modalService.open(CrearUsuariosComponent, {
      size: '50%',
      centered: true
    });

    modalRef.result.then( result => {
      console.log('Se ha cerrado el modal');
      this.cargarUsuarios();
    })
    
  }

  editarUsuario(usuario){
    const modalRef = this.modalService.open(CrearUsuariosComponent, {
      size: '50%',
      centered: true
    });
    modalRef.componentInstance.usuario = usuario;

    modalRef.result.then( result => {
      console.log('Se ha cerrado el modal');
      this.cargarUsuarios();
    })

  }

  eliminarUsuario(usuario) {
    if(confirm("¿Desea eliminar al usuario?")) {
    console.log('eliminando');
      let jsonApiWrapper = {
        data: {
            type: 'usuarios',
            attributes: {
              estado: 'N'
          },
            id: ''
          }
      };
   jsonApiWrapper.data.id = usuario.id;
    this.restangular.all('usuarios/' + usuario.id).patch(jsonApiWrapper).subscribe(
      data => {
        this.toastr.success('El usuario se ha eliminado correctamente', 'Ok');
        //this.activeModal.close();
        this.cargarUsuarios();
      },
      error => {
        this.toastr.error('Hubo un error al eliminar al usuario', 'Error');
      }
  );
    }
  }

  asignarPerfiles(usuario){

    const modalRef = this.modalService.open(AsignarPerfilesComponent, {
      size: 'lg',
      centered: true
    });
    modalRef.componentInstance.usuario = usuario;

    
  }

}
