import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { ChartsModule } from "ng2-charts";
import { ChartistModule } from "ng-chartist";
import { NgCircleProgressModule } from "ng-circle-progress";
import { DragulaModule } from "ng2-dragula";
import { NgxGaugeModule } from "ngx-gauge";

import { AppComponent } from "./app.component";

import { AccordionsComponent } from "./ui-elements/accordions/accordions.component";
import { AlertsComponent } from "./ui-elements/alerts/alerts.component";
import { BadgesComponent } from "./ui-elements/badges/badges.component";
import { BreadcrumbsComponent } from "./ui-elements/breadcrumbs/breadcrumbs.component";
import { ButtonsComponent } from "./ui-elements/buttons/buttons.component";
import { CarouselComponent } from "./ui-elements/carousel/carousel.component";
import { DropdownComponent } from "./ui-elements/dropdown/dropdown.component";
import { PaginationComponent } from "./ui-elements/pagination/pagination.component";
import { ProgressbarComponent } from "./ui-elements/progressbar/progressbar.component";
import { TablesComponent } from "./ui-elements/tables/tables.component";
import { TabsComponent } from "./ui-elements/tabs/tabs.component";
import { TooltipsComponent } from "./ui-elements/tooltips/tooltips.component";
import { TypographyComponent } from "./ui-elements/typography/typography.component";

import { DashboardComponent } from "./screens/dashboard/dashboard.component";
import { Dashboard2Component } from "./screens/dashboard-2/dashboard-2.component";
import { WidgetsComponent } from "./screens/widgets/widgets.component";

import { FlagIconsComponent } from "./icons/flag-icons/flag-icons.component";
import { FontAwesomeComponent } from "./icons/font-awesome/font-awesome.component";
import { SimpleLineIconsComponent } from "./icons/simple-line-icons/simple-line-icons.component";
import { MdiComponent } from "./icons/mdi/mdi.component";

import { DragulaComponent } from "./advanced-elements/dragula/dragula.component";
import { LoaderComponent } from "./advanced-elements/loader/loader.component";

import { EmailComponent } from "./apps/email/email.component";
import { TodoListComponent } from "./apps/todo-list/todo-list.component";

import { ChartjsComponent } from "./charts/chartjs/chartjs.component";

import { InvoiceComponent } from "./sample-pages/invoice/invoice.component";
import { PricingTableComponent } from "./sample-pages/pricing-table/pricing-table.component";
import { LoginComponent } from "./sample-pages/login/login.component";
import { RegisterComponent } from "./sample-pages/register/register.component";
import { Page404Component } from "./sample-pages/page404/page404.component";
import { Page500Component } from "./sample-pages/page500/page500.component";
import { ChartistComponent } from "./charts/chartist/chartist.component";
import { LoginComponentComponent } from './login-component/login-component.component';
import { RestangularModule } from "ngx-restangular";
import { environment } from "src/environments/environment";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrivadoModule } from "./privado/privado.module";
import { PublicoModule } from "./publico/publico.module";
import { AppRoutes } from "./app-routing.module";
import { NgbAccordion, NgbAccordionModule, NgbCollapseModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from "@angular/common";
registerLocaleData(localeEs, 'es');

// Function for setting the default restangular configuration
export function RestangularConfigFactory(RestangularProvider) {
  RestangularProvider.setBaseUrl(environment.urlBase);
  /*RestangularProvider.setDefaultHeaders({'Authorization': 'Bearer UDXPx-Xko0w4BRKajozCVy20X11MRZs1'});*/
  RestangularProvider.setDefaultHeaders({
      'Content-Type': 'application/vnd.api+json',
      'Accept': 'application/vnd.api+json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
  });
}
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ButtonsComponent,
    TablesComponent,
    TypographyComponent,
    AlertsComponent,
    AccordionsComponent,
    BadgesComponent,
    ProgressbarComponent,
    BreadcrumbsComponent,
    PaginationComponent,
    DropdownComponent,
    TooltipsComponent,
    CarouselComponent,
    TabsComponent,
    Dashboard2Component,
    WidgetsComponent,
    DragulaComponent,
    LoaderComponent,
    EmailComponent,
    TodoListComponent,
    ChartjsComponent,
    FontAwesomeComponent,
    FlagIconsComponent,
    SimpleLineIconsComponent,
    MdiComponent,
    InvoiceComponent,
    PricingTableComponent,
    LoginComponent,
    RegisterComponent,
    Page404Component,
    Page500Component,
    ChartistComponent,
    LoginComponentComponent
  ],
  imports: [
    PrivadoModule,
    PublicoModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    // Importing RestangularModule and making default configs for restanglar
    RestangularModule.forRoot(RestangularConfigFactory),
    RouterModule,
    ChartsModule,
    ChartistModule,
    NgxGaugeModule,
    DragulaModule.forRoot(),
    NgCircleProgressModule.forRoot({
      radius: 60,
      outerStrokeWidth: 10,
      innerStrokeWidth: 5,
      showBackground: false,
      startFromZero: false
    }),
    AppRoutes,
    NgbModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    NgbAccordionModule
  ],
  providers: [{provide: LOCALE_ID, useValue: 'es' }],
  bootstrap: [AppComponent],
  schemas: [
      CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {}


