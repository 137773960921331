import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { EstadisticasService } from "src/app/services/estadisticas.service";

@Injectable()  
export class TotalInstitucionesRegistradasResolve implements Resolve<any[]> {  
  constructor(private estadisticaService: EstadisticasService) {}  
  
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {  
    return this.estadisticaService.obtenerTotalInstitucionesRegistrados();
  }  
} 