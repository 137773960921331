<div toastContainer></div>
<div class="container-xxl flex-grow-1 container-p-y">
  <div class="row">
    <!-- <div class="col-md-12 d-flex align-items-stretch grid-margin"> -->
    <!-- <div class="row flex-grow"> -->
    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-10">
              <h4 class="card-title" style="font-size: xx-large;">¿Qué es la innovación pública?</h4>
            </div>
            <div class="col-2">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-primary dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Acciones
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" (click)="descargarEncuesta()">Descargar encuesta</a></li>
                  <li><a class="dropdown-item" (click)="borrarEncuesta()">Borrar respuestas</a></li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li><a class="dropdown-item" (click)="verPuntajes()">Ver puntajes</a></li>
                  <li><a class="dropdown-item" (click)="verResultados()">Ver resultados</a></li>
                </ul>
              </div>
            </div>
          </div>
          
          <p class="card-description">Procesos de transformacion en instituciones publicas, orientado a la creacion y/o
            modificacion de servicios que agreguen valor a la relacion entre gestion publica y ciudadania. </p>
        </div>
      </div>
    </div>
    <br>
    </div>
    <br>
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-header" style="font-size: x-large;">
            Encuesta institucional de {{ nombreInstitucion }}
          </div>
          
        </div>
      </div>
    </div>
   

  <br>

    <div class="row">
      <!-- <div class="col-md-12 d-flex align-items-stretch grid-margin"> -->
      <!-- <div class="row flex-grow"> -->
      <div class="col-6" *ngFor="let pregunta of preguntasLista">
        <div *ngIf="pregunta.attributes.dependencia.length > 0 || pregunta.attributes.dependencia == 1">
        <div class="card mt-1">
          <div class="card-body ">
            <h4 class="card-title">Pregunta {{ pregunta.attributes.nro_pregunta }}</h4>
            <p class="card-description">{{ pregunta.attributes.nombre }}</p>
            <form class="forms-sample">
              <div class="form-group">
                <div *ngIf="pregunta.attributes.tipo == 'CERRADA'">
                  <div class="form-check" *ngFor="let item of pregunta.attributes.grupo_respuestas">
                    <input class="form-check-input" type="radio" name={{item.id}} id={{item.id}}
                      (change)="onChange(pregunta)" [(ngModel)]="respuestaValores[pregunta.attributes.orden].respuesta"
                      value={{item.id}}>
                    <label class="form-check-label" for={{item.id}}>
                      {{item.texto}}
                    </label>
                    <div class="form-group">
                      <input
                        *ngIf="item.tipo == 'CHECKBOX-COMPLEMENTARIA' && respuestaValores[pregunta.attributes.orden].respuesta == item.id"
                        type="text" name="pregunta.attributes.orden"
                        placeholder="{{ item.texto_respuesta_complementaria}}"
                        [(ngModel)]="respuestaValores[pregunta.attributes.orden].respuesta_complementaria"
                        class="form-control form-control-lg">
                    </div>
                  </div>
                </div>
                <div *ngIf="pregunta.attributes.tipo == 'ABIERTA'">
                  <div class="form-group">
                    <textarea class="form-control" id="exampleTextarea1" rows="2" name="pregunta.attributes.orden"
                      [(ngModel)]="respuestaValores[pregunta.attributes.orden].respuesta"></textarea>
                  </div>
                </div>
                <div *ngIf="pregunta.attributes.tipo == 'ABIERTA-MULTIPLE'">
                  <div class="form-group" style="margin-left: 1.5%;">
                    <div *ngFor="let item of pregunta.attributes.grupo_respuestas">
                      <div *ngIf="item.tipo=='TEXTO'">
                        <p> {{item.texto}}</p><input id={{item.id}} type="text" name={{item.orden}} class="form-control"
                          [(ngModel)]="respuestaValoresMultiples[item.orden].respuesta_valor" />
                      </div>
                      <div *ngIf="item.tipo=='CHECKBOX'">
                        <div class="form-group">
                          <input class="form-check-input" type="checkbox" name={{item.id}} id={{item.id}}
                            (change)="onChange(pregunta)"
                            [(ngModel)]="respuestaValoresMultiples[item.orden].respuesta_valor" value={{item.id}}>
                          <label class="form-check-label" for={{item.id}}>
                            {{item.texto}}
                          </label>
                        </div>

                      </div>
                      <div *ngIf="item.tipo=='CHECKBOX-COMPLEMENTARIA'">
                        <div class="form-group">
                          <input class="form-check-input" type="checkbox" name={{item.id}} id={{item.id}}
                            [(ngModel)]="respuestaValoresMultiples[item.orden].respuesta_valor" value={{item.id}}>
                          <label class="form-check-label" for={{item.id}}>
                            {{item.texto}}
                          </label>
                          <input *ngIf="respuestaValoresMultiples[item.orden].respuesta_valor == '1'" type="text"
                            name="{{item.id}}" placeholder="{{ item.texto_respuesta_complementaria}}"
                            [(ngModel)]="respuestaValoresMultiples[item.orden].respuesta_complementaria"
                            class="form-control form-control-lg">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <form *ngIf="respuestaValores[pregunta.attributes.orden].mostrar == 'SI'" class="forms-sample">
                  <button type="submit" class="btn btn-primary btn-fw"
                    style="background-color: #E15957; border-color: #E15957;"
                    (click)="guardarRespuesta(pregunta)">Guardar</button>
                </form>
              </div>
            </form>
          </div>
        </div>
      </div>
      </div>
    </div>


  <!-- Resultados para la dimensión Gestión Pública Local -->
<!--   <div class="card col-sm-12">
    <div class="card-header" style="font-size: xx-large;">
      Resultados Dimensión Gestión Pública Local
    </div>
  </div>
  <div class="row" *ngIf="true">
    <div class="card col-sm-6">
      <div class="col-sm-12">
        <div class="card-header" style="font-size: x-large;">
          Variable Innovación Abierta
        </div>
        <div class="card-body">
          <table class="table">
            <caption>Variable Innovación Abierta</caption>
            <thead>
              <tr class="bg-primary">
                <th scope="col"></th>
                <th scope="col">Puntaje</th>
                <th scope="col">Indicador</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Puntaje Innovación Abierta</th>
                <td> {{ puntajeVariableGestionPublica[0].puntajeTotal }}</td>
                <td> {{ puntajeVariableGestionPublica[0].puntajeTotal / puntajeVariableGestionPublica[0].puntaje_maximo
                  | percent}} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
    <div class="card col-sm-6">
      <div class="row">
        <div class="col-sm-12">
          <div class="card-header" style="font-size: x-large;">
            Variable Descentralización
          </div>
          <div class="card-body">
            <table class="table">
              <caption>Variable Descentralización</caption>
              <thead>
                <tr class="bg-primary">
                  <th scope="col"></th>
                  <th scope="col">Puntaje</th>
                  <th scope="col">Indicador</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Puntaje Innovación Abierta</th>
                  <td> {{ puntajeVariableGestionPublica[1].puntajeTotal }} </td>
                  <td> {{ puntajeVariableGestionPublica[1].puntajeTotal /
                    puntajeVariableGestionPublica[1].puntaje_maximo | percent}} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="row" *ngIf="true">
    <div class="card col-sm-12">
      <div class="col-sm-12">
        <div class="card-header" style="font-size: x-large;">
          Ejes de medición
        </div>
        <div class="card-body">
          <table class="table">
            <caption>Ejes de medición</caption>
            <thead>
              <tr class="bg-primary">
                <th scope="col"></th>
                <th scope="col">Puntaje</th>
                <th scope="col">Indicador</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let eje of sumaPuntajeEjeMedicion">
                <th *ngIf="eje.dimension == 'Gestión Pública Local'" scope="row"> {{ eje.nombre }}</th>
                <td *ngIf="eje.dimension == 'Gestión Pública Local'"> {{ eje.suma_puntaje }}</td>
                <td *ngIf="eje.dimension == 'Gestión Pública Local'"> {{ eje.suma_puntaje / eje.puntaje_maximo |
                  percent}} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>


  <div class="row" *ngIf="true">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header" style="font-size: xx-large;">
          Resultado según dimensión
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="card" style="margin: 10px;">
              <div class="card-body">
                <!-- <h5 class="card-title">Dimensión</h5> -->
                <!-- <div *ngFor="let item of puntajeDimension">
                  <p class="card-text">Se obtuvieron <span
                      style="font-size: x-large;color: #FF0066;font-weight: bold;">{{ item.puntajeTotal }}</span> puntos
                    de
                    20 en la dimensión {{item.nombre}}</p>

                  <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <div class="row"> -->
                          <!--div class="col-md-8 d-flex flex-column">
                          <canvas class="my-4 my-md-0 mt-md-auto" height="200" baseChart [chartType]="'bar'"
                            [datasets]="realtimeStatisticsData[indexOfelement]" [labels]="realtimeStatisticsLabels"
                            [options]="realtimeStatisticsOptions" [legend]="false" [colors]="realtimeStatisticsColors">
                          </canvas>
                        </div-->
                          <!-- <div class="col-md-12">
                            <div *ngFor="let promedio of sumaPuntajeEjeMedicion">
                              <div class="wrapper">
                                <div class="d-flex justify-content-between py-2 border-bottom"
                                  *ngIf="promedio.dimension == item.nombre">
                                  <p class="mb-0"><span style="font-size: large; font-weight: bold;">
                                      {{promedio.suma_puntaje}}</span> puntos de {{ promedio.puntaje_maximo }} en temas
                                    de <span style="font-size: large; font-weight: bold;"> {{ promedio.nombre}} </span>
                                  </p>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->


             <!--  </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card" style="margin: 10px;">
              <div class="card-body d-flex flex-column">
                <div class="wrapper">
                  <h4 class="card-title mb-0">Equilibrio entre las dimensiones de la innovación pública local</h4> -->
                  <!-- <div class="mb-4" id="net-profit-legend">
                  <div class="chartjs-legend">
                    <ul>
                      <li><span style="background-color:rgba(88, 208, 222,0.8)"></span>Sales</li>
                      <li><span style="background-color:rgba(150, 77, 247,1)"></span>Orders</li>
                    </ul>
                  </div>
                </div> -->
                <!-- </div>
                <canvas class="my-auto mx-auto" height="100" baseChart [chartType]="'radar'" [datasets]="netProfitData"
                  [labels]="netProfitLabels" [options]="netProfitOptions" [legend]="false" [colors]="netProfitColors">
                </canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
  <!-- <div class="row" *ngIf="true">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header" style="font-size: xx-large;">
          Resultados según variable asociada a una dimensión
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="card" style="margin: 10px;">
              <div class="card-header" style="color: #660066;font-weight: bold;">
                Gestión pública local
              </div>
              <div class="card-body">
                <!-- <h5 class="card-title">Dimensión</h5> -->
                <!-- <p class="card-text" *ngFor="let item of puntajeVariableGestionPublica">Se obtuvieron <span
                    style="font-size: x-large;color: #FF0066;font-weight: bold;">{{ item.puntajeTotal }}</span> puntos
                  de
                  {{ item.puntaje_maximo }} en la variable {{item.nombre}}</p>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="row">
          <div class="col-sm-12">
            <div class="card" style="margin: 10px;">
              <div class="card-header" style="color: #C45911;font-weight: bold;">
                Gobernanza democrática
              </div>
              <div class="card-body"> -->
                <!-- <h5 class="card-title">Dimensión</h5> -->
               <!--  <p class="card-text" *ngFor="let item of puntajeVariableGobernanzaDemocratica">Se obtuvieron <span
                    style="font-size: x-large;color: #FF0066;font-weight: bold;">{{ item.puntajeTotal }}</span> puntos
                  de
                  {{ item.puntaje_maximo }} en la variable {{item.nombre}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="card" style="margin: 10px;">
              <div class="card-header" style="color: #538135;font-weight: bold;">
                Desarrollo territorial
              </div>
              <div class="card-body"> -->
                <!-- <h5 class="card-title">Dimensión</h5> -->
               <!--  <p class="card-text" *ngFor="let item of puntajeVariableDesarrolloTerritorial">Se obtuvieron <span
                    style="font-size: x-large;color: #FF0066;font-weight: bold;">{{ item.puntajeTotal }}</span> puntos
                  de
                  {{ item.puntaje_maximo }} en la variable {{item.nombre}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
<!--   </div>  -->
<!-- </div>  -->
<!--   <div class="col-md-4 grid-margin stretch-card">
    <div class="card">
      <div class="card-body d-flex flex-column">
        <div class="wrapper">
          <h4 class="card-title mb-0">Net Profit Margin</h4>
          <p>Started collecting data from February 2019</p>
          <div class="mb-4" id="net-profit-legend">
            <div class="chartjs-legend">
              <ul>
                <li><span style="background-color:rgba(88, 208, 222,0.8)"></span>Sales</li>
                <li><span style="background-color:rgba(150, 77, 247,1)"></span>Orders</li>
              </ul>
            </div>
          </div>
        </div>
        <canvas class="my-auto mx-auto" height="250" baseChart [chartType]="'radar'" [datasets]="netProfitData"
          [labels]="netProfitLabels" [options]="netProfitOptions" [legend]="false" [colors]="netProfitColors">
        </canvas>
      </div>
    </div>
  </div>
</div> -->
</div>