<!-- Navbar -->

<nav class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
  id="layout-navbar">
  <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none"> <a
      class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)"> <i class="ti ti-menu-2 ti-sm"></i> </a> </div>
  <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
    <ul class="navbar-nav flex-row align-items-center ms-auto">
      <!-- User -->
      <li class="nav-item navbar-dropdown dropdown-user dropdown"> <a class="nav-link hide-arrow"
          href="javascript:void(0);" data-bs-toggle="dropdown">
          <div class="avatar avatar-online"> <img src="../../../assets/img/avatars/1.png" alt
              class="h-auto rounded-circle" /> </div>
        </a>
        <ul class="dropdown-menu dropdown-menu-end">
          <li> <a class="dropdown-item" href="#">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar avatar-online"> <img src="../../../assets/img/avatars/1.png" alt
                      class="h-auto rounded-circle" /> </div>
                </div>
                <div class="flex-grow-1"> <span class="fw-medium d-block">Juan Pérez</span> <small
                    class="text-muted">Admin</small> </div>
              </div>
            </a> </li>
          <li>
            <div class="dropdown-divider"></div>
          </li>
          <li> <a class="dropdown-item" [routerLink]="'/perfil-usuario/'+usuarioStorage"> <i class="ti ti-user-check me-2 ti-sm"></i> <span
                class="align-middle">Mi perfil</span> </a> </li>
          <li>
            <div class="dropdown-divider"></div>
          </li>
          <li> <a class="dropdown-item" (click)="cerrarSesion()"> <i class="ti ti-logout me-2 ti-sm"></i> <span
                class="align-middle">Cerrar sesión</span> </a> </li>
        </ul>
      </li>
      <!--/ User -->
    </ul>
  </div>
</nav>

<!-- / Navbar --> 

<!--<nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
  <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center" style="background: #20364C;">
    <a class="navbar-brand brand-logo" routerLink="/inicio">
      <img src="assets/images/logo-lab-blanco.png" alt="logo" /> </a>
    <a class="navbar-brand brand-logo-mini" routerLink="/inicio">
      <img src="assets/images/logo-lab-blanco.png" alt="logo" /> </a>
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-center">
    <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize"
      (click)="toggleIconOnlySidebar()">
      <span class="mdi mdi-menu"></span>
    </button>
    <ul class="navbar-nav">
    </ul>
    <form class="ml-auto search-form d-none d-md-block" action="#">
      <div class="form-group">
        <input type="search" class="form-control" placeholder="Buscar">
      </div>
    </form>
    <ul class="navbar-nav navbar-nav-right">
      
      <li class="nav-item dropdown d-none d-xl-inline-flex" ngbDropdown>
        <a class="nav-link dropdown-toggle" id="UserDropdown" ngbDropdownToggle>
          <img class="img-xs rounded-circle" src="assets/images/faces/default.png" alt="Profile image">
        </a>
        <div class="dropdown-menu dropdown-menu-right navbar-dropdown" ngbDropdownMenu aria-labelledby="UserDropdown">
          <div class="dropdown-header text-center">
            <img class="img-md rounded-circle" src="assets/images/faces/default.png" alt="Profile image">
            <p class="mb-1 mt-3 font-weight-semibold">{{ nombresUsuario }} {{ apellidosUsuario }}</p>
            <p class="font-weight-light text-muted mb-0">{{ emailUsuario }}</p>
          </div>
          <a class="dropdown-item" (click)="editarUsuario(usuario)">Mi Perfil<i class="dropdown-item-icon ti-dashboard"></i></a>
          <a class="dropdown-item" (click)="cerrarSesion()">Cerrar sesión<i class="dropdown-item-icon ti-power-off"></i></a>
        </div>
      </li>
    </ul>
    <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
      (click)="toggleRightSidebar()">
      <span class="mdi mdi-menu"></span>
    </button>
  </div>
</nav>-->
