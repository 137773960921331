<div class="row">
  <div class="col-lg-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Basic carousel</h4>
        <ngb-carousel>
          <ng-template ngbSlide>
            <img src="assets/images/carousel/banner_12.jpg" alt="Random first slide">
          </ng-template>
          <ng-template ngbSlide>
            <img src="assets/images/carousel/banner_2.jpg" alt="Random second slide">
          </ng-template>
          <ng-template ngbSlide>
            <img src="assets/images/carousel/banner_1.jpg" alt="Random third slide">
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </div>
  <div class="col-lg-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Carousel with captions</h4>
        <ngb-carousel>
          <ng-template ngbSlide>
            <img src="assets/images/carousel/banner_3.jpg" alt="Random first slide">
            <div class="carousel-caption">
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </div>
          </ng-template>
          <ng-template ngbSlide>
            <img src="assets/images/carousel/banner_4.jpg" alt="Random second slide">
            <div class="carousel-caption">
              <h3>Second slide label</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
          </ng-template>
          <ng-template ngbSlide>
            <img src="assets/images/carousel/banner_5.jpg" alt="Random third slide">
            <div class="carousel-caption">
              <h3>Third slide label</h3>
              <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </div>
</div>
<div class="row grid-margin">
  <div class="col-lg-6">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Loop</h4>
        <ngb-carousel>
          <ng-template ngbSlide>
            <img src="assets/images/carousel/banner_6.jpg" alt="Random first slide">
          </ng-template>
          <ng-template ngbSlide>
            <img src="assets/images/carousel/banner_7.jpg" alt="Random second slide">
          </ng-template>
          <ng-template ngbSlide>
            <img src="assets/images/carousel/banner_8.jpg" alt="Random third slide">
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">non-Loop</h4>
        <ngb-carousel wrap>
          <ng-template ngbSlide>
            <img src="assets/images/carousel/banner_9.jpg" alt="Random first slide">
          </ng-template>
          <ng-template ngbSlide>
            <img src="assets/images/carousel/banner_10.jpg" alt="Random second slide">
          </ng-template>
          <ng-template ngbSlide>
            <img src="assets/images/carousel/banner_11.jpg" alt="Random third slide">
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </div>
</div>
