import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { PreguntasServiceService } from "src/app/services/preguntas-service.service";

@Injectable()  
export class EncuestaIniciativaRespuestasMultiplesResolve implements Resolve<any[]> {  

  constructor(private postService: PreguntasServiceService,
              private route: ActivatedRoute) {
  }  
  
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {  
      console.log('En el resolve');
    return this.postService.getRespuestasAbiertasMultiplesIniciativas(route.params['id']);  
  }  
} 