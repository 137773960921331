<div class="row">
  <div class="col-lg-12 grid-margin">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Basic dropdown</h4>
        <p class="card-description">
          Wrap the dropdown’s toggle (your button or link) and the dropdown menu within <code>.dropdown</code>
        </p>
        <div class="template-demo">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-primary" type="button" id="dropdownMenuButton1" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton1">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-secondary" type="button" id="dropdownMenuButton2" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton2">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-danger" type="button" id="dropdownMenuButton3" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton3">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-warning" type="button" id="dropdownMenuButton4" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton4">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-success" type="button" id="dropdownMenuButton5" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton5">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-info" type="button" id="dropdownMenuButton6" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton6">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <h4 class="card-title">Dropdown outline</h4>
        <p class="card-description">
          Add class <code>.btn-outline-{{'{'}}color{{'}'}}</code> to the button inside <code>.dropdown</code>
        </p>
        <div class="template-demo">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-primary" type="button" id="dropdownMenuOutlineButton1" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuOutlineButton1">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-secondary" type="button" id="dropdownMenuOutlineButton2" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuOutlineButton2">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-danger" type="button" id="dropdownMenuOutlineButton3" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuOutlineButton3">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-warning" type="button" id="dropdownMenuOutlineButton4" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuOutlineButton4">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-success" type="button" id="dropdownMenuOutlineButton5" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuOutlineButton5">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-info" type="button" id="dropdownMenuOutlineButton6" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuOutlineButton6">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Split button dropdowns</h4>
        <p class="card-description">
          Wrap two buttons in <code>.btn-group</code> and add <code>.dropdown-toggle-split</code> to the toggling button
        </p>
        <div class="template-demo">
          <div class="btn-group">
            <button type="button" class="btn btn-primary">Dropdown</button>
            <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
              <button type="button" class="btn btn-primary dropdown-toggle-split" id="dropdownMenuSplitButton1" ngbDropdownToggle>
                <span class="sr-only">Toggle Dropdown</span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMenuSplitButton1">
                <h6 class="dropdown-header">Settings</h6>
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Separated link</a>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button type="button" class="btn btn-danger">Dropdown</button>
            <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
              <button type="button" class="btn btn-danger dropdown-toggle-split" id="dropdownMenuSplitButton2" ngbDropdownToggle>
                <span class="sr-only">Toggle Dropdown</span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMenuSplitButton2">
                <h6 class="dropdown-header">Settings</h6>
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Separated link</a>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button type="button" class="btn btn-success">Dropdown</button>
            <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
              <button type="button" class="btn btn-success dropdown-toggle-split" id="dropdownMenuSplitButton3" ngbDropdownToggle>
                <span class="sr-only">Toggle Dropdown</span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMenuSplitButton3">
                <h6 class="dropdown-header">Settings</h6>
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Separated link</a>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button type="button" class="btn btn-secondary">Dropdown</button>
            <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
              <button type="button" class="btn btn-secondary dropdown-toggle-split" id="dropdownMenuSplitButton4" ngbDropdownToggle>
                <span class="sr-only">Toggle Dropdown</span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMenuSplitButton4">
                <h6 class="dropdown-header">Settings</h6>
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Separated link</a>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button type="button" class="btn btn-info">Dropdown</button>
            <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
              <button type="button" class="btn btn-info dropdown-toggle-split" id="dropdownMenuSplitButton5" ngbDropdownToggle>
                <span class="sr-only">Toggle Dropdown</span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMenuSplitButton5">
                <h6 class="dropdown-header">Settings</h6>
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Separated link</a>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button type="button" class="btn btn-warning">Dropdown</button>
            <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
              <button type="button" class="btn btn-warning dropdown-toggle-split" id="dropdownMenuSplitButton6" ngbDropdownToggle>
                <span class="sr-only">Toggle Dropdown</span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMenuSplitButton6">
                <h6 class="dropdown-header">Settings</h6>
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Separated link</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Icon dropdowns</h4>
        <p class="card-description">
          Replace button with icon buttons
        </p>
        <div class="row">
          <div class="col-12">
            <div class="template-demo">
              <div ngbDropdown class="d-inline-block">
                <button class="btn btn-warning icon-btn" type="button" id="dropdownMenuIconButton1" ngbDropdownToggle>
                  <i class="mdi mdi-earth"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownMenuIconButton1">
                  <h6 class="dropdown-header">Settings</h6>
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Separated link</a>
                </div>
              </div>
              <div ngbDropdown class="d-inline-block">
                <button class="btn btn-danger icon-btn" type="button" id="dropdownMenuIconButton2" ngbDropdownToggle>
                  <i class="mdi mdi-trophy-outline"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownMenuIconButton2">
                  <h6 class="dropdown-header">Settings</h6>
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Separated link</a>
                </div>
              </div>
              <div ngbDropdown class="d-inline-block">
                <button type="button" class="btn btn-light  icon-btn-split" id="dropdownMenuIconButton3" ngbDropdownToggle>
                  <i class="mdi mdi-email"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownMenuIconButton3">
                  <h6 class="dropdown-header">Settings</h6>
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Separated link</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="template-demo">
              <div ngbDropdown class="d-inline-block">
                <button type="button" class="btn btn-success icon-btn" id="dropdownMenuIconButton4" ngbDropdownToggle>
                  <i class="mdi mdi-heart"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownMenuIconButton4">
                  <h6 class="dropdown-header">Settings</h6>
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Separated link</a>
                </div>
              </div>
              <div ngbDropdown class="d-inline-block">
                <button type="button" class="btn btn-secondary icon-btn" id="dropdownMenuIconButton5" ngbDropdownToggle>
                  <i class="mdi mdi-logout"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownMenuIconButton5">
                  <h6 class="dropdown-header">Settings</h6>
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Separated link</a>
                </div>
              </div>
              <div ngbDropdown class="d-inline-block">
                <button class="btn btn-primary icon-btn" type="button" id="dropdownMenuIconButton6" ngbDropdownToggle>
                  <i class="mdi mdi-security"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownMenuIconButton6">
                  <h6 class="dropdown-header">Settings</h6>
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Separated link</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="template-demo">
              <div ngbDropdown class="d-inline-block">
                <button type="button" class="btn btn-dark icon-btn" id="dropdownMenuIconButton7" ngbDropdownToggle>
                  <i class="mdi mdi-account"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownMenuIconButton7">
                  <h6 class="dropdown-header">Settings</h6>
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Separated link</a>
                </div>
              </div>
              <div ngbDropdown class="d-inline-block">
                <button type="button" class="btn btn-info icon-btn" id="dropdownMenuIconButton8" ngbDropdownToggle>
                  <i class="mdi mdi-bell"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownMenuIconButton8">
                  <h6 class="dropdown-header">Settings</h6>
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Separated link</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Dropup variation</h4>
        <p class="card-description">
          Add class <code>.dropup</code>
        </p>
        <div class="template-demo">
          <div ngbDropdown placement="top-right" class="d-inline-block">
            <button class="btn btn-primary" type="button" id="dropdownMenuButton1" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton1">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <div ngbDropdown placement="top-right" class="d-inline-block">
            <button class="btn btn-secondary" type="button" id="dropdownMenuButton2" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton2">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <div ngbDropdown placement="top-right" class="d-inline-block">
            <button class="btn btn-danger" type="button" id="dropdownMenuButton3" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton3">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <div ngbDropdown placement="top-right" class="d-inline-block">
            <button class="btn btn-warning" type="button" id="dropdownMenuButton4" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton4">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <div ngbDropdown placement="top-right" class="d-inline-block">
            <button class="btn btn-success" type="button" id="dropdownMenuButton5" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton5">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <div ngbDropdown placement="top-right" class="d-inline-block">
            <button class="btn btn-info" type="button" id="dropdownMenuButton6" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton6">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Dropdown sizes</h4>
        <p class="card-description">
          Add class <code>.btn-{{'{'}}size{{'}'}}</code> to dropdown buttons
        </p>
        <div class="template-demo mt-5">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-danger btn-lg" type="button" id="dropdownMenuSizeButton1" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuSizeButton1">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-danger" type="button" id="dropdownMenuSizeButton2" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuSizeButton2">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-danger btn-sm" type="button" id="dropdownMenuSizeButton3" ngbDropdownToggle>
              Dropdown
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuSizeButton3">
              <h6 class="dropdown-header">Settings</h6>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>