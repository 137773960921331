<div class="row">
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Bootstrap alert</h4>
        <p class="car-description">Add class <code>.alert .alert-*</code></p>
        <div class="alert alert-success" role="alert">
          You successfully read this important alert message.
        </div>
        <div class="alert alert-info" role="alert">
          This alert needs your attention, but it's not that important.
        </div>
        <div class="alert alert-warning" role="alert">
          Better check yourself, you're not looking too good.
        </div>
        <div class="alert alert-danger" role="alert">
          Change a few things up and try submitting again.
        </div>
        <div class="alert alert-primary" role="alert">
            This is a primary alert
        </div>
        <div class="alert alert-secondary" role="alert">
          This alert is not so important.
          </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Custom alerts</h4>
        <p class="card-description">
          Custom HTML inside alert
        </p>
        <div class="card card-inverse-secondary mb-5">
          <div class="card-body">
            <p class="mb-4">
              Well done! You successfully read this important alert message.
            </p>
            <button class="btn btn-secondary">Ok</button>
            <button class="btn btn-light">Cancel</button>
          </div>
        </div>
        <div class="card card-inverse-info">
          <div class="card-body">
            <p class="mb-4">
              Heads up! This alert needs your attention, but it's not super important.
            </p>
            <button class="btn btn-info">Ok</button>
            <button class="btn btn-light">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 grid-margin">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Bootstrap alerts in fill colors</h4>
        <p class="card-description">
          Add class <code>.alert-fill-*</code> with <code>.alert</code>
        </p>
        <div class="alert alert-fill-primary" role="alert">
          <i class="mdi mdi-alert-circle"></i>
          There! This is a primary alert.
        </div>
        <div class="alert alert-fill-success" role="alert">
          <i class="mdi mdi-alert-circle"></i>
          Well done! You successfully read this important alert message.
        </div>
        <div class="alert alert-fill-info" role="alert">
          <i class="mdi mdi-alert-circle"></i>
          Heads up! This alert needs your attention, but it's not super important.
        </div>
        <div class="alert alert-fill-warning" role="alert">
          <i class="mdi mdi-alert-circle"></i>
          Warning! Better check yourself, you're not looking too good.
        </div>
        <div class="alert alert-fill-danger" role="alert">
          <i class="mdi mdi-alert-circle"></i>
          Oh snap! Change a few things up and try submitting again.
        </div>
      </div>
    </div>
  </div>
</div>