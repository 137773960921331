    <div class="card col-12 grid-margin stretch-card">
        <div class="row g-0" >
          <div class="col-md-12">
            <div class="card-body">
              <div class="row">
                <h1 class="card-title" style="font-size: xx-large;"> {{ casoInnovacion.attributes.titulo }}</h1>

              </div>
              <div class="row">
                <div class="col-md-3 offset-md-9">
                  <button *ngIf="rolesUsuario[0].attributes.rol_id =='1' || rolesUsuario[0].attributes.rol_id =='2'" class="btn btn-primary btn-fw" (click)="verPuntajeCasoModal()">Ver puntaje del caso</button>
                </div>
              </div>
            </div>
          </div>
          <!--div class="col-md-3">
            <img src="assets/images/dashboard/img_1.jpg" alt="..."> 
          </!--div-->
        </div>
    </div>

    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Detalle del caso</h4>
            <div class="form-group">
              <label for="exampleInputName1">Título</label>
              <input type="text" class="form-control" id="exampleInputName1" [value]="casoInnovacion.attributes.titulo" disabled>
            </div>
            <div class="form-group">
              <label for="exampleInputName1">Periodo o tiempo de desarrollo de la experiencia</label>
              <input type="text" class="form-control" id="exampleInputName1" [value]="casoInnovacion.attributes.tiempo_desarrollo_desde + ' - ' + casoInnovacion.attributes.tiempo_desarrollo_hasta" disabled>
            </div>
            <div class="form-group">
              <label for="exampleInputName1">Clasificación de la experiencia</label>
              <input type="text" class="form-control" id="exampleInputName1" [value]="casoInnovacion.attributes.clasificacion_experiencia" disabled>
            </div>
            <div class="form-group">
              <label for="exampleInputName1">Temática de la experiencia de innovación pública</label>
              <input type="text" class="form-control" id="exampleInputName1" [value]="casoInnovacion.attributes.tematica" disabled>
            </div>
            <div class="form-group">
              <label for="exampleInputName1">Nombre de la Institución</label>
              <input type="text" class="form-control" id="exampleInputName1" [value]="casoInnovacion.attributes.nombre_institucion" disabled>
            </div>
            <div class="form-group">
              <label for="exampleInputName1">Categoría de Municipalidad según SUBDERE</label>
              <input type="text" class="form-control" id="exampleInputName1" [value]="casoInnovacion.attributes.categoria_muni_subdere" disabled>
            </div>
            <div class="form-group">
              <label for="exampleInputName1">Caracterización general de la comuna</label>
              <input type="text" class="form-control" id="exampleInputName1" [value]="casoInnovacion.attributes.caraterizacion_general_comuna" disabled>
            </div>
            <div class="form-group">
              <label for="exampleInputName1">Presupuesto Anual Municipal ($)</label>
              <input type="text" class="form-control" id="exampleInputName1" [value]="casoInnovacion.attributes.presupuesto_anual_municipal" disabled>
            </div>
            <div class="form-group">
              <label for="exampleInputName1">Otros</label>
              <input type="text" class="form-control" id="exampleInputName1" [value]="casoInnovacion.attributes.caracterizacion_otros" disabled>
            </div>
            <div class="form-group">
              <label for="exampleInputName1">Provincia</label>
              <input type="text" class="form-control" id="exampleInputName1" [value]="casoInnovacion.attributes.provincia" disabled>
            </div>
            <div class="form-group">
              <label for="exampleInputName1">Región</label>
              <input type="text" class="form-control" id="exampleInputName1" [value]="casoInnovacion.attributes.region" disabled>
            </div>
            <div class="form-group">
              <label for="exampleInputName1">Habitantes de la comuna</label>
              <input type="text" class="form-control" id="exampleInputName1" [value]="casoInnovacion.attributes.cantidad_habitantes_comuna" disabled>
            </div>
            <div class="form-group">
              <label for="exampleInputName1">Nombre del (la) encargado(a) de completar este formulario</label>
              <input type="text" class="form-control" id="exampleInputName1" [value]="casoInnovacion.attributes.encargado_formulario" disabled>
            </div>
            <div class="form-group">
              <label for="exampleInputName1">Teléfono</label>
              <input type="text" class="form-control" id="exampleInputName1" [value]="casoInnovacion.attributes.telefonos_contacto" disabled>
            </div>
            <div class="form-group">
              <label for="exampleInputName1">E-mail</label>
              <input type="text" class="form-control" id="exampleInputName1" [value]="casoInnovacion.attributes.email" disabled>
            </div>
            <div class="form-group">
              <label for="exampleInputName1">Sitio Web</label>
              <input type="text" class="form-control" id="exampleInputName1" [value]="casoInnovacion.attributes.sitio_web" disabled>
            </div>
            <div class="form-group">
              <label for="exampleTextarea1">Objetivos propuestos de la experiencia de innovación pública</label>
              <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="casoInnovacion.attributes.objetivos_propuestos" disabled></textarea>
            </div>
            <div class="form-group">
              <label for="exampleTextarea1">Describa el problema/necesidad/oportunidad que buscó abordar la experiencia</label>
              <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="casoInnovacion.attributes.problema_necesidad" disabled></textarea>
            </div>
            <div class="form-group">
              <label for="exampleTextarea1">Describa los resultados/impactos esperados del desarrollo de esta iniciativa</label>
              <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="casoInnovacion.attributes.resultados_impactos" disabled></textarea>
            </div>
            <div class="form-group">
              <label for="exampleTextarea1">Caracterización de la situación previa a la experiencia</label>
              <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="casoInnovacion.attributes.situacion_previa" disabled></textarea>
            </div>
            <div class="form-group">
              <label for="exampleTextarea1">Etapas o fases del desarrollo de la experiencia de innovación pública</label>
              <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="casoInnovacion.attributes.fases_desarrollo" disabled></textarea>
            </div>
            <div class="form-group">
              <label for="exampleTextarea1">Caracterización y organización del equipo de trabajo que llevó a cabo la experiencia</label>
              <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="casoInnovacion.attributes.organizacion_equipo" disabled></textarea>
            </div>
            <div class="form-group">
              <label for="exampleTextarea1">Describa cómo elaboraron y desarrollaron la experiencia para el problema detectado</label>
              <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="casoInnovacion.attributes.elaboracion_desarrollo" disabled></textarea>
            </div>
            <div class="form-group">
              <label for="exampleTextarea1">Participación ciudadana</label>
              <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="casoInnovacion.attributes.participacion_ciudadana" disabled></textarea>
            </div>
            <div class="form-group">
              <label for="exampleTextarea1">¿Cómo se llevó a cabo el proceso de implementación de la experiencia?</label>
              <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="casoInnovacion.attributes.implementacion" disabled></textarea>
            </div>
            <div class="form-group">
              <label for="exampleTextarea1">Evaluación del impacto de la experiencia</label>
              <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="casoInnovacion.attributes.impacto" disabled></textarea>
            </div>
            <div class="form-group">
              <label for="exampleTextarea1">Aprendizajes del desarrollo de la experiencia</label>
              <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="casoInnovacion.attributes.aprendizaje" disabled></textarea>
            </div>
        </div>
      </div>
    </div>
  

  <!--  <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h2 class="card-title">Resultado global</h2>
          <p class="card-text">El resultado global corresponde a la suma de todos los puntos obtenidos, sin diferenciar si es por dimensión, variable o eje de medición.</p>
          <div class="row">
            <div class="col-md-6">
              <div class="d-block m-auto" id="usersDoughnutChart"></div>
              <ngx-gauge class="d-block m-auto" [type]="gaugeType" [value]="puntajeGlobalTotal" [label]="gaugeLabel"
                [thick]="gaugeThickness" [foregroundColor]="gaugeForegroundColor" [size]="gaugeSize" [max]="maxValue">
              </ngx-gauge>
            </div>
            <div class="col-md-6 legend-wrapper">
              <div class="d-flex flex-column justify-content-center">
                <div class="d-flex align-items-center">
                  <div class="dot-indicator bg-primary mt-1 mr-2"></div>
                  <h4 class="mb-0"> El puntaje total es: {{ puntajeGlobalTotal }}</h4>
                </div>
                <small *ngIf="puntajeGlobalTotal >= 0 && puntajeGlobalTotal < 10" class="text-muted ml-3">La iniciativa necesita que involucren más elementos de innovación pública sustentable No te desanimes, aún puedes hacer más.</small>
                <small *ngIf="puntajeGlobalTotal >= 10 && puntajeGlobalTotal < 20"  class="text-muted ml-3">Persevera en lo que comenzaste. Aun puedes hacer más.</small>
                <small *ngIf="puntajeGlobalTotal >= 20 && puntajeGlobalTotal <= 30" class="text-muted ml-3">Sigue así, la iniciativa de innovación tiene los elementos de innovación pública sustentable.</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body d-flex flex-column">
          <div class="wrapper">
            <h4 class="card-title mb-0">Resultado según dimensión</h4>
            <p class="card-text" style="margin-top: 20px;">Equilibrio entre las dimensiones de la innovación pública local</p>
          </div>
          <canvas class="my-auto mx-auto" height="70" baseChart [chartType]="'radar'" [datasets]="netProfitData"
            [labels]="netProfitLabels" [options]="netProfitOptions" [legend]="false" [colors]="netProfitColors">
          </canvas>
        </div>
      </div>
    </div>
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8 d-flex flex-column">
              <div class="d-flex align-items-center">
                <h4 class="card-title mb-0">Resultado según eje de medición</h4>
              </div>
              <canvas class="my-4 my-md-0 mt-md-auto" height="50" baseChart [chartType]="'bar'" [datasets]="realtimeStatisticsData"
                [labels]="realtimeStatisticsLabels" [options]="realtimeStatisticsOptions" [legend]="false" [colors]="realtimeStatisticsColors">
              </canvas>
            </div>
            <div class="col-md-4">
              <p>Resultados generales</p>
              <div *ngFor="let eje of puntajeEjeMedicion" class="d-flex justify-content-between py-2 border-bottom">
                <div class="wrapper">
                  <p class="mb-0">{{ eje.nombre}}</p>
                  <h5 class="font-weight-medium">{{eje.puntajeTotal}} de {{eje.puntaje_maximo}} puntos</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 d-flex flex-column">
              <div class="d-flex align-items-center">
                <h4 class="card-title mb-0">Puntaje obtenido según variables</h4>
              </div>
              <canvas class="my-4 my-md-0 mt-md-auto" height="50" baseChart [chartType]="'bar'" [datasets]="variablesData"
                [labels]="variablesLabels" [options]="variablesOptions" [legend]="false" [colors]="variablesColors">
              </canvas>
            </div>
          </div>
        </div>
      </div>
    </div> -->


    <div *ngFor="let comentario of listadoComentarios" class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{ comentario.attributes.titulo }}</h5>
          <h6 class="card-subtitle mb-2 text-muted">{{ comentario.attributes.usuario[0].nombres + ' ' + comentario.attributes.usuario[0].apellidos }}</h6>
          <p class="card-text">{{ comentario.attributes.detalle }}</p>
        </div>
      </div>
    </div>
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Ingrese un comentario</h5>
          <input style="margin-bottom: 10px;" type="text" class="form-control form-control-lg"
                          placeholder="Ingrese el título del comentario"
                          aria-label="Username" [(ngModel)]="nuevoComentario.titulo">
          <textarea style="margin-bottom: 10px;" class="form-control" id="exampleTextarea1" rows="5"
                    [(ngModel)]="nuevoComentario.detalle"></textarea>
          <button type="submit" style="margin-right: 10px;" class="btn btn-primary btn-fw" (click)="guardarComentario()">Guardar</button>
                        <button style="margin-right: 10px;" class="btn btn-primary btn-fw" (click)="activeModal.close('Close click')">Cancelar</button>
        </div>
      </div>
    </div>
