import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-font-awesome',
  templateUrl: './font-awesome.component.html',
  styleUrls: ['./font-awesome.component.scss']
})
export class FontAwesomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
