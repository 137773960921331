<div class="container-xxl flex-grow-1 container-p-y">
  <h4 class="py-3 mb-4">Título</h4>
  <p> Página de inicio<br />
    Definir contenido escritorio/inicio </p>
<div class="card p-0 mb-4">
          <div class="card-body d-flex flex-column flex-md-row justify-content-between p-0 pt-4">
            <div class="app-academy-md-25 card-body py-0">
              <img src="assets/img/illustrations/bulb-light.png" class="img-fluid app-academy-img-height scaleX-n1-rtl" alt="Bulb in hand" data-app-light-img="illustrations/bulb-light.png" data-app-dark-img="illustrations/bulb-dark.png" height="90">
            </div>
            <div class="app-academy-md-50 card-body d-flex align-items-md-center flex-column text-md-center">
              <h3 class="card-title mb-4 lh-sm px-md-5 lh-lg">
                Bienvenido a Lab UBB.
                <span class="text-primary fw-medium text-nowrap">Plataforma de Métrica Pública</span>.
              </h3>
              <p class="mb-3">
                Plataforma para gobiernos regionales dedicada a medir el nivel de innovación de iniciativas e instituciones, a partir del modelo definido desde el Lab UBB.
              </p>
              <div class="d-flex align-items-center justify-content-between app-academy-md-80">
                <input type="search" placeholder="Buscar" class="form-control me-2">
                <button type="submit" class="btn btn-primary btn-icon waves-effect waves-light"><i class="ti ti-search"></i></button>
              </div>
            </div>
            <div class="app-academy-md-25 d-flex align-items-end justify-content-end">
              <img src="assets/img/illustrations/pencil-rocket.png" alt="pencil rocket" height="188" class="scaleX-n1-rtl">
            </div>
          </div>
        </div>
</div>