<div class="card col-12 grid-margin stretch-card">
  <div class="row g-0" >
    <div class="col-md-12">
      <div class="card-body">
        <div class="row">
          <h1 class="card-title" style="font-size: xx-large;">Casos evaluados para {{ nombreInstitucion}}</h1>

        </div>
      </div>
    </div>
  </div>
</div>
<div *ngFor="let caso of listadoCasos" class="row" > 
    <div class="card col-12 grid-margin stretch-card">
        <div class="row g-0">
          <div class="col-md-9">
            <div class="card-body">
              <a [routerLink]="'/detalle-caso-innovacion/' +caso.id"><h5 class="card-title" style="margin-bottom: 5px;font-size: 18px;">{{ caso.attributes.titulo | slice:0:50 }}</h5></a>
              <p class="card-text"><small class="text-muted"> {{ caso.attributes.created_at | date:short }}</small></p>
              <p class="card-text">{{caso.attributes.objetivos_propuestos}}</p>
            </div>
          </div>
          <!--div class="col-md-3">
            <img style="object-fit: contain; width: 100%; height: 100%;" src="assets/images/dashboard/img_1.jpg" alt="..."> 
          </div-->
        </div>
    </div>
</div>