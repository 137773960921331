<div class="container-xxl flex-grow-1 container-p-y">
              
  <!-- Users List Table -->
  <div class="card">
    <div class="card-datatable table-responsive">
      <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
        <div class="row me-2">
          <div class="col-md-10">
            <div class="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0">
              <div class="dt-buttons btn-group flex-wrap">
                 <button class="btn btn-secondary add-new btn-primary" 
                  type="button" (click)="crearUsuario()"><span><i
                      class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">Agregar usuario
                    </span></span></button>
              </div>
            </div>
          </div>
        </div>
        <table class="datatables-users table dataTable no-footer dtr-column" id="DataTables_Table_0"
          aria-describedby="DataTables_Table_0_info" style="width: 1395px;">
        <thead class="border-top">
          <tr>
            <th class="control sorting_disabled dtr-hidden" rowspan="1" colspan="1" style="width: 0px; display: none;"
              aria-label=""></th>
            <th class="sorting sorting_desc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
              style="width: 359px;" aria-label="Usuario: activate to sort column ascending" aria-sort="descending">Usuario</th>
            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style="width: 115px;"
              aria-label="Plan: activate to sort column ascending">Fecha de creación</th>
            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style="width: 110px;"
              aria-label="Estado: activate to sort column ascending">Estado</th>
            <th class="sorting_disabled" rowspan="1" colspan="1" style="width: 161px;" aria-label="Actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr class="odd" *ngFor="let usuario of listadoUsuarios">
             <td class="  control" tabindex="0" style="display: none;"></td>
             <td class="sorting_1">
                <div class="d-flex justify-content-start align-items-center user-name">
                   <div class="avatar-wrapper">
                      <div class="avatar me-3"><img src="assets/img/avatars/2.png" alt="Avatar" class="rounded-circle"></div>
                   </div>
                   <div class="d-flex flex-column"><a href="app-user-view-account.html" class="text-body text-truncate"><span class="fw-medium">{{ usuario.attributes.nombres + ' ' + usuario.attributes.apellidos}}</span></a><small class="text-muted">{{ usuario.attributes.rut }}</small></div>
                </div>
             </td>
             <td><span class="fw-medium"> {{ usuario.attributes.created_at | date:short}}  </span></td>
             <td><span class="badge bg-label-success" text-capitalized="">Active</span></td>
             <td>
                <div class="d-flex align-items-center">
                  <a class="text-body" (click)="editarUsuario(usuario)"><i class="ti ti-edit ti-sm me-2"></i></a>
                  <a class="text-body delete-record" (click)="asignarPerfiles(usuario)"><i class="ti ti-users ti-sm mx-2"></i></a>
                  <a class="text-body delete-record" (click)="eliminarUsuario(usuario)"><i class="ti ti-trash ti-sm mx-2"></i></a>
        
                </div>
             </td>
          </tr>
       </tbody>
      </table>
      </div>
    </div>
  </div>
</div>
<!-- / Content -->
<!-- <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Lista de Usuarios</h4>
        <div class="row">
            <div class="col-md-3 offset-md-9">
              <button type="button" class="btn btn-primary btn-fw" (click)="crearUsuario()">Crear usuario</button>
            </div>
          </div>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>
                  RUN
                </th>
                <th>
                  Nombre
                </th>
                <th>
                  Fecha
                </th>
                <th>
                  Acción
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let usuario of listadoUsuarios">
                <td>
                  {{ usuario.attributes.rut }}
              </td>
                <td>
                    {{ usuario.attributes.nombres + ' ' + usuario.attributes.apellidos}}
                </td>
                <td>
                    {{ usuario.attributes.created_at | date:short}} 
                </td>
                <td>
                  <button style="margin-right: 5px;" type="button" class="btn btn-primary" ngbTooltip="Editar usuario"(click)="editarUsuario(usuario)"><i class="fa fa-edit"></i></button>
                  <button style="margin-right: 5px;" type="button" class="btn btn-primary" ngbTooltip="Asignar perfiles"(click)="asignarPerfiles(usuario)"><i class="fa fa-users"></i></button>
                  <button style="margin-right: 5px;" type="button" class="btn btn-primary" ngbTooltip="Eliminar usuario" (click)="eliminarUsuario(usuario)"><i class="fa fa-eraser"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div> -->

