import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';
import { DetalleCasoComponent } from '../detalle-caso/detalle-caso.component';

@Component({
  selector: 'app-listado-casos',
  templateUrl: './listado-casos.component.html',
  styleUrls: ['./listado-casos.component.scss']
})
export class ListadoCasosComponent implements OnInit {

  listadoCasos: any[] = [];
  idInstitucion: any;
  estadoCasoAprobado = 'Aprobado';

  constructor(private restangular: Restangular,
    private router: Router,
    private modalService: NgbModal,
    private toastr: ToastrService) {
      this.idInstitucion = JSON.parse(localStorage.getItem('institucion')).id;
     }

  ngOnInit(): void {
    this.cargarCasos();
  }

  cargarCasos(){
    this.restangular.all('casoinnovacions').get('').subscribe(data => {
      this.listadoCasos = data.data;
    })
  }

  goEncuestaIniciativa(caso){
    this.router.navigate(['/encuesta-iniciativa',caso.id]);
  }

  abrirDetalleCaso(caso){
    this.router.navigate(['/detalle-evaluar-caso',caso.id]);
  }

  abrirDetalleCasoModal(caso){
    const modalRef = this.modalService.open(DetalleCasoComponent, {
      size: '500',
      centered: true
    });

    modalRef.componentInstance.caso = caso;

    modalRef.result.then( result => {
      console.log('Se ha cerrado el modal');
      //this.cargarMunicipalidades();
    })
  }

  eliminarCasoModal(caso){
    console.log('eliminando');
      let jsonApiWrapper = {
        data: {
            type: 'casoinnovacions',
            attributes: {
              estado: 'N'
          },
            id: ''
          }
      };
   jsonApiWrapper.data.id = caso.id;
   if(confirm("Está seguro que desea eliminar el caso "+caso.attributes.titulo)){
    this.restangular.all('casoinnovacions/' + caso.id).remove().subscribe(
      data => {
        this.toastr.success('El caso se ha eliminado correctamente', 'Ok');
        //this.activeModal.close();
        this.cargarCasos();
      },
      error => {
        this.toastr.error('Hubo un error al eliminar el caso', 'Error');
      }
  );
   }
    
  }
}
