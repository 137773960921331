import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Observable } from 'rxjs';
import { ResponseCuestionario } from '../api/modelos/ResponseCuestionario';

@Injectable({
  providedIn: 'root'
})
export class CasoInnovacionService {
  
  constructor(private http: HttpClient,
    private restangular: Restangular,) {}  
  
  obtenerInstituciones(): Observable<ResponseCuestionario[]> {  
    return this.restangular.all('institucions?filter[estado]=S').get('');
  }
  
  obtenerCaso(idCaso: number): Observable<any[]> {  
    return this.restangular.all('casoinnovacions?filter[id]='+idCaso).get('');
  }
  
}