import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
  //styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'star-admin-pro-angular';

  constructor(private router: Router){
    console.log(this.constructor.name);
  }

  ngOnInit(): void {
  }

  login(){
    console.log('login');
    this.router.navigateByUrl('/dashboard');
  }
}
