<!-- Footer -->
<footer class="content-footer footer bg-footer-theme">
  <div class="container-xxl">
    <div
          class="footer-container d-flex align-items-center justify-content-between py-2 flex-md-row flex-column">
      <div> © 
        <script>
              document.write(new Date().getFullYear());
            </script> 
        , Lab UBB, 2023<a href="#" target="_blank" class="fw-medium"></a> </div>
      <div class="d-none d-lg-inline-block"> <a
              href="#"
              target="_blank"
              class="footer-link me-4"
              >Soloweb</a
            > </div>
    </div>
  </div>
</footer>
<!-- / Footer -->
<!--<footer class="footer">
  <div class="d-sm-flex justify-content-center justify-content-sm-between">
    <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a
        href="https://www.ubiobio.cl/" target="_blank" style="color: #0056b3;">Universidad del Bio-Bio</a>. Todos los derechos reservados.</span>
  </div>
</footer> -->
