import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';
import { CasoInnovacions } from 'src/app/api/modelos/CasoInnovacion';

@Component({
  selector: 'app-detalle-evaluar-caso',
  templateUrl: './detalle-evaluar-caso.component.html',
  styleUrls: ['./detalle-evaluar-caso.component.scss']
})
export class DetalleEvaluarCasoComponent implements OnInit {

  casoInnovacion: any;

  constructor(private route: ActivatedRoute,
    private restangular: Restangular,
    private toastr: ToastrService) {
    this.casoInnovacion = this.route.snapshot.data.casoInnovacion.data[0];
   }

  ngOnInit(): void {
  }

}
