<div class="row">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Usuario invitado</h4>
                <p class="card-description">
                  Para ingresar como invitado, porfavor ingrese los siguiente datos:
                </p>
                  <div class="form-group">
                    <label for="nombre">Nombres</label>
                    <input type="text"  class="form-control" id="nombre" [(ngModel)]="nuevoUsuario.nombres" placeholder="Nombre">
                  </div>
                  <br>
                  <div class="form-group">
                    <label for="email">E-mail</label>
                    <input type="text" class="form-control" id="email" [(ngModel)]="nuevoUsuario.email" placeholder="E-mail">
                  </div>
                  <br>
                  
                    <button type="submit" style="margin-right: 10px;" class="btn btn-primary btn-fw" (click)="entrar()">Entrar</button>                      
              </div>
            </div>
</div>
