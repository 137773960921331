import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DragulaService } from 'ng2-dragula';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';
import { RolUsuario } from 'src/app/api/modelos/RolUsuario';

@Component({
  selector: 'app-asignar-perfiles',
  templateUrl: './asignar-perfiles.component.html',
  styleUrls: ['./asignar-perfiles.component.scss']
})
export class AsignarPerfilesComponent implements OnInit {

  @Input() public usuario;

  perfilesDisponibles: any[];
  perfilesDisponiblesModel: any[];
  perfilesAsignados: any[] = [];
  perfilesAsignadosModel: any[];
  listaPerfilesSeleccionados: any[] = [];

  rolUsuarioNuevo: RolUsuario[] = [];

  rolUsuarioBase: Restangular;

  constructor(private restangular: Restangular,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private dragulaService: DragulaService) {
      this.rolUsuarioBase = this.restangular.one('rolusuario');
     }

  ngOnInit(): void {
    //this.dragulaService.createGroup('profile-team', {});
    console.log(this.usuario);
    this.cargarPerfilesAsignados();
    this.cargarPerfilesDisponibles();
  }

  cargarPerfilesDisponibles(){
    this.restangular.all('usuario/rolesDisponibles/').get(this.usuario.id).subscribe(data => {
      this.perfilesDisponibles = data;
    });
  }

  cargarPerfilesAsignados(){
    this.restangular.all('usuario/roles/').get(this.usuario.id).subscribe(data => {
      this.perfilesAsignados = data;
    });
  }

  guardarPerfiles(){
    this.restangular.all('usuario/roles/'+this.usuario.id).get('').subscribe(
      data => {
        data.forEach(element => {
          console.log(element);
          this.restangular.one("rolusuario/"+element.id_rol_usuario).remove();
        });

        let jsonApiWrapper = {
          data: {
              type: 'rolusuario',
              attributes: null
          }
        };
        this.perfilesAsignados.forEach( e => {
          let rolUsuario: RolUsuario = {
            rol_id: e.id,
            usuario_id: this.usuario.id
          }
          this.rolUsuarioNuevo.push(rolUsuario);
          jsonApiWrapper.data.attributes = rolUsuario;
          this.rolUsuarioBase.post('', jsonApiWrapper).subscribe(
            data => {
                //this.ref.close();
            }
          );
        });
        this.toastr.success('Se han guardado correctamente los perfiles', 'Ok');
        this.activeModal.close();
      },
      error => {
        this.toastr.error('Se ha producido un error al guardar los perfiles', 'Error');
      }
    );
  }

  perfilesAsignadosModelEvent(event){
    console.log(event);
  }

}
