<div class="container-xxl flex-grow-1 container-p-y">
              

    <div class="card">
      <div class="card-datatable table-responsive">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
          <table class="datatables-users table dataTable no-footer dtr-column" id="DataTables_Table_0"
            aria-describedby="DataTables_Table_0_info" style="width: 1395px;">
            <thead>
              <tr>
                <th>
                  Título
                </th>
                <th>
                  Municipio
                </th>
                <th>
                  Fecha
                </th>
                <th>
                  Estado
                </th>
                <th>
                  Acción
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let caso of listadoCasos">
                <td>
                    {{ caso.attributes.titulo}}
                </td>
                <td>
                  {{ caso.attributes.institucion[0].nombre}}
              </td>
                <td>
                    {{ caso.attributes.created_at | date:short}}
                </td>
                <td>
                  {{ caso.attributes.estado}}
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <!-- <a class="text-body" (click)="editarMunicipalidad(institucion)"><i class="ti ti-edit ti-sm me-2"></i></a>
                    <a class="text-body delete-record" (click)="eliminarMunicipalidad(institucion)"><i class="ti ti-trash ti-sm mx-2"></i></a> -->
                    <a class="text-body delete-record" ngbTooltip="Detalle caso"(click)="abrirDetalleCasoModal(caso)"><i class="ti ti-plus ti-sm mx-2"></i></a>
                    <a class="text-body delete-record" ngbTooltip="Encuesta iniciativa" (click)="goEncuestaIniciativa(caso)"><i class="ti ti-tag ti-sm mx-2"></i></a>
                    <a class="text-body delete-record" ngbTooltip="Ver puntaje" (click)="verPuntajeCasoModal(caso.id)"><i class="ti ti-star ti-sm mx-2"></i></a>
                  </div>
               </td>
               <!--  <td>
                  <button style="margin-right: 5px; background-color: #E15957; border-color: #E15957;" type="button" class="btn btn-primary" ngbTooltip="Detalle caso"(click)="abrirDetalleCasoModal(caso)"><i class="fa fa-address-card-o"></i></button>
                  <button style="margin-right: 5px; background-color: #E15957; border-color: #E15957;" type="button" class="btn btn-primary" ngbTooltip="Encuesta iniciativa" (click)="goEncuestaIniciativa(caso)"><i class="fa fa-file-text-o"></i></button>

                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>