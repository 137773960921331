<div class="container-xxl flex-grow-1 container-p-y">

  <!-- Users List Table -->
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Contacto</h4>
      <p class="card-description"> 
         <span>Web Lab Ubb: <a href="https://www.labubb.cl/">https://www.labubb.cl/</a> </span><br>
         <span>Concepción: Avda. Collao 1202</span><br>
         <span>Teléfono: </span>
      </p>
      <form class="forms-sample">
        <div class="form-group row">
          <label for="exampleInputEmail2" class="col-sm-3 col-form-label">Nombre</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" id="exampleInputEmail2" placeholder="Nombre">
          </div>
        </div>
        <br>
        <div class="form-group row">
          <label for="exampleInputPassword2" class="col-sm-3 col-form-label">E-mail</label>
          <div class="col-sm-9">
            <input type="email" class="form-control" id="exampleInputPassword2" placeholder="E-mail">
          </div>
        </div>
        <br>
        <div class="form-group row">
          <label for="exampleTextarea1" class="col-sm-3 col-form-label" >Ingrese su comentario</label>
          <div class="col-sm-9">
              <textarea class="form-control" id="exampleTextarea1" rows="3"></textarea>
          </div>
        </div>
        <br>
        <button style="margin-right: 10px;" type="submit" class="btn btn-primary btn-fw">Enviar</button>
        <button style="margin-right: 10px;" class="btn btn-primary btn-fw">Cancelar</button>
      </form>
    </div>

  </div>
</div>


<!-- <div class="col-12 stretch-card">
    <div class="card">
      
    </div>
  </div> -->
