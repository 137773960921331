import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-banco-experiencias',
  templateUrl: './banco-experiencias.component.html',
  styleUrls: ['./banco-experiencias.component.scss']
})
export class BancoExperienciasComponent implements OnInit {

  listadoCasos: any[] = [];
  idInstitucion: any;
  estadoCasoArchivado = 'Archivado';

  constructor(private restangular: Restangular,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) { 
    this.idInstitucion = JSON.parse(localStorage.getItem('institucion')).id;
  }

  ngOnInit(): void {
    this.cargarCasosArchivados();
  }

  cargarCasosArchivados(){
    this.restangular.all('casoinnovacions?filter[estado]='+this.estadoCasoArchivado).get('').subscribe(data => {
      this.listadoCasos = data.data;
      console.log(this.listadoCasos);
    })
  }

}
