<div class="row">
    <div class="col-xl-12">
        <div class="row">
            <div class="col-sm-6">
                <div class="card-header" style="font-size: larger;">
                    Indicador Global Cuestionario Institucional
                </div>
                <table class="table">
                    <!-- <caption>Variable Innovación Abierta</caption> -->
                    <thead>
                        <tr class="bg-label-primary">
                            <th scope="col"></th>
                            <th scope="col">Indicador</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row"><b>Indicador Global Cuestionario Institucional</b></th>
                            <td> 98% </td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <table class="table">
                    <!-- <caption>Variable Innovación Abierta</caption> -->
                    <thead>
                        <tr class="bg-label-primary">
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row"><b>Dimensión Gestión Pública Local</b></th>
                            <td> {{ puntajeVariableGestionPublica[0].puntajeTotal + puntajeVariableGestionPublica[1].puntajeTotal / 20 | percent }} </td>
                        </tr>
                        <tr>
                            <th scope="row">Variable Innovación Abierta</th>
                            <td> {{ puntajeVariableGestionPublica[0].puntajeTotal / puntajeVariableGestionPublica[0].puntaje_maximo
                                | percent}} </td>
                        </tr>
                        <tr>
                            <th scope="row">Variable Desentralización</th>
                            <td> {{ puntajeVariableGestionPublica[1].puntajeTotal /
                                puntajeVariableGestionPublica[1].puntaje_maximo | percent}} </td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <table class="table">
                    <!-- <caption>Variable Innovación Abierta</caption> -->
                    <thead>
                        <tr class="bg-label-primary">
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row"><b>Dimensión Gobernanza Democrática</b></th>
                            <td> 98% </td>
                        </tr>
                        <tr>
                            <th scope="row">Variable Participación Ciudadana</th>
                            <td> 98% </td>
                        </tr>
                        <tr>
                            <th scope="row">Variable Asociatividad</th>
                            <td> 98% </td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <table class="table">
                    <!-- <caption>Variable Innovación Abierta</caption> -->
                    <thead>
                        <tr class="bg-label-primary">
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row"><b>Dimensión Desarrollo Territorial</b></th>
                            <td> 98% </td>
                        </tr>
                        <tr>
                            <th scope="row">Variable Capital Humano</th>
                            <td> 98% </td>
                        </tr>
                        <tr>
                            <th scope="row">Variable Competetividad</th>
                            <td> 98% </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-sm-6">
                  <h5 class="card-header">Resultado General</h5>
                  <div class="card-body">
                    <p class="card-text">
                        Innovar es una actividad de riesgo cuyo principal riesgo es no practicarla ¡Anímate y práctica la innovación pública!
                    </p>
                    <p class="demo-inline-spacing">
                      <a
                        class="btn btn-primary me-1"
                        data-bs-toggle="collapse"
                        href="#collapseExample"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample">
                        Ver más
                      </a>
                    </p>
                    <div class="collapse" id="collapseExample">
                      <div class="d-grid d-sm-flex p-3 border">
                        <span>
                            De acuerdo a tus resultados, la innovación de tu municipio cumple un bajo grado de innovación. Esto significa que, se necesitar trabajar en potenciar la creación y desarrollo de acciones innovadoras. Asimismo, la puntuación ha arrojado un bajo desarrollo en lo que respecta a tres ámbitos fundamentales para lograr ser un municipio innovador: Gestión Pública local, Desarrollo Territorial y Gobernanza democrática. 
                            En cuanto al ámbito de “Gestión Pública Local”, debido a que el municipio conoce la realidad local y sus características, tiende a utilizar sus recursos de manera eficiente para satisfacer las necesidades de la ciudadanía. En este sentido, no existirían prácticas asociadas a la innovación abierta; es decir, no existe una vinculación con redes de colaboración tanto internas como externas. Además, existe un deficiente diseño y/o implementación de iniciativas de innovación pública, no existiendo un enfoque hacia el mejoramiento continuo de las mismas. Asimismo, no se incentivarían de forma suficiente las capacidades de los diversos actores para el desarrollo de innovación pública y los mecanismos, espacios o instrumentos que fomentan y fortalecen la innovación pública están recién comenzando a desarrollarse.
                             En cuanto al ámbito de “Desarrollo Territorial”, existe una ausencia de mecanismos para involucrar a la ciudadanía en acciones innovadoras, ya sea desde el punto de vista de identificar las necesidades o bien para hacerlas partícipes de las distintas iniciativas de innovación. Se cuenta con pocas o nulas redes de colaboración a nivel interinstitucional. Por último, se realizan pocas o ningún tipo de acción para capacitar al personal en diversas temáticas asociadas con innovar, por tanto, existe una baja o nula cantidad de funcionarios que cuenta con conocimientos y capacidades para desarrollar innovación asociada a sus funciones dentro del municipio, condiciones que no permiten al mismo ser competitivo. Además, los servicios online o digitales no mejoran constantemente. Por otro lado, el municipio tiene un bajo desarrollo respecto a los ejes de medición; es decir, posee pocos o insuficientes insumos o recursos iniciales para desarrollar la innovación, dispone de deficientes procesos y estrategias en el proceso de innovación, y, por consiguiente, también obtiene insatisfactorios o lentos resultados de sus innovaciones. 
                            Con la instalación de una cultura innovadora en el municipio se crea valor público, lo cual significa que actualmente en su municipio, no se consigue lograr una transformación y creación de valor público, lo cual, en un largo plazo, podría afectar la relación con la ciudadanía, entendiendo que ésta demanda cada vez mas participación y mejora en servicios.                            
                        </span>
                      </div>
                    </div>
                  </div>
              </div>
        </div>
        <div class="row">
            
          </div>
    </div>
</div>