<div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Lista de casos para evaluar</h4>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>
                  Usuario
                </th>
                <th>
                  Título
                </th>
                <th>
                  Fecha
                </th>
                <th>
                  Estado
                </th>
                <th>
                  Acción
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let caso of listadoCasos">
                <td class="py-1">
                  <img src="assets/images/faces-clipart/pic-1.png" alt="image" />
                </td>
                <td>
                    {{ caso.attributes.titulo}}
                </td>
                <td>
                    {{ caso.attributes.created_at | date:short}}
                </td>
                <td>
                  {{ caso.attributes.estado}}
                </td>
                <td>
                  <button style="margin-right: 5px; background-color: #E15957; border-color: #E15957;" type="button" class="btn btn-primary" ngbTooltip="Detalle caso" (click)="abrirDetalleCasoModal(caso)"><i class="fa fa-address-card-o"></i></button>
                  <button style="margin-right: 5px; background-color: #E15957; border-color: #E15957;" type="button" class="btn btn-primary" ngbTooltip="Encuesta iniciativa" (click)="goEncuestaIniciativa(caso)"><i class="fa fa-file-text-o"></i></button>
                  <button style="margin-right: 5px; background-color: #E15957; border-color: #E15957;" type="button" class="btn btn-primary" ngbTooltip="Puntaje" (click)="abrirPuntajeCaso(caso)"><i class="fa fa-star"></i></button>
                  <button style="margin-right: 5px; background-color: #E15957; border-color: #E15957;" type="button" class="btn btn-primary" ngbTooltip="Enviar a casos evaluados" (click)="enviarCasosEvaluados(caso)"><i class="fa fa-archive"></i></button>
                  <button style="margin-right: 5px; background-color: #E15957; border-color: #E15957;" type="button" class="btn btn-primary" ngbTooltip="Enviar a banco de experiencias" (click)="enviarBancoExperiencias(caso)"><i class="fa fa-archive"></i></button>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="container-xxl flex-grow-1 container-p-y">
    <!-- Basic Bootstrap Table -->
    <div class="card">
      <h5 class="card-header">Lista de casos para evaluar</h5>
      <div class="table-responsive text-nowrap">
        <table class="table">
          <thead>
            <tr>
              <th>Usuario</th>
              <th>Título</th>
              <th>Fecha</th>
              <th>Estado</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody class="table-border-bottom-0">
            <tr *ngFor="let caso of listadoCasos">
              <td>
                <img src="assets/images/faces-clipart/pic-1.png" alt="image" />
                <span class="fw-medium">Angular Project</span>
              </td>
              <td>{{ caso.attributes.titulo}}</td>
              <td>
                {{ caso.attributes.created_at | date:short}}
              </td>
              <td><span class="badge bg-label-primary me-1">{{ caso.attributes.estado}}</span></td>
              <td>
                <div class="dropdown">
                  <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                    <i class="ti ti-dots-vertical"></i>
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="javascript:void(0);"
                      ><i class="ti ti-pencil me-1"></i> Edit</a
                    >
                    <a class="dropdown-item" href="javascript:void(0);"
                      ><i class="ti ti-trash me-1"></i> Delete</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>
    <!--/ Basic Bootstrap Table -->