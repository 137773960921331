// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /* DEV */
 /* wsServer: 'ws://localhost:4550',
  urlBase: 'http://backend.fic.test:80/api/v1/',
  urlFront: 'http://localhost:4200',*/

  /* QA */
 wsServer: 'ws://localhost:4550',
 urlBase: 'https://labinnovacionpublica.ubiobio.cl/backend.fic/public/api/v1/',
  urlFront: 'https://labinnovacionpublica.ubiobio.cl', 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
