import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styles: [
  ]
})
export class PerfilUsuarioComponent implements OnInit {

  idUsuario: any;
  usuario: any;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.usuario = this.route.snapshot.data.usuario.data.attributes;

    console.log(this.usuario );
  }

}
