import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { PreguntasServiceService } from "src/app/services/preguntas-service.service";

@Injectable()  
export class EncuestaInstitucionalRespuestasMultiplesResolve implements Resolve<any[]> {  

  idInstitucion: any;

  constructor(private postService: PreguntasServiceService) {
    this.idInstitucion = JSON.parse(localStorage.getItem('institucion')).id;
  }  
  
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {  
    return this.postService.getRespuestasAbiertasMultiplesInstitucionales(this.idInstitucion);  
  }  
} 