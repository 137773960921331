import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { CatalogoService } from "src/app/services/catalogo.service";

@Injectable()  
export class CatalogoProvinciasResolve implements Resolve<any[]> {  
  constructor(private catalogoService: CatalogoService) {}  
  
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {  
    return this.catalogoService.obtenerCatalogoPorNombre('PROVINCIAS');
  }  
} 