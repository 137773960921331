<div class="col-md-12 grid-margin stretch-card">
  <div class="card">
    <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <p class="card-text">Muchas gracias por responder el cuestionario y obtener los resultados. Para orientar la
              interpretación, te presentamos el siguiente glosario de las ejes, dimensiones y variables.</p>
          </div>
          <div class="col-md-2">
              <button  class="btn btn-primary btn-fw" (click)="descargarPuntaje()">Descargar puntaje</button>
        </div>
        </div>
    </div>
  </div>
</div>
<div id="exportPDF" #exportPdf>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h2 class="card-title">Resultado global</h2>
        <p class="card-text">El resultado global corresponde a la suma de todos los puntos obtenidos, sin diferenciar si es por dimensión, variable o eje de medición.</p>
        <div class="row">
          <div class="col-md-6">
            <div class="d-block m-auto" id="usersDoughnutChart"></div>
            <ngx-gauge class="d-block m-auto" [type]="gaugeType" [value]="puntajeGlobalTotal" [label]="gaugeLabel"
              [thick]="gaugeThickness" [foregroundColor]="gaugeForegroundColor" [size]="gaugeSize" [max]="maxValue">
            </ngx-gauge>
          </div>
          <div class="col-md-6 legend-wrapper">
            <div class="d-flex flex-column justify-content-center">
              <div class="d-flex align-items-center">
                <div class="dot-indicator bg-primary mt-1 mr-2"></div>
                <h4 class="mb-0"> El puntaje total es: {{ puntajeGlobalTotal }}</h4>
              </div>
              <small *ngIf="puntajeGlobalTotal >= 0 && puntajeGlobalTotal < 10" class="text-muted ml-3">La iniciativa necesita que involucren más elementos de innovación pública sustentable No te desanimes, aún puedes hacer más.</small>
              <small *ngIf="puntajeGlobalTotal >= 10 && puntajeGlobalTotal < 20"  class="text-muted ml-3">Persevera en lo que comenzaste. Aun puedes hacer más.</small>
              <small *ngIf="puntajeGlobalTotal >= 20 && puntajeGlobalTotal <= 30" class="text-muted ml-3">Sigue así, la iniciativa de innovación tiene los elementos de innovación pública sustentable.</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body d-flex flex-column">
        <div class="wrapper">
          <h4 class="card-title mb-0">Resultado según dimensión</h4>
          <p class="card-text" style="margin-top: 20px;">Equilibrio entre las dimensiones de la innovación pública local</p>
        </div>
        <canvas class="my-auto mx-auto" height="70" baseChart [chartType]="'radar'" [datasets]="netProfitData"
          [labels]="netProfitLabels" [options]="netProfitOptions" [legend]="false" [colors]="netProfitColors">
        </canvas>
        <div class="wrapper">
          <ul>
            <li>
              <p><strong>Gestión Pública</strong></p><p>Articulan tres actores relevantes para la promoción del
                desarrollo territorial. Al respecto, la organización municipal es el entorno en el que
                operan recursos humanos y donde los procedimientos, comunicaciones, funciones
                y tecnologías determinan los niveles y grados de eficiencia de la municipalidad.</p>
            </li>
            <li>
              <p><strong>Gobernanza Democrática</strong></p><p>Forma de dirigir un territorio en que el desafío de las
                políticas públicas se centra en su adaptación a las características locales;
                considerando la vinculación con a otros actores en el quehacer de la gestión
                pública. Además, involucra la generación de nuevas redes de colaboración, en
                virtud de aumentar las relaciones de confianza de aquellos actores que participan
                en estas estructuras.</p>
            </li>
            <li>
              <p><strong>Desarrollo Territorial</strong></p><p>Considera elementos económicos, productivos,
                ambientales, sociales e institucionales que configuran la sustentabilidad del
                desarrollo en el territorio.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-8 d-flex flex-column">
            <div class="d-flex align-items-center">
              <h4 class="card-title mb-0">Resultado según eje de medición</h4>
            </div>
            <canvas class="my-4 my-md-0 mt-md-auto" height="50" baseChart [chartType]="'bar'" [datasets]="realtimeStatisticsData"
              [labels]="realtimeStatisticsLabels" [options]="realtimeStatisticsOptions" [legend]="false" [colors]="realtimeStatisticsColors">
            </canvas>
          </div>
          <div class="col-md-4">
            <p>Resultados generales</p>
            <div *ngFor="let eje of puntajeEjeMedicion" class="d-flex justify-content-between py-2 border-bottom">
              <div class="wrapper">
                <p *ngIf="eje.nombre == 'Insumos y Recursos'" class="mb-0" ngbTooltip="Se refiere a todos los insumos y recursos que posee la
                institución o bien que escasean al momento de querer realizar innovación, aquí se
                incluyen recursos físicos, humanos, de conocimientos, infraestructurales, etc.">{{ eje.nombre}}</p>
                <p *ngIf="eje.nombre == 'Procesos y Estrategias'" class="mb-0" ngbTooltip="Se refiere a la evaluación del trabajo en equipo,
                las formas de organizar los trabajos, de delegar responsabilidades, aplicación de
                los conocimientos, entre otros.">{{ eje.nombre}}</p>
                <p *ngIf="eje.nombre == 'Impacto en la innovación'" class="mb-0" ngbTooltip="Responde a la interrogante ¿Qué se obtiene al
                innovar?, es importante poder identificar que están logrando los municipios al
                realizar innovaciones, como esto beneficia a los funcionarios o a los ciudadanos, si
                se optimizan los tiempos de atención o mejora la calidad de los servicios.">{{ eje.nombre}}</p>
                <h5 class="font-weight-medium">{{eje.puntajeTotal}} de {{eje.puntaje_maximo}} puntos</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 d-flex flex-column">
            <div class="d-flex align-items-center">
              <h4 class="card-title mb-0">Puntaje obtenido según variables</h4>
            </div>
            <canvas class="my-4 my-md-0 mt-md-auto" height="50" baseChart [chartType]="'bar'" [datasets]="variablesData"
              [labels]="variablesLabels" [options]="variablesOptions" [legend]="false" [colors]="variablesColors">
            </canvas>
            <div class="wrapper">
              <ul>
                <li>
                  <p><strong>Innovación Abierta</strong></p><p>Sistema de insumos procesos y resultados asociados a
                    procesar cambios y mejoras basados en la interrelación del sistema municipal con
                    el entorno. Esto implica que existe un sistema, que interactúa con ese medio y
                    procesa insumos para implementar y desarrollar la innovación en diferentes
                    niveles.</p>
                </li>
                <li>
                  <p><strong>Descentralización</strong></p><p>Implica fortalecer autonomía en la gestión local, en este caso
                    asociada a innovación.</p>
                </li>
                <li>
                  <p><strong>Participación Ciudadana</strong></p><p>Sistema que tiene el objetivo de interactuar con los
                    ciudadanos y las ciudadanas, a objeto de cogestionar decisiones (preferencias,
                    concesos o disensos.</p>
                </li>
                <li>
                  <p><strong>Asociatividad</strong></p><p>Promoción activa de la inserción en redes de diverso tipo (sector
                    privado, academia, publico, nacional o internacional) para fortalecer la innovación
                    abierta del municipio (vinculo proactivo con el medio).</p>
                </li>
                <li>
                  <p><strong>Capital Humano</strong></p><p>Línea base y procesos de promoción de la innovación.
                    centralidad en la formación para la gestión del conocimiento asociado a
                    innovación.</p>
                </li>
                <li>
                  <p><strong>Competitividad</strong></p><p>Mecanismo, procesos y resultados vinculados a proyectar la
                    innovación en la conectividad del territorio y los soportes institucionales para ello.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Detalle del caso</h4>
          <div class="form-group">
            <label for="exampleInputName1">Título</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.titulo" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Periodo o tiempo de desarrollo de la experiencia</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.tiempo_desarrollo_desde + ' - ' + caso.attributes.tiempo_desarrollo_hasta" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Clasificación de la experiencia</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.clasificacion_experiencia" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Temática de la experiencia de innovación pública</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.tematica" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Nombre de la Institución</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.nombre_institucion" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Categoría de Municipalidad según SUBDERE</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.categoria_muni_subdere" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Caracterización general de la comuna</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.caraterizacion_general_comuna" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Presupuesto Anual Municipal ($)</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.presupuesto_anual_municipal" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Otros</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.caracterizacion_otros" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Provincia</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.provincia" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Región</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.region" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Habitantes de la comuna</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.cantidad_habitantes_comuna" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Nombre del (la) encargado(a) de completar este formulario</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.encargado_formulario" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Teléfono</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.telefonos_contacto" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">E-mail</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.email" disabled>
          </div>
          <div class="form-group">
            <label for="exampleInputName1">Sitio Web</label>
            <input type="text" class="form-control" id="exampleInputName1" [value]="caso.attributes.sitio_web" disabled>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Objetivos propuestos de la experiencia de innovación pública</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.objetivos_propuestos" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Describa el problema/necesidad/oportunidad que buscó abordar la experiencia</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.problema_necesidad" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Describa los resultados/impactos esperados del desarrollo de esta iniciativa</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.resultados_impactos" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Caracterización de la situación previa a la experiencia</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.situacion_previa" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Etapas o fases del desarrollo de la experiencia de innovación pública</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.fases_desarrollo" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Caracterización y organización del equipo de trabajo que llevó a cabo la experiencia</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.organizacion_equipo" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Describa cómo elaboraron y desarrollaron la experiencia para el problema detectado</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.elaboracion_desarrollo" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Participación ciudadana</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.participacion_ciudadana" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">¿Cómo se llevó a cabo el proceso de implementación de la experiencia?</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.implementacion" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Evaluación del impacto de la experiencia</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.impacto" disabled></textarea>
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Aprendizajes del desarrollo de la experiencia</label>
            <textarea class="form-control" id="exampleTextarea1" rows="8" [value]="caso.attributes.aprendizaje" disabled></textarea>
          </div>
      </div>
    </div>
  </div-->
