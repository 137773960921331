import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-resultados-ei',
  templateUrl: './resultados-ei.component.html',
  styles: [
  ]
})
export class ResultadosEiComponent implements OnInit {

  @Input() public puntajeVariableGestionPublica;

  totalDimensiónGestionPublicaLocal: any;
  constructor() { }

  ngOnInit(): void {
    this.calcularPuntajeDimensionGestionPublicaLocal();
  }

  calcularPuntajeDimensionGestionPublicaLocal(){
    this.totalDimensiónGestionPublicaLocal = this.puntajeVariableGestionPublica[0].puntajeTotal + this.puntajeVariableGestionPublica[1].puntajeTotal / 20;
    console.log(this.totalDimensiónGestionPublicaLocal);
    
  }

}
