<div class="row">
    <div class="col-xl-12">
        <div class="nav-align-top nav-tabs-shadow mb-4">
          <ul class="nav nav-tabs nav-fill" role="tablist">
            <li class="nav-item">
              <button
                type="button"
                class="nav-link active"
                role="tab"
                data-bs-toggle="tab"
                data-bs-target="#navs-justified-home"
                aria-controls="navs-justified-home"
                aria-selected="true">
                <i class="tf-icons ti ti-home ti-xl me-1"></i> Gestión Pública Local
                <!-- <span class="badge rounded-pill badge-center h-px-20 w-px-20 bg-label-danger ms-1">3</span> -->
              </button>
            </li>
            <li class="nav-item">
              <button
                type="button"
                class="nav-link"
                role="tab"
                data-bs-toggle="tab"
                data-bs-target="#navs-justified-profile"
                aria-controls="navs-justified-profile"
                aria-selected="false">
                <i class="tf-icons ti ti-user ti-xl me-1"></i> Gobernanza Democrática
              </button>
            </li>
            <li class="nav-item">
              <button
                type="button"
                class="nav-link"
                role="tab"
                data-bs-toggle="tab"
                data-bs-target="#navs-justified-messages"
                aria-controls="navs-justified-messages"
                aria-selected="false">
                <i class="tf-icons ti ti-message-dots ti-xl me-1"></i> Desarrollo Territorial
              </button>
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane fade show active" id="navs-justified-home" role="tabpanel">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="card-header" style="font-size: larger;">
                          Variable Innovación Abierta
                        </div>
                          <table class="table">
                            <!-- <caption>Variable Innovación Abierta</caption> -->
                            <thead>
                              <tr class="bg-label-primary">
                                <th scope="col"></th>
                                <th scope="col">Puntaje</th>
                                <th scope="col">Indicador</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Puntaje Innovación Abierta</th>
                                <td> {{ puntajeVariableGestionPublica[0].puntajeTotal }}</td>
                                <td> {{ puntajeVariableGestionPublica[0].puntajeTotal / puntajeVariableGestionPublica[0].puntaje_maximo
                                  | percent}} </td>
                              </tr>
                            </tbody>
                          </table>
                      </div>
                      <br>
                      <div class="col-sm-6">
                            <div class="card-header" style="font-size: larger;">
                              Variable Descentralización
                            </div>
                            <div class="card-body">
                              <table class="table">
                                <!-- <caption>Variable Descentralización</caption> -->
                                <thead>
                                  <tr class="bg-label-primary">
                                    <th scope="col"></th>
                                    <th scope="col">Puntaje</th>
                                    <th scope="col">Indicador</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">Puntaje Innovación Abierta</th>
                                    <td> {{ puntajeVariableGestionPublica[1].puntajeTotal }} </td>
                                    <td> {{ puntajeVariableGestionPublica[1].puntajeTotal /
                                      puntajeVariableGestionPublica[1].puntaje_maximo | percent}} </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                  
                      </div>
                </div>
                <div class="row">
                      <div class="col-sm-12">
                        <div class="card-header" style="font-size: larger;">
                          Ejes de medición
                        </div>
                        <div class="card-body">
                          <table class="table">
                           <!--  <caption>Ejes de medición</caption> -->
                            <thead>
                              <tr class="bg-label-primary">
                                <th scope="col"></th>
                                <th scope="col">Puntaje</th>
                                <th scope="col">Indicador</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let eje of sumaPuntajeEjeMedicion">
                                <th *ngIf="eje.dimension == 'Gestión Pública Local'" scope="row"> {{ eje.nombre }}</th>
                                <td *ngIf="eje.dimension == 'Gestión Pública Local'"> {{ eje.suma_puntaje }}</td>
                                <td *ngIf="eje.dimension == 'Gestión Pública Local'"> {{ eje.suma_puntaje / eje.puntaje_maximo |
                                  percent}} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                  </div>
            </div>
            <div class="tab-pane fade" id="navs-justified-profile" role="tabpanel">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="card-header" style="font-size: larger;">
                          Variable Participación Ciudadana
                        </div>
                          <table class="table">
                            <!-- <caption>Variable Participación Ciudadana</caption> -->
                            <thead>
                              <tr class="bg-label-primary">
                                <th scope="col"></th>
                                <th scope="col">Puntaje</th>
                                <th scope="col">Indicador</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Puntaje Innovación Abierta</th>
                                <td> {{ puntajeVariableGobernanzaDemocratica[0].puntajeTotal }}</td>
                                <td> {{ puntajeVariableGobernanzaDemocratica[0].puntajeTotal / puntajeVariableGobernanzaDemocratica[0].puntaje_maximo
                                  | percent}} </td>
                              </tr>
                            </tbody>
                          </table>
                      </div>
                      <div class="col-sm-6">
                            <div class="card-header" style="font-size: larger;">
                              Variable Asociatividad
                            </div>
                            <div class="card-body">
                              <table class="table">
                                <!-- <caption>Variable Asociatividad</caption> -->
                                <thead>
                                  <tr class="bg-label-primary">
                                    <th scope="col"></th>
                                    <th scope="col">Puntaje</th>
                                    <th scope="col">Indicador</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">Puntaje Innovación Abierta</th>
                                    <td> {{ puntajeVariableGobernanzaDemocratica[1].puntajeTotal }} </td>
                                    <td> {{ puntajeVariableGobernanzaDemocratica[1].puntajeTotal /
                                        puntajeVariableGobernanzaDemocratica[1].puntaje_maximo | percent}} </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                  
                      </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm-12">
                      <div class="card-header" style="font-size: larger;">
                        Ejes de medición
                      </div>
                      <div class="card-body">
                        <table class="table">
                          <!-- <caption>Ejes de medición</caption> -->
                          <thead>
                            <tr class="bg-label-primary">
                              <th scope="col"></th>
                              <th scope="col">Puntaje</th>
                              <th scope="col">Indicador</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let eje of sumaPuntajeEjeMedicion">
                              <th *ngIf="eje.dimension == 'Gobernanza Democrática'" scope="row"> {{ eje.nombre }}</th>
                              <td *ngIf="eje.dimension == 'Gobernanza Democrática'"> {{ eje.suma_puntaje }}</td>
                              <td *ngIf="eje.dimension == 'Gobernanza Democrática'"> {{ eje.suma_puntaje / eje.puntaje_maximo |
                                percent}} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="navs-justified-messages" role="tabpanel">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="card-header" style="font-size: larger;">
                          Variable Desarrollo Territorial
                        </div>
                          <table class="table">
                            <!-- <caption>Variable Desarrollo Territorial</caption> -->
                            <thead>
                              <tr class="bg-label-primary">
                                <th scope="col"></th>
                                <th scope="col">Puntaje</th>
                                <th scope="col">Indicador</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Puntaje Innovación Abierta</th>
                                <td> {{ puntajeVariableDesarrolloTerritorial[0].puntajeTotal }}</td>
                                <td> {{ puntajeVariableDesarrolloTerritorial[0].puntajeTotal / puntajeVariableDesarrolloTerritorial[0].puntaje_maximo
                                  | percent}} </td>
                              </tr>
                            </tbody>
                          </table>
                      </div>
                      <div class="col-sm-6">
                            <div class="card-header" style="font-size: larger;">
                              Variable Asociatividad
                            </div>
                            <div class="card-body">
                              <table class="table">
                                <!-- <caption>Variable Asociatividad</caption> -->
                                <thead>
                                  <tr class="bg-label-primary">
                                    <th scope="col"></th>
                                    <th scope="col">Puntaje</th>
                                    <th scope="col">Indicador</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">Puntaje Innovación Abierta</th>
                                    <td> {{ puntajeVariableDesarrolloTerritorial[1].puntajeTotal }} </td>
                                    <td> {{ puntajeVariableDesarrolloTerritorial[1].puntajeTotal /
                                        puntajeVariableDesarrolloTerritorial[1].puntaje_maximo | percent}} </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                  
                      </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm-12">
                      <div class="card-header" style="font-size: larger;">
                        Ejes de medición
                      </div>
                      <div class="card-body">
                        <table class="table">
                          <!-- <caption>Ejes de medición</caption> -->
                          <thead>
                            <tr class="bg-label-primary">
                              <th scope="col"></th>
                              <th scope="col">Puntaje</th>
                              <th scope="col">Indicador</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let eje of sumaPuntajeEjeMedicion">
                              <th *ngIf="eje.dimension == 'Desarrollo Territorial'" scope="row"> {{ eje.nombre }}</th>
                              <td *ngIf="eje.dimension == 'Desarrollo Territorial'"> {{ eje.suma_puntaje }}</td>
                              <td *ngIf="eje.dimension == 'Desarrollo Territorial'"> {{ eje.suma_puntaje / eje.puntaje_maximo |
                                percent}} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
</div>