import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cuestionaro-caso-innovacion',
  templateUrl: './cuestionaro-caso-innovacion.component.html',
  styleUrls: ['./cuestionaro-caso-innovacion.component.scss']
})
export class CuestionaroCasoInnovacionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  netProfitData = [
    {
      borderWidth: 2,
      fill: false,
      radius: 0,
      pointRadius: 0,
      pointBorderWidth: 0,
      pointHoverRadius: 10,
      pointHitRadius: 5,
      data: [10, 35, 60]
    },
    {
      borderWidth: 2,
      fill: false,
      radius: 0,
      pointRadius: 0,
      pointBorderWidth: 0,
      pointHoverRadius: 10,
      pointHitRadius: 5,
      data: [80, 100, 70]
    },
    {
      borderWidth: 2,
      fill: false,
      radius: 0,
      pointRadius: 0,
      pointBorderWidth: 0,
      pointHoverRadius: 10,
      pointHitRadius: 5,
      data: [5, 55, 90]
    }
  ];

  netProfitColors = [
    {
      backgroundColor: "rgba(53, 137, 238)",
      borderColor: "rgba(53, 137, 238)"
    },
    {
      backgroundColor: "rgba(209, 106, 64)",
      borderColor: "rgba(209, 106, 64)"
    },
    {
      backgroundColor: "rgba(107, 211, 62 )",
      borderColor: "rgba(107, 211, 62 )"
    }
  ];

  netProfitOptions = {
    scale: {
      ticks: {
        beginAtZero: true,
        min: 0,
        max: 100,
        stepSize: 20,
        display: false
      },
      pointLabels: {
        fontSize: 14,
        fontColor: "#b9c0d3",
        color: "#b9c0d3"
      },
      angleLines: {
        color: "#b9c0d3",
        zeroLineColor: "#b9c0d3"
      },
      gridLines: {
        color: "#b9c0d3",
        zeroLineColor: "#b9c0d3"
      }
    },
    legend: false
  };

  netProfitLabels = ["I", "P", "R"];

}
