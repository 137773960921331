<div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Lista de casos a evaluar</h4>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>
                  Usuario
                </th>
                <th>
                  Título
                </th>
                <th>
                    Municipalidad
                </th>
                <th>
                  Fecha
                </th>
                <th>
                  Estado
                </th>
                <th>
                  Acción
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let caso of listadoCasos">
                <td class="py-1">
                  <img src="assets/images/faces-clipart/pic-1.png" alt="image" />
                </td>
                <td>
                    {{ caso.attributes.titulo}}
                </td>
                <td>
                    {{ caso.attributes.institucion[0].nombre}}
                </td>
                <td>
                    {{ caso.attributes.created_at | date:short}}
                </td>
                <td>
                  {{ caso.attributes.estado}}
                </td>
                <td>
                  <button style="margin-right: 5px;" type="button" class="btn btn-primary" ngbTooltip="Detalle caso"(click)="abrirDetalleCasoModal(caso)"><i class="fa fa-address-card-o"></i></button>
                  <button style="margin-right: 5px;" type="button" class="btn btn-primary" ngbTooltip="Eliminar caso"(click)="eliminarCasoModal(caso)"><i class="fa fa-address-card-o"></i></button>
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-primary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      Acciones
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" (click)="descargarEncuesta()">Descargar encuesta</a></li>
                      <li><a class="dropdown-item" (click)="borrarEncuesta()">Borrar respuestas</a></li>
                      <li>
                        <hr class="dropdown-divider" />
                      </li>
                      <li><a class="dropdown-item" (click)="verPuntajes()">Ver puntajes</a></li>
                      <li><a class="dropdown-item" (click)="verResultados()">Ver resultados</a></li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>