import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private restangular: Restangular,
    private toastr: ToastrService) { }

    totalRespuestaPregunta5: any;
    totalRespuestaPregunta1: any;
    totalRespuestaPregunta10: any;
    totalRespuestaPregunta19: any;
    totalRespuestaPregunta39: any;
    puntajePromedioGobernanzaDemocratica: any;
    puntajeMaximoGobernanzaDemocratica: any;
    puntajePromedioDesarrolloTerritorial: any;
    puntajeMaximoDesarrolloTerritorial: any;
    puntajePromedioEjeMedicionGD: any;
    puntajePromedioEjeMedicionDT: any;

  ngOnInit(): void {
    this.totalCasosIngresados = this.route.snapshot.data.totalCasos[0];
    this.totalCasosEvaluados = this.route.snapshot.data.totalCasosEvaluados[0];
    this.totalInstitucionesRegistrados = this.route.snapshot.data.totalInstitucionesRegistradas[0];
    this.totalUsuariosRegistrados = this.route.snapshot.data.totalUsuariosRegistrados[0];

    this.totalRespuestaPregunta11 = this.route.snapshot.data.totalRespuestaPregunta11[0];
    this.totalPregunta11[0].data[0] = this.totalRespuestaPregunta11.totalRespuesta;
    this.totalPregunta11[0].data[1] = this.totalInstitucionesRegistrados.total_instituciones - this.totalRespuestaPregunta11.totalRespuesta;

    this.totalRespuestaPregunta15 = this.route.snapshot.data.totalRespuestaPregunta15[0];
    this.totalPregunta15[0].data[0] = this.totalRespuestaPregunta15.totalRespuesta;
    this.totalPregunta15[0].data[1] = this.totalInstitucionesRegistrados.total_instituciones - this.totalRespuestaPregunta15.totalRespuesta;

    if(this.route.snapshot.data.totalRespuestaPregunta26[0]){
      this.totalPregunta26[0].data[0] = this.route.snapshot.data.totalRespuestaPregunta26[0].total;
      this.totalPregunta26[0].data[1] = this.route.snapshot.data.totalRespuestaPregunta26[1].total;
      this.totalPregunta26[0].data[2] = this.route.snapshot.data.totalRespuestaPregunta26[2].total;
      this.totalPregunta26[0].data[3] = this.route.snapshot.data.totalRespuestaPregunta26[3].total;
      this.totalPregunta26[0].data[4] = this.route.snapshot.data.totalRespuestaPregunta26[4].total;
      this.totalPregunta26[0].data[5] = this.route.snapshot.data.totalRespuestaPregunta26[5].total;
      this.totalPregunta26[0].data[6] = this.route.snapshot.data.totalRespuestaPregunta26[6].total;
    }
    
    if(this.route.snapshot.data.nombreRespuestaMayorPreguntaMultiple[0]){
      this.nombreRespuestaMayorPreguntaMultiple = this.route.snapshot.data.nombreRespuestaMayorPreguntaMultiple[0].texto;
    }
    
    if(this.route.snapshot.data.promedioRespuestaComplementaria25[0]){
      this.promedioRespuestaComplementaria25 = this.route.snapshot.data.promedioRespuestaComplementaria25[0].promedio;
    }

    if(this.route.snapshot.data.puntajePromedioDimensiones[0]){
      this.puntajePromedioGestionPublicaLocal = this.route.snapshot.data.puntajePromedioDimensiones[1].puntajeTotal;
      this.puntajeMaximoGestionPublicaLocal = this.route.snapshot.data.puntajePromedioDimensiones[1].puntaje_maximo;

      this.puntajePromedioGobernanzaDemocratica = this.route.snapshot.data.puntajePromedioDimensiones[2].puntajeTotal;
      this.puntajeMaximoGobernanzaDemocratica = this.route.snapshot.data.puntajePromedioDimensiones[2].puntaje_maximo;

      this.puntajePromedioDesarrolloTerritorial = this.route.snapshot.data.puntajePromedioDimensiones[0].puntajeTotal;
      this.puntajeMaximoDesarrolloTerritorial = this.route.snapshot.data.puntajePromedioDimensiones[0].puntaje_maximo;

    }

    if(this.route.snapshot.data.puntajePromedioEjeMedicion[0]){
      this.puntajePromedioEjeMedicion = this.route.snapshot.data.puntajePromedioEjeMedicion;

      this.realtimeStatisticsData[0].data[0] = this.route.snapshot.data.puntajePromedioEjeMedicion[0].puntaje_promedio;
      this.realtimeStatisticsData[0].data[1] = this.route.snapshot.data.puntajePromedioEjeMedicion[1].puntaje_promedio;
      this.realtimeStatisticsData[0].data[2] = this.route.snapshot.data.puntajePromedioEjeMedicion[2].puntaje_promedio;
  
      this.realtimeStatisticsData[1].data[0] = this.route.snapshot.data.puntajePromedioEjeMedicion[0].puntaje_maximo;
      this.realtimeStatisticsData[1].data[1] = this.route.snapshot.data.puntajePromedioEjeMedicion[1].puntaje_maximo;
      this.realtimeStatisticsData[1].data[2] = this.route.snapshot.data.puntajePromedioEjeMedicion[2].puntaje_maximo;
    }

    if(this.route.snapshot.data.puntajePromedioEjeMedicionGD[0]){
      this.puntajePromedioEjeMedicionGD = this.route.snapshot.data.puntajePromedioEjeMedicionGD;

      this.ejeMedicionGobernanzaDemocraticaData[0].data[0] = this.route.snapshot.data.puntajePromedioEjeMedicionGD[0].puntaje_promedio;
      this.ejeMedicionGobernanzaDemocraticaData[0].data[1] = this.route.snapshot.data.puntajePromedioEjeMedicionGD[1].puntaje_promedio;
      this.ejeMedicionGobernanzaDemocraticaData[0].data[2] = this.route.snapshot.data.puntajePromedioEjeMedicionGD[2].puntaje_promedio;
  
      this.ejeMedicionGobernanzaDemocraticaData[1].data[0] = this.route.snapshot.data.puntajePromedioEjeMedicionGD[0].puntaje_maximo;
      this.ejeMedicionGobernanzaDemocraticaData[1].data[1] = this.route.snapshot.data.puntajePromedioEjeMedicionGD[1].puntaje_maximo;
      this.ejeMedicionGobernanzaDemocraticaData[1].data[2] = this.route.snapshot.data.puntajePromedioEjeMedicionGD[2].puntaje_maximo;
    }

    if(this.route.snapshot.data.puntajePromedioEjeMedicionDT[0]){
      this.puntajePromedioEjeMedicionDT = this.route.snapshot.data.puntajePromedioEjeMedicionDT;

      this.ejeMedicionDesarrolloTerritorialData[0].data[0] = this.route.snapshot.data.puntajePromedioEjeMedicionDT[0].puntaje_promedio;
      this.ejeMedicionDesarrolloTerritorialData[0].data[1] = this.route.snapshot.data.puntajePromedioEjeMedicionDT[1].puntaje_promedio;
      this.ejeMedicionDesarrolloTerritorialData[0].data[2] = this.route.snapshot.data.puntajePromedioEjeMedicionDT[2].puntaje_promedio;
  
      this.ejeMedicionDesarrolloTerritorialData[1].data[0] = this.route.snapshot.data.puntajePromedioEjeMedicionDT[0].puntaje_maximo;
      this.ejeMedicionDesarrolloTerritorialData[1].data[1] = this.route.snapshot.data.puntajePromedioEjeMedicionDT[1].puntaje_maximo;
      this.ejeMedicionDesarrolloTerritorialData[1].data[2] = this.route.snapshot.data.puntajePromedioEjeMedicionDT[2].puntaje_maximo;
    }
    
    if(this.route.snapshot.data.totalRespuestaPregunta5[0]){
      this.totalRespuestaPregunta5 = this.route.snapshot.data.totalRespuestaPregunta5[0];

      this.totalPregunta5Data[0].data[0] = this.totalRespuestaPregunta5.totalRespuesta;
      this.totalPregunta5Data[0].data[1] = this.totalInstitucionesRegistrados.total_instituciones - this.totalRespuestaPregunta5.totalRespuesta;
    }

    if(this.route.snapshot.data.totalRespuestaPregunta1[0]){
      this.totalRespuestaPregunta1 = this.route.snapshot.data.totalRespuestaPregunta1[0];

      this.totalPregunta1Data[0].data[0] = this.totalRespuestaPregunta1.totalRespuesta;
      this.totalPregunta1Data[0].data[1] = this.totalInstitucionesRegistrados.total_instituciones - this.totalRespuestaPregunta1.totalRespuesta;
    }

    if(this.route.snapshot.data.totalRespuestaPregunta10[0]){
      this.totalRespuestaPregunta10 = this.route.snapshot.data.totalRespuestaPregunta10[0];

      this.totalPregunta10Data[0].data[0] = this.totalRespuestaPregunta10.totalRespuesta;
      this.totalPregunta10Data[0].data[1] = this.totalInstitucionesRegistrados.total_instituciones - this.totalRespuestaPregunta10.totalRespuesta;
    }

    if(this.route.snapshot.data.totalRespuestaPregunta19[0]){
      this.totalRespuestaPregunta19 = this.route.snapshot.data.totalRespuestaPregunta19[0];

      this.totalPregunta19Data[0].data[0] = this.totalRespuestaPregunta19.totalRespuesta;
      this.totalPregunta19Data[0].data[1] = this.totalInstitucionesRegistrados.total_instituciones - this.totalRespuestaPregunta19.totalRespuesta;
    }
    
    if(this.route.snapshot.data.totalRespuestaPregunta39[0]){
      this.totalRespuestaPregunta39 = this.route.snapshot.data.totalRespuestaPregunta39[0];

      this.totalPregunta39Data[0].data[0] = this.totalRespuestaPregunta39.totalRespuesta;
      this.totalPregunta39Data[0].data[1] = this.totalInstitucionesRegistrados.total_instituciones - this.totalRespuestaPregunta39.totalRespuesta;
    }
  }

  public isCollapsed = false;

  gaugeType = "semi";
  gaugeValue = 28.3;
  gaugeLabel = "Daily average";
  gaugeForegroundColor = "#62d896";
  gaugeSize = "180";
  gaugeThickness = "20";

  dashboardBarChartlabels = [
    "Day 1",
    "Day 2",
    "Day 3",
    "Day 4",
    "Day 5",
    "Day 6",
    "Day 7",
    "Day 8",
    "Day 9",
    "Day 10",
    "Day 11",
    "Day 12",
    "Day 13"
  ];
  dashboardBarChartOptions = {
    scales: {
      yAxes: [
        {
          display: false
        }
      ],
      xAxes: [
        {
          display: false
        }
      ]
    },
    legend: {
      display: false
    },
    elements: {
      point: {
        radius: 0
      },
      line: {
        tension: 0
      }
    },
    stepsize: 100
  };
  dashboardBarChartColors = [
    {
      borderColor: "#6d7bf7",
      backgroundColor: "#e6e8fd"
    }
  ];
  dashboardBarChartDataOne = [
    {
      label: "Profit",
      data: [7, 6, 9, 7, 8, 6, 8, 5, 7, 8, 6, 7, 7],
      borderWidth: 3,
      fill: true
    }
  ];
  dashboardBarChartDataTwo = [
    {
      label: "Profit",
      data: [7, 6, 8, 5, 7, 8, 6, 7, 7, 6, 9, 7, 8],
      borderWidth: 3,
      fill: true
    }
  ];
  dashboardBarChartDataThree = [
    {
      label: "Profit",
      data: [8, 6, 7, 8, 5, 7, 9, 7, 8, 7, 6, 7, 6],
      borderWidth: 3,
      fill: true
    }
  ];
  dashboardBarChartDataFour = [
    {
      label: "Profit",
      data: [7, 6, 8, 5, 8, 6, 8, 7, 8, 6, 9, 7, 7],
      borderWidth: 3,
      fill: true
    }
  ];

  realtimeStatisticsLabels = [
    "Insumos y Recursos",
    "Procesos y Estrategias",
    "Impacto en la innovación"
  ];
  realtimeStatisticsOptions = {
    responsive: true,
    maintainAspectRatio: true,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },
    scales: {
      yAxes: [
        {
          display: false,
          gridLines: {
            display: false
          }
        }
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            fontColor: "#354168"
          },
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
            display: false
          },
          barPercentage: 0.4
        }
      ]
    },
    elements: {
      point: {
        radius: 0
      }
    }
  };
  realtimeStatisticsData = [
    {
      label: "Puntaje promedio",
      data: []
    },
    {
      label: "Puntaje máximo",
      data: []
    }
  ];
  realtimeStatisticsColors = [
    {
      backgroundColor: "#3096f3"
    },
    {
      backgroundColor: "rgba(238, 242, 245, 1)"
    }
  ];

  //Datos gráfico Eje de medición Gobernanza democrática

  ejeMedicionGobernanzaDemocraticaLabels = [
    "Insumos y Recursos",
    "Procesos y Estrategias",
    "Impacto en la innovación"
  ];
  ejeMedicionGobernanzaDemocraticaOptions = {
    responsive: true,
    maintainAspectRatio: true,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },
    scales: {
      yAxes: [
        {
          display: false,
          gridLines: {
            display: false
          }
        }
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            fontColor: "#354168"
          },
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
            display: false
          },
          barPercentage: 0.4
        }
      ]
    },
    elements: {
      point: {
        radius: 0
      }
    }
  };
  ejeMedicionGobernanzaDemocraticaData = [
    {
      label: "Puntaje promedio",
      data: []
    },
    {
      label: "Puntaje máximo",
      data: []
    }
  ];
  ejeMedicionGobernanzaDemocraticaColors = [
    {
      backgroundColor: "#3096f3"
    },
    {
      backgroundColor: "rgba(238, 242, 245, 1)"
    }
  ];

  /* -------------------------------------------------------------------------------------------------------------- */


  //Datos gráfico Eje de medición Desarrollo Territorial

  ejeMedicionDesarrolloTerritorialLabels = [
    "Insumos y Recursos",
    "Procesos y Estrategias",
    "Impacto en la innovación"
  ];
  ejeMedicionDesarrolloTerritorialOptions = {
    responsive: true,
    maintainAspectRatio: true,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },
    scales: {
      yAxes: [
        {
          display: false,
          gridLines: {
            display: false
          }
        }
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            fontColor: "#354168"
          },
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
            display: false
          },
          barPercentage: 0.4
        }
      ]
    },
    elements: {
      point: {
        radius: 0
      }
    }
  };
  ejeMedicionDesarrolloTerritorialData = [
    {
      label: "Puntaje promedio",
      data: []
    },
    {
      label: "Puntaje máximo",
      data: []
    }
  ];
  ejeMedicionDesarrolloTerritorialColors = [
    {
      backgroundColor: "#3096f3"
    },
    {
      backgroundColor: "rgba(238, 242, 245, 1)"
    }
  ];

  /* -------------------------------------------------------------------------------------------------------------- */

  respuestaPregunta26 = ["A. Meta de la institución", "B. A partir de los funcionarios municipales que trabajan en la entidad",
    "C. Mandato de las autoridades (Ministerios/Subsecreatia/Gobierno regional o central, otros)",
    "D. A partir de la ciudadanía no organizada",
    "E. A partir de la ciudadanía organizada",
    "F. A partir de la otra entidad pública",
    "G. Otro ¿Cuál es?"];

    opcionesGraficoPregunta26 = {
      cutoutPercentage: 70,
      animationEasing: "easeOutBounce",
      animateRotate: true,
      animateScale: false,
      responsive: true,
      maintainAspectRatio: false,
      showScale: true,
      legend: {
        display: true,
        position: 'right',
        fontSize: 5,
        labels: {
          boxWidth: 5,
        }
      }
    };

    coloresGraficoPregunta26 = [
      {
        backgroundColor: ["#4472C4", "#ED7D31", "#A5A5A5","#FFC000", "#5B9BD5", "#70AD47","#264478"],
        borderColor: ["#4472C4", "#ED7D31", "#A5A5A5","#FFC000", "#5B9BD5", "#70AD47","#264478"]
      }
    ];

    totalPregunta26 = [
      {
        data: []
      }
    ];

  //Pregunta 1
  respuestaPregunta1Label = ["Si", "No"];
  totalPregunta1Data = [
    {
      data: []
    }
  ];
  opcionesGraficoPregunta1 = {
    cutoutPercentage: 70,
    animationEasing: "easeOutBounce",
    animateRotate: true,
    animateScale: false,
    responsive: true,
    maintainAspectRatio: true,
    showScale: true,
    legend: {
      display: true
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    }
  };
  coloresGraficoPregunta1 = [
    {
      backgroundColor: ["#92D050", "#EAEAEA"],
      borderColor: ["#92D050", "#EAEAEA"]
    }
  ];

  //Pregunta 5
  respuestaPregunta5Label = ["Si", "No"];
  totalPregunta5Data = [
    {
      data: []
    }
  ];
  opcionesGraficoPregunta5 = {
    cutoutPercentage: 70,
    animationEasing: "easeOutBounce",
    animateRotate: true,
    animateScale: false,
    responsive: true,
    maintainAspectRatio: true,
    showScale: true,
    legend: {
      display: true
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    }
  };
  coloresGraficoPregunta5 = [
    {
      backgroundColor: ["#92D050", "#EAEAEA"],
      borderColor: ["#92D050", "#EAEAEA"]
    }
  ];

  //Pregunta 10
  respuestaPregunta10Label = ["Si", "No"];
  totalPregunta10Data = [
    {
      data: []
    }
  ];
  opcionesGraficoPregunta10 = {
    cutoutPercentage: 70,
    animationEasing: "easeOutBounce",
    animateRotate: true,
    animateScale: false,
    responsive: true,
    maintainAspectRatio: true,
    showScale: true,
    legend: {
      display: true
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    }
  };
  coloresGraficoPregunta10 = [
    {
      backgroundColor: ["#92D050", "#EAEAEA"],
      borderColor: ["#92D050", "#EAEAEA"]
    }
  ];

  //Pregunta 11
  respuestaPregunta11 = ["Si", "No"];
  totalPregunta11 = [
    {
      data: []
    }
  ];
  opcionesGraficoPregunta11 = {
    cutoutPercentage: 70,
    animationEasing: "easeOutBounce",
    animateRotate: true,
    animateScale: false,
    responsive: true,
    maintainAspectRatio: true,
    showScale: true,
    legend: {
      display: true
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    }
  };
  coloresGraficoPregunta11 = [
    {
      backgroundColor: ["#92D050", "#EAEAEA"],
      borderColor: ["#92D050", "#EAEAEA"]
    }
  ];

  //Pregunta 19
  respuestaPregunta19Label = ["Si", "No"];
  totalPregunta19Data = [
    {
      data: []
    }
  ];
  opcionesGraficoPregunta19 = {
    cutoutPercentage: 70,
    animationEasing: "easeOutBounce",
    animateRotate: true,
    animateScale: false,
    responsive: true,
    maintainAspectRatio: true,
    showScale: true,
    legend: {
      display: true
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    }
  };
  coloresGraficoPregunta19 = [
    {
      backgroundColor: ["#92D050", "#EAEAEA"],
      borderColor: ["#92D050", "#EAEAEA"]
    }
  ];

  //Pregunta 39
  respuestaPregunta39Label = ["Si", "No"];
  totalPregunta39Data = [
    {
      data: []
    }
  ];
  opcionesGraficoPregunta39 = {
    cutoutPercentage: 70,
    animationEasing: "easeOutBounce",
    animateRotate: true,
    animateScale: false,
    responsive: true,
    maintainAspectRatio: true,
    showScale: true,
    legend: {
      display: true
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    }
  };
  coloresGraficoPregunta39 = [
    {
      backgroundColor: ["#92D050", "#EAEAEA"],
      borderColor: ["#92D050", "#EAEAEA"]
    }
  ];

  opcionesGraficoPregunta15 = {
    cutoutPercentage: 70,
    animationEasing: "easeOutBounce",
    animateRotate: true,
    animateScale: false,
    responsive: true,
    maintainAspectRatio: true,
    showScale: true,
    legend: {
      display: true
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    }
  };
  coloresGraficoPregunta15 = [
    {
      backgroundColor: ["#3096f3", "#f6af42", "#62d896"],
      borderColor: ["#3096f3", "#f6af42", "#62d896"]
    }
  ];

  puntajeDimension: any[];
  puntajePromedioEjeMedicion: any[];
  puntajePromedioGestionPublicaLocal: any;
  puntajeMaximoGestionPublicaLocal: any;

  totalInstituciones: any;
  totalRespuestaPregunta11: any;
  totalRespuestaPregunta15: any;

  respuestaPregunta15 = ["Si", "No"];
  totalPregunta15 = [
    {
      data: []
    }
  ];

  promedioRespuestaComplementaria25: any;
 

  obtenerPuntajePromedioDimensiones(){
    this.restangular.all('puntaje-promedio-gestion-publica-local/').get('1').subscribe( data => {
      console.log(data);
      this.puntajeDimension = data;
      setTimeout(() => {
        
        this.puntajePromedioGestionPublicaLocal = this.puntajeDimension[1].puntajeTotal;
        this.puntajeMaximoGestionPublicaLocal = this.puntajeDimension[1].puntaje_maximo;
        //this.llenarGraficoDimension();
      },500
      );
      
    });
  }

  obtenerPuntajePromedioEjeMedicion(){
    this.restangular.all('puntaje-promedio-eje-medicion/').get('1').subscribe( data => {
      console.log(data);
      
      setTimeout(() => {
        this.puntajePromedioEjeMedicion = data;

        this.realtimeStatisticsData[0].data[0] = this.puntajePromedioEjeMedicion[0].puntaje_promedio;
        this.realtimeStatisticsData[0].data[1] = this.puntajePromedioEjeMedicion[1].puntaje_promedio;
        this.realtimeStatisticsData[0].data[2] = this.puntajePromedioEjeMedicion[2].puntaje_promedio;

        this.realtimeStatisticsData[1].data[0] = this.puntajePromedioEjeMedicion[0].puntaje_maximo;
        this.realtimeStatisticsData[1].data[1] = this.puntajePromedioEjeMedicion[1].puntaje_maximo;
        this.realtimeStatisticsData[1].data[2] = this.puntajePromedioEjeMedicion[2].puntaje_maximo;
      },500
      );
      
    });
  }

  obtenerTotalInstituciones(){
    this.restangular.all('total-instituciones').get('').subscribe( data => {
      console.log(data);
      
      setTimeout(() => {
        this.totalInstituciones = data[0];
      },500
      );
      
    });
  }

  obtenerTotalRespuestaPregunta(numeroPregunta, numeroRespuesta){
    this.restangular.all('total-respuesta-pregunta/').get(numeroPregunta+'/'+numeroRespuesta).subscribe( data => {
      console.log(data);
      
      setTimeout(() => {
        if(numeroPregunta === '11'){
          this.totalRespuestaPregunta11 = data[0];
          this.totalPregunta11[0].data[0] = this.totalRespuestaPregunta11.totalRespuesta;
          this.totalPregunta11[0].data[1] = this.totalInstituciones.total_instituciones - this.totalRespuestaPregunta11.totalRespuesta;
        } else if (numeroPregunta === '15'){
          this.totalRespuestaPregunta15 = data[0];
          this.totalPregunta15[0].data[0] = this.totalRespuestaPregunta15.totalRespuesta;
          this.totalPregunta15[0].data[1] = this.totalInstituciones.total_instituciones - this.totalRespuestaPregunta15.totalRespuesta;
        }
        
      },500
      );
      
    });
  }

  obtenerPromedioRespuestaComplementaria(numeroPregunta){
    this.restangular.all('promedio-respuesta-complementaria/').get(numeroPregunta).subscribe( data => {
      console.log(data);
      
      setTimeout(() => {
        if(numeroPregunta === '25'){
          this.promedioRespuestaComplementaria25 = data[0].promedio;
        } 
      },500
      );
      
    });
  }

  obtenerPromedioRespuestaPreguntaMultiple(numeroPregunta){
    this.restangular.all('promedio-respuesta-pregunta-multiple/').get(numeroPregunta).subscribe( data => {
      console.log(data);
      
      setTimeout(() => {
        if(numeroPregunta === '26'){
          this.totalPregunta26[0].data[0] = data[0].total;
          this.totalPregunta26[0].data[1] = data[1].total;
          this.totalPregunta26[0].data[2] = data[2].total;
          this.totalPregunta26[0].data[3] = data[3].total;
          this.totalPregunta26[0].data[4] = data[4].total;
          this.totalPregunta26[0].data[5] = data[5].total;
          this.totalPregunta26[0].data[6] = data[6].total;
        } 
      },500
      );
      
    });
  }

  nombreRespuestaMayorPreguntaMultiple: any;
  obtenerMayorRespuestaPreguntaMultiple(numeroPregunta){
    this.restangular.all('mayor-respuesta-pregunta-multiple/').get(numeroPregunta).subscribe( data => {
      console.log(data);
      
      setTimeout(() => {
        if(numeroPregunta === '26'){
          this.nombreRespuestaMayorPreguntaMultiple = data[0].texto;
        } 
      },500
      );
      
    });
  }

  totalCasosIngresados: any;
  obtenerTotalCasos(){
    this.restangular.all('total-casos').get('').subscribe( data => {
      
      setTimeout(() => {
        this.totalCasosIngresados = data[0];
      },1000
      );
      
    });
  }

  totalCasosEvaluados: any;
  obtenerTotalCasosEvaluados(){
    this.restangular.all('total-casos-evaluados').get('').subscribe( data => {
      
      setTimeout(() => {
        this.totalCasosEvaluados = data[0];
      },1000
      );
      
    });
  }

  totalUsuariosRegistrados: any;
  obtenerTotalUsuariosRegistrados(){
    this.restangular.all('total-usuarios-registrados').get('').subscribe( data => {
      
      setTimeout(() => {
        this.totalUsuariosRegistrados = data[0];
      },1000
      );
      
    });
  }

  totalInstitucionesRegistrados: any;
  obtenerTotalInstitucionesRegistrados(){
    this.restangular.all('total-instituciones-registradas').get('').subscribe( data => {
      
      setTimeout(() => {
        this.totalInstitucionesRegistrados = data[0];
      },1000
      );
      
    });
  }

}
