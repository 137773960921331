import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { CasoInnovacionService } from "src/app/services/caso-innovacion.service";
import { PreguntasServiceService } from "src/app/services/preguntas-service.service";

@Injectable()  
export class CrearCasoInnovacionResolve implements Resolve<any[]> {  
  constructor(private casoInnovacionService: CasoInnovacionService) {}  
  
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {  
      console.log('En el resolve');
    return this.casoInnovacionService.obtenerInstituciones();
  }  
} 