import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Restangular } from 'ngx-restangular';
import { DetalleCasoComponent } from '../detalle-caso/detalle-caso.component';

@Component({
  selector: 'app-listado-casos-municipio',
  templateUrl: './listado-casos-municipio.component.html',
  styleUrls: ['./listado-casos-municipio.component.scss']
})
export class ListadoCasosMunicipioComponent implements OnInit {

  listadoCasos: any[] = [];
  idInstitucion: any;
  estadoCasoAprobado = 'Aprobado';

  constructor(private restangular: Restangular,
    private router: Router,
    private modalService: NgbModal) {
      this.idInstitucion = JSON.parse(localStorage.getItem('institucion')).id;
     }

  ngOnInit(): void {
    this.cargarCasos();
  }

  cargarCasos(){
    this.restangular.all('casoinnovacions?filter[institucion_id]='+this.idInstitucion).get('').subscribe(data => {
      this.listadoCasos = data.data;
    })
  }

  goEncuestaIniciativa(caso){
    this.router.navigate(['/encuesta-iniciativa',caso.id]);
  }

  abrirDetalleCaso(caso){
    this.router.navigate(['/detalle-evaluar-caso',caso.id]);
  }

  abrirDetalleCasoModal(caso){
    const modalRef = this.modalService.open(DetalleCasoComponent, {
      size: '500',
      centered: true
    });

    modalRef.componentInstance.caso = caso;

    modalRef.result.then( result => {
      console.log('Se ha cerrado el modal');
      //this.cargarMunicipalidades();
    })
  }
}
