import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';
import { Institucion } from 'src/app/api/modelos/Institucion';

@Component({
  selector: 'app-agregar-institucion',
  templateUrl: './agregar-institucion.component.html',
  styleUrls: ['./agregar-institucion.component.scss']
})
export class AgregarInstitucionComponent implements OnInit {

  nuevaInstitucion: Institucion = {
    descripcion : "",
    nombre: "",
    estado: "S"
  }
  nuevaInstitucionCreada: any;

  institucionBase: Restangular;
  institucionCuestionarioBase: Restangular;

  @Input() public institucion;

  constructor(public activeModal: NgbActiveModal,
    private restangular: Restangular,
    private route: ActivatedRoute,
    private toastr: ToastrService) { 
      this.institucionBase = this.restangular.one('institucions');
      this.institucionCuestionarioBase = this.restangular.one('institucioncuestionarios');
    }

  ngOnInit(): void {
    console.log(this.institucion);
    if(this.institucion){
      this.nuevaInstitucion = this.institucion.attributes;
    }
  }

  guardarMunicipalidad(){
    if(this.institucion){
      let jsonApiWrapper = {
        data: {
            type: 'institucions',
            attributes: {
                nombre: this.nuevaInstitucion.nombre,
                descripcion: this.nuevaInstitucion.descripcion
            },
            id: ''
          }
      };
   jsonApiWrapper.data.id = this.institucion.id;
    this.restangular.all('institucions/' + this.institucion.id).patch(jsonApiWrapper).subscribe(
      data => {
        this.toastr.success('la municipalidad se ha editado correctamente', 'Ok');
        this.activeModal.close();
      },
      error => {
        this.toastr.error('Hubo un error al editar la municipalidad', 'Error');
      }
  );
    } else {
      console.log('creando');
      let jsonApiWrapper = {
        data: {
            type: 'institucions',
            attributes: null
        }
      };
  
      jsonApiWrapper.data.attributes = this.nuevaInstitucion;
  
      this.institucionBase.post('', jsonApiWrapper).subscribe(
        caso => {
              //this.nuevaInstitucionCreada = caso.data;
              let jsonApiWrapper = {
                data: {
                    type: 'institucioncuestionarios',
                    attributes: {
                        institucion_id: Number(caso.data.id),
                        cuestionario_id: 1
                    }
                  }
              };
              this.institucionCuestionarioBase.post('', jsonApiWrapper).subscribe( data => {

              });
              this.toastr.success('Se ha guardado correctamente la municipalidad', 'Ok');
              this.activeModal.close();
        },
            () => {
              this.toastr.success('Se ha guardado correctamente la municipalidad', 'Ok');
            }
          );
    }
    
        
  }

  

}
