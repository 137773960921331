import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';
import { DetalleCasoComponent } from '../casos-innovacion/detalle-caso/detalle-caso.component';
import { PuntajeCasoComponent } from '../puntaje-caso/puntaje-caso.component';

@Component({
  selector: 'app-aprobar-caso',
  templateUrl: './aprobar-caso.component.html',
  styleUrls: ['./aprobar-caso.component.scss']
})
export class AprobarCasoComponent implements OnInit {

  listadoCasos: any[] = [];
  idInstitucion: any;
  estadoCasoAprobado = 'En-validacion';

  constructor(private restangular: Restangular,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal, 
    public activeModal: NgbActiveModal) {
      this.idInstitucion = JSON.parse(localStorage.getItem('institucion')).id;
     }

  ngOnInit(): void {
    this.cargarCasos();
  }

  cargarCasos(){
    this.restangular.all('casoinnovacions?filter[estado]='+this.estadoCasoAprobado).get('').subscribe(data => {
      this.listadoCasos = data.data;
    })
  }

  goEncuestaIniciativa(caso){
    this.router.navigate(['/encuesta-iniciativa',caso.id]);
  }

  abrirDetalleCaso(caso){
    this.router.navigate(['/detalle-evaluar-caso',caso.id]);
  }

  abrirDetalleCasoModal(caso){
    const modalRef = this.modalService.open(DetalleCasoComponent, {
      size: '500',
      centered: true
    });

    modalRef.componentInstance.caso = caso;

    modalRef.result.then( result => {
      console.log('Se ha cerrado el modal');
      //this.cargarMunicipalidades();
    })
  }

  abrirPuntajeCaso(caso){
    //this.router.navigate(['/detalle-caso-innovacion',caso.id]);
    const modalRef = this.modalService.open(PuntajeCasoComponent, {
      size: 'xl',
      centered: true
    });

    modalRef.componentInstance.casoInnovacion = caso;

    modalRef.result.then( result => {
      console.log('Se ha cerrado el modal');
      //this.cargarMunicipalidades();
    })
  }

  enviarCasosEvaluados(caso){
    let jsonApiWrapper = {
      data: {
          type: 'casoinnovacions',
          attributes: {
              estado: 'Finalizado'
          },
          id: ''
      }
  };
  jsonApiWrapper.data.id = caso.id;
  this.restangular.all('casoinnovacions/' + caso.id).patch(jsonApiWrapper).subscribe(
      data => {
        this.toastr.success('El caso se ha enviado al archivo de casos evaluados correctamente', 'Ok');
        this.cargarCasos();
      },
      error => {
        this.toastr.error('Hubo un error al enviar el caso', 'Error');
      }
  );
  }

  enviarBancoExperiencias(caso){
    let jsonApiWrapper = {
      data: {
          type: 'casoinnovacions',
          attributes: {
              estado: 'Archivado'
          },
          id: ''
      }
  };
  jsonApiWrapper.data.id = caso.id;
  this.restangular.all('casoinnovacions/' + caso.id).patch(jsonApiWrapper).subscribe(
      data => {
        this.toastr.success('El caso se ha enviado al banco de experiencias correctamente', 'Ok');
        this.cargarCasos();
      },
      error => {
        this.toastr.error('Hubo un error al enviar el caso', 'Error');
      }
  );
  }
}
