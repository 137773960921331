import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';
import { CrearCasoInnovacionComponent } from '../crear-caso-innovacion/crear-caso-innovacion.component';
import { DetalleCasoComponent } from '../casos-innovacion/detalle-caso/detalle-caso.component';

@Component({
  selector: 'app-listado-casos-innovacion',
  templateUrl: './listado-casos-innovacion.component.html',
  styleUrls: ['./listado-casos-innovacion.component.css']
})
export class ListadoCasosInnovacionComponent implements OnInit {

  listadoCasos: any[] = [];
  idInstitucion: any;
  estadoCasoAprobado = 'Aprobado';

  constructor(private restangular: Restangular,
    private router: Router,
    private modalService: NgbModal,
    private toastr: ToastrService) {
      this.idInstitucion = JSON.parse(localStorage.getItem('institucion')).id;
     }

  ngOnInit(): void {
    this.cargarCasos();
  }

  cargarCasos(){
    this.restangular.all('casoinnovacions').get('').subscribe(data => {
      this.listadoCasos = data.data;
    })
  };

  crearCasoInnovacion(){
    const modalRef = this.modalService.open(CrearCasoInnovacionComponent, {
      size: 'xl',
      centered: true
    });

    modalRef.result.then( result => {
      console.log('Se ha cerrado el modal');
      this.cargarCasos();
    })

  }

  aprobarCaso(caso){
    console.log(caso);
    let jsonApiWrapper = {
      data: {
          type: 'casoinnovacions',
          attributes: {
              estado: 'Aprobado'
          },
          id: ''
      }
  };
  jsonApiWrapper.data.id = caso.id;
  this.restangular.all('casoinnovacions/' + caso.id).patch(jsonApiWrapper).subscribe(
      data => {
        this.toastr.success('El caso se ha admitido correctamente', 'Ok');
        this.cargarCasos();
        this.restangular.all('send-email/admisibilidad-aprobada').get('').subscribe(data => {
        });
      },
      error => {
        this.toastr.error('Hubo un error al aprobar el caso', 'Error');
      }
  );
  };

  enviarBancoExperiencias(caso){
    let jsonApiWrapper = {
      data: {
          type: 'casoinnovacions',
          attributes: {
              estado: 'Archivado'
          },
          id: ''
      }
  };
  jsonApiWrapper.data.id = caso.id;
  this.restangular.all('casoinnovacions/' + caso.id).patch(jsonApiWrapper).subscribe(
      data => {
        this.toastr.success('El caso se ha enviado al banco de experiencias correctamente', 'Ok');
        this.cargarCasos();
      },
      error => {
        this.toastr.error('Hubo un error al enviar el caso', 'Error');
      }
  );
  };

  enviarCasosEvaluados(caso){
    let jsonApiWrapper = {
      data: {
          type: 'casoinnovacions',
          attributes: {
              estado: 'Finalizado'
          },
          id: ''
      }
  };
  jsonApiWrapper.data.id = caso.id;
  this.restangular.all('casoinnovacions/' + caso.id).patch(jsonApiWrapper).subscribe(
      data => {
        this.toastr.success('El caso se ha enviado al archivo de casos evaluados correctamente', 'Ok');
        this.cargarCasos();
      },
      error => {
        this.toastr.error('Hubo un error al enviar el caso', 'Error');
      }
  );
  };

  abrirDetalleCasoModal(caso){
    const modalRef = this.modalService.open(DetalleCasoComponent, {
      size: '500',
      centered: true
    });

    modalRef.componentInstance.caso = caso;

    modalRef.result.then( result => {
      console.log('Se ha cerrado el modal');
      //this.cargarMunicipalidades();
    })
  }

}
