import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EncuestaInstitucionalComponent } from './encuesta-institucional/encuesta-institucional.component';
import { AuthGuard } from '../guards/AuthGuard';
import { PrincipalComponent } from './principal/principal.component';
import { EventosComponent } from './eventos/eventos.component';
import { CasosInnovacionComponent } from './casos-innovacion/casos-innovacion.component';
import { EvaluarCasosComponent } from './evaluar-casos/evaluar-casos.component';
import { DetalleEvaluarCasoComponent } from './detalle-evaluar-caso/detalle-evaluar-caso.component';
import { CrearCasoInnovacionComponent } from './crear-caso-innovacion/crear-caso-innovacion.component';
import { CuestionaroCasoInnovacionComponent } from './cuestionaro-caso-innovacion/cuestionaro-caso-innovacion.component';
import { DetalleCasoInnovacionComponent } from './detalle-caso-innovacion/detalle-caso-innovacion.component';
import { BancoExperienciasComponent } from './banco-experiencias/banco-experiencias.component';
import { EncuestaInstitucionalCuestionarioResolve } from './encuesta-institucional/encuesta-institucional-cuestionario.resolve';
import { EncuestaInstitucionalRespuestaValoresResolve } from './encuesta-institucional/encuesta-institucional-respuesta-valores.resolve';
import { InicioComponent } from './inicio/inicio.component';
import { EncuestaInstitucionalRespuestasMultiplesResolve } from './encuesta-institucional/encuesta-institucional-respuestas-multiples.resolve';
import { CrearCasoInnovacionResolve } from './crear-caso-innovacion/crear-caso-innovacion.resolver';
import { CatalogoResolve } from './crear-caso-innovacion/catalogo.resolver';
import { CatalogoProvinciasResolve } from './crear-caso-innovacion/catalogo-provincias.resolver';
import { CatalogoRegionesResolve } from './crear-caso-innovacion/catalogo-regiones.resolver';
import { EncuestaIniciativaComponent } from './encuesta-iniciativa/encuesta-iniciativa.component';
import { EncuestaIniciativaCuestionarioResolve } from './encuesta-iniciativa/encuesta-iniciativa-cuestionario.resolve';
import { EncuestaIniciativaRespuestasResolve } from './encuesta-iniciativa/encuesta-iniciativa-respuestas-resolve';
import { EncuestaIniciativaRespuestasMultiplesResolve } from './encuesta-iniciativa/encuesta-iniciativa.respuestas-multiples.resolve';
import { AdmisionCasosComponent } from './admision-casos/admision-casos.component';
import { DetalleAdmisibilidadComponent } from './detalle-admisibilidad/detalle-admisibilidad.component';
import { DetalleCasoInnovacionCargarCasoResolve } from './detalle-caso-innovacion/detalle-caso-innovacion.cargar-caso.resolve';
import { AprobarCasoComponent } from './aprobar-caso/aprobar-caso.component';
import { DetalleEvaluarCasoCargarCasoResolve } from './detalle-evaluar-caso/detalle-evaluar-caso.cargar-caso.resolve';
import { InstitucionesComponent } from './instituciones/instituciones.component';
import { TotalCasosResolve } from './inicio/total-casos-resolver';
import { TotalCasosEvaluadosResolve } from './inicio/total-casos-evaluados.resolver';
import { TotalUsuariosRegistradosResolve } from './inicio/total-usuarios-registrados-resolver';
import { TotalInstitucionesRegistradasResolve } from './inicio/total-instituciones-registradas.resolver';
import { TotalRespuestaPregunta11Resolve } from './inicio/total-respuesta-pregunta-11.resolver';
import { TotalRespuestaPregunta15Resolve } from './inicio/total-respuesta-pregunta-15.resolver';
import { TotalRespuestaPregunta26Resolve } from './inicio/total-respuesta-pregunta-26.resolve';
import { NombreRespuestaMayorPreguntaMultiple26Resolve } from './inicio/nombre-respuesta-mayor-pregunta-multiple-26.resolver';
import { PromedioRespuestaComplementaria25Resolve } from './inicio/promedio-respuesta-complementaria-25.resolver';
import { PuntajePromedioDimensionesResolve } from './inicio/puntaje-promedio-dimensiones.resolver';
import { PuntajePromedioEjeMedicionResolve } from './inicio/puntaje-promedio-eje-medicion.resolver';
import { TotalRespuestaPregunta5Resolve } from './inicio/total-respuesta-pregunta-5.resolver';
import { TotalRespuestaPregunta1Resolve } from './inicio/total-respuesta-pregunta-1.resolver';
import { TotalRespuestaPregunta10Resolve } from './inicio/total-respuesta-pregunta-10.resolver';
import { TotalRespuestaPregunta19Resolve } from './inicio/total-respuesta-pregunta-19.resolver';
import { TotalRespuestaPregunta39Resolve } from './inicio/total-respuesta-pregunta-39.resolver';
import { PuntajePromedioEjeMedicionGDResolve } from './inicio/puntaje-promedio-eje-medicion-GD.resolver';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { CasosEvaluadosComponent } from './casos-innovacion/casos-evaluados/casos-evaluados.component';
import { ListadoCasosMunicipioComponent } from './casos-innovacion/listado-casos-municipio/listado-casos-municipio.component';
import { ListadoCasosComponent } from './casos-innovacion/listado-casos/listado-casos.component';
import { PuntajePromedioEjeMedicionDTResolve } from './inicio/puntaje-promedio-eje-medicion-DT.resolver';
import { DetalleCasoInnovacionCargarRolesResolve } from './detalle-caso-innovacion/detalle-caso-innovacion.cargar-roles.resolve';
import { DescubreInnovacionComponent } from './descubre-innovacion/descubre-innovacion.component';
import { EstadoInnovacionComponent } from './estado-innovacion/estado-innovacion.component';
import { ComoSeMideComponent } from './como-se-mide/como-se-mide.component';
import { ListadoCasosInnovacionComponent } from './listado-casos-innovacion/listado-casos-innovacion.component';
import { ContactoComponent } from './contacto/contacto.component';
import { PerfilUsuarioComponent } from './usuarios/perfil-usuario/perfil-usuario.component';
import { PerfilUsuarioCargarUsuarioResolve } from './usuarios/perfil-usuario/perfil-usuario.cargar-usuario.resolve';


const routes: Routes = [
  {
      path: '', component: PrincipalComponent, canActivate: [AuthGuard],
      children: [
          { path: 'encuesta-institucional', component: EncuestaInstitucionalComponent, 
            resolve: {
              preguntas: EncuestaInstitucionalCuestionarioResolve, 
              respuestaValores: EncuestaInstitucionalRespuestaValoresResolve, 
              respuestasMultiples: EncuestaInstitucionalRespuestasMultiplesResolve
            }, 
            canActivate: [AuthGuard]
          },
          { path: 'perfil-usuario', component: PerfilUsuarioComponent, canActivate: [AuthGuard]},
          { path: 'eventos', component: EventosComponent, canActivate: [AuthGuard]},
          { path: 'instituciones', component: InstitucionesComponent, canActivate: [AuthGuard]},
          { path: 'usuarios', component: UsuariosComponent, canActivate: [AuthGuard]},
          { path: "casos-evaluados-municipio", component: CasosInnovacionComponent , canActivate: [AuthGuard]},
          { path: "casos-evaluados", component: CasosEvaluadosComponent , canActivate: [AuthGuard]},
          { path: "aprobar-casos", component: AprobarCasoComponent , canActivate: [AuthGuard]},
          { path: "listado-casos", component: ListadoCasosComponent , canActivate: [AuthGuard]},
          { path: "listado-casos-municipio", component: ListadoCasosMunicipioComponent , canActivate: [AuthGuard]},
          { path: "evaluar-casos", component: EvaluarCasosComponent , canActivate: [AuthGuard]},
          { path: "admision-casos", component: AdmisionCasosComponent , canActivate: [AuthGuard]},
          { path: "detalle-admisibilidad", component: DetalleAdmisibilidadComponent , canActivate: [AuthGuard]},
          { path: "descubre-innovacion", component: DescubreInnovacionComponent , canActivate: [AuthGuard]},
          { path: "estado-innovacion", component: EstadoInnovacionComponent , canActivate: [AuthGuard]},
          { path: "como-se-mide", component: ComoSeMideComponent , canActivate: [AuthGuard]},
          { path: "listado-casos-innovacion", component: ListadoCasosInnovacionComponent , canActivate: [AuthGuard]},
          { path: "contacto", component: ContactoComponent , canActivate: [AuthGuard]},
          { path: "detalle-evaluar-caso/:idCaso", component: DetalleEvaluarCasoComponent , 
            resolve: {
              casoInnovacion: DetalleEvaluarCasoCargarCasoResolve,
              rolesUsuario: DetalleCasoInnovacionCargarRolesResolve
            },
            canActivate: [AuthGuard]},
          { path: "crear-caso-innovacion", component: CrearCasoInnovacionComponent , 
            resolve: {
              instituciones: CrearCasoInnovacionResolve,
              tematicas: CatalogoResolve,
              provincias: CatalogoProvinciasResolve,
              regiones: CatalogoRegionesResolve
            },
            canActivate: [AuthGuard]},
          { path: "cuestionaro-caso-innovacion", component: CuestionaroCasoInnovacionComponent , canActivate: [AuthGuard]},
          { path: "detalle-caso-innovacion/:idCaso", component: DetalleCasoInnovacionComponent , 
            resolve: {
              casoInnovacion: DetalleCasoInnovacionCargarCasoResolve,
              rolesUsuario: DetalleCasoInnovacionCargarRolesResolve
            },canActivate: [AuthGuard]},
            { path: "perfil-usuario/:idUsuario", component: PerfilUsuarioComponent , 
            resolve: {
              usuario: PerfilUsuarioCargarUsuarioResolve
            },canActivate: [AuthGuard]},
          { path: "encuesta-iniciativa/:id", component: EncuestaIniciativaComponent , 
            resolve: {
              preguntas: EncuestaIniciativaCuestionarioResolve,
              respuestas: EncuestaIniciativaRespuestasResolve,
              respuestasMultiples: EncuestaIniciativaRespuestasMultiplesResolve
            }, canActivate: [AuthGuard]},
          { path: "banco-experiencias", component: BancoExperienciasComponent , canActivate: [AuthGuard]},
          { path: "inicio", component: InicioComponent , 
            resolve: {
              totalCasos: TotalCasosResolve,
              totalCasosEvaluados: TotalCasosEvaluadosResolve,
              totalUsuariosRegistrados: TotalUsuariosRegistradosResolve,
              totalInstitucionesRegistradas: TotalInstitucionesRegistradasResolve,
              totalRespuestaPregunta11: TotalRespuestaPregunta11Resolve,
              totalRespuestaPregunta15: TotalRespuestaPregunta15Resolve,
              totalRespuestaPregunta26: TotalRespuestaPregunta26Resolve,
              nombreRespuestaMayorPreguntaMultiple: NombreRespuestaMayorPreguntaMultiple26Resolve,
              promedioRespuestaComplementaria25: PromedioRespuestaComplementaria25Resolve,
              puntajePromedioDimensiones: PuntajePromedioDimensionesResolve,
              puntajePromedioEjeMedicion: PuntajePromedioEjeMedicionResolve,
              totalRespuestaPregunta5: TotalRespuestaPregunta5Resolve,
              totalRespuestaPregunta1: TotalRespuestaPregunta1Resolve,
              totalRespuestaPregunta10: TotalRespuestaPregunta10Resolve,
              totalRespuestaPregunta19: TotalRespuestaPregunta19Resolve,
              totalRespuestaPregunta39: TotalRespuestaPregunta39Resolve,
              puntajePromedioEjeMedicionGD: PuntajePromedioEjeMedicionGDResolve,
              puntajePromedioEjeMedicionDT: PuntajePromedioEjeMedicionDTResolve
            },canActivate: [AuthGuard]}
          /*{ path: 'solicitudes',  loadChildren: '../solicitudes/solicitudes.module#SolicitudesModule', canActivate: [AuthGuard]},
          { path: 'cajas', loadChildren: '../cajas/cajas.module#CajasModule', canActivate: [AuthGuard]},*/
          /*{path: '', component: InicioComponent, canActivate: [AuthGuard]},
          {path: 'mantenedores', loadChildren: '../mantenedores/mantenedores.module#MantenedoresModule', canActivate: [AuthGuard]},
          {path: 'registro-asistencia', component: RegistroAsistenciaComponent, canActivate: [AuthGuard]},
          {path: 'registro-aseo', component: RegistroAseoComponent, canActivate: [AuthGuard]},
          {path: 'informes', component: InformesComponent, canActivate: [AuthGuard]},
          {path: 'plan-nutricional', component: PlanNutricionalComponent, canActivate: [AuthGuard]},
      {path: 'menus', component: ComidasComponent, canActivate: [AuthGuard]}*/
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivadoRoutingModule { }
