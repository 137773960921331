import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { CasoInnovacionService } from "src/app/services/caso-innovacion.service";
import { UsuariosService } from "src/app/services/usuarios.service";

@Injectable()  
export class DetalleCasoInnovacionCargarRolesResolve implements Resolve<any[]> {  

  idUsuario: any;
  constructor(private usuarioService: UsuariosService,
              private route: ActivatedRoute) {
                this.idUsuario = JSON.parse(localStorage.getItem('usuario'));
  }  
  
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {  
      console.log('En el resolve DetalleCasoInnovacionCargarRolesResolve');
    return this.usuarioService.obtenerPerfilesAsignadosUsuariosRestangular(this.idUsuario);
  }  
} 