import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from 'ngx-toastr';
import { CasoInnovacions } from 'src/app/api/modelos/CasoInnovacion';
import { PreguntaRespuestas } from 'src/app/api/modelos/PreguntaRespuestas';

@Component({
  selector: 'app-encuesta-iniciativa',
  templateUrl: './encuesta-iniciativa.component.html',
  styleUrls: ['./encuesta-iniciativa.component.scss']
})
export class EncuestaIniciativaComponent implements OnInit {

  preguntasLista: any[] = [];
  respuestaValores: any[] = [];
  respuestaValoresMultiples: any[] = [];

  respuestaPreguntaBase: Restangular;

  finalizarCuestionario: boolean = false;

  idInstitucion: any;
  idCaso: any;

  mostrarDetallePregunta: boolean = true;
  

  constructor(private route: ActivatedRoute,
              private restangular: Restangular,
              private toastr: ToastrService,
              private router: Router) { 
    this.respuestaPreguntaBase = this.restangular.one('preguntarespuestas');
    this.idInstitucion = JSON.parse(localStorage.getItem('institucion')).id;
    this.idCaso = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {
    this.preguntasLista = this.route.snapshot.data.preguntas.data;
    this.respuestaValores = this.route.snapshot.data.respuestas;
    this.respuestaValoresMultiples = this.route.snapshot.data.respuestasMultiples;
  }

  borrarEncuesta(){
    this.restangular.all('cuestionario-caso/borrar/2/'+this.idCaso).get('').subscribe(
      data => {
          console.log(data.data);
          this.preguntasLista = data.data;
          //this.cargarPuntajes();
          this.restangular.all('preguntas?filter[cuestionario_id]=2&&sort=orden&&institucion=2&&caso='+this.idCaso).get('').subscribe( data => {
            this.preguntasLista = data.data;
          })
          /* this.preguntasService.getPosts().subscribe( preguntas => {
            this.preguntasLista = preguntas.data;
            this.preguntasService.getRespuestas().subscribe(respuestas => {
              this.respuestaValores = respuestas;
            });
          }); */
      });

  }

  guardarRespuesta(pregunta){
    console.log(pregunta);
    console.log(this.respuestaValores);

    let jsonApiWrapper = {
      data: {
          type: 'preguntarespuestas',
          attributes: null
      }

    };

    let preguntaRespuestaNueva: PreguntaRespuestas = {
      cuestionario_id: 2,
      pregunta_id: null,
      respuesta_id: null,
      respuesta_valor: null,
      puntaje: 0,
      respuesta_complementaria: null
    }
    
    let preguntaTemp = this.preguntasLista.find( elemento => elemento.id == pregunta.id); 

    //Selecciono la respuesta para rescatar el puntaje (solo para preguntas cerradas)   
    let respuestaTemp = preguntaTemp.attributes.grupo_respuestas.find(ele => ele.id == this.respuestaValores[pregunta.attributes.orden].respuesta);
    if(respuestaTemp){
      preguntaRespuestaNueva.puntaje = respuestaTemp.puntaje;
    } else {
      let respuestaTemp = preguntaTemp.attributes.grupo_respuestas.find(ele => ele.id == this.respuestaValoresMultiples[pregunta.attributes.orden].id);
      if(respuestaTemp){
        preguntaRespuestaNueva.puntaje = respuestaTemp.puntaje;
      }
    }

    if(pregunta.attributes.tipo == 'ABIERTA-MULTIPLE'){
      //Se recorren el grupo de respuestas para asignar por cada respuesta una valor
      preguntaTemp.attributes.grupo_respuestas.forEach(element => {
        //Se envía el id 2 de la respuesta por defecto para las preguntas de tipo abierta
        preguntaRespuestaNueva.cuestionario_id = 2;
        preguntaRespuestaNueva.pregunta_id = parseInt(pregunta.id);
        preguntaRespuestaNueva.respuesta_id = element.id;
        preguntaRespuestaNueva.puntaje = element.puntaje;
        preguntaRespuestaNueva.respuesta_valor = this.respuestaValoresMultiples[element.orden].respuesta_valor;
        preguntaRespuestaNueva.respuesta_complementaria = this.respuestaValoresMultiples[element.orden].respuesta_complementaria;
        preguntaRespuestaNueva.caso_innovacion_id = this.idCaso;
        jsonApiWrapper.data.attributes = preguntaRespuestaNueva;

        this.respuestaPreguntaBase.post('', jsonApiWrapper).subscribe(
          data => {
              console.log(data);
              
              this.restangular.all('preguntas?filter[cuestionario_id]=2&&institucion='+this.idInstitucion+'&&caso='+this.idCaso).get('').subscribe(
                data => {
                    console.log(data.data);
                    this.preguntasLista = data.data;
                    this.restangular.all('preguntas/iniciativa').get('2/'+this.idCaso).subscribe( lista => {
                      this.respuestaValores = lista
                    });
                    // this.cargarPuntajes();
                    if(this.preguntasLista[pregunta.attributes.orden + 1].attributes.dependencia.length == 0){
                      if(this.preguntasLista[pregunta.attributes.orden + 2].attributes.dependencia.length > 0){
                        this.finalizarCuestionario = false;
                        console.log(this.finalizarCuestionario);
                      } else {
                        this.finalizarCuestionario = true;
                      }
                    }
                    console.log(this.finalizarCuestionario);
                });
          }
      );
      });
      this.toastr.success('Se ha guardado correctamente la respuesta', 'Ok');
    }
    if(pregunta.attributes.tipo == 'CERRADA'){
      preguntaRespuestaNueva.cuestionario_id = 2;
      preguntaRespuestaNueva.pregunta_id = parseInt(pregunta.id);
      preguntaRespuestaNueva.respuesta_id = Number(this.respuestaValores[pregunta.attributes.orden].respuesta);
      preguntaRespuestaNueva.respuesta_complementaria = this.respuestaValores[pregunta.attributes.orden].respuesta_complementaria; 
      preguntaRespuestaNueva.caso_innovacion_id = this.idCaso;

      jsonApiWrapper.data.attributes = preguntaRespuestaNueva;

    this.respuestaPreguntaBase.post('', jsonApiWrapper).subscribe(
      data => {
          console.log(data);
          this.toastr.success('Se ha guardado correctamente la respuesta', 'Ok');
          this.restangular.all('preguntas?filter[cuestionario_id]=2&&institucion='+this.idInstitucion+'&&caso='+this.idCaso).get('').subscribe(
            data => {
                console.log(data.data);
                this.preguntasLista = data.data;
                console.log(this.preguntasLista);
                this.restangular.all('preguntas/iniciativa').get('2/'+this.idCaso).subscribe( lista => {
                  this.respuestaValores = lista;
                  console.log(this.preguntasLista[pregunta.attributes.orden + 1].dependencia);
                });
                
                // this.cargarPuntajes();
                console.log(this.preguntasLista);
                // Se agrega esta condición para comprobar la finalización del cuestionario
                if((this.preguntasLista.length -1) !=  pregunta.attributes.orden){
                  if(this.preguntasLista[pregunta.attributes.orden + 1].attributes.dependencia.length == 0){
                    if(this.preguntasLista[pregunta.attributes.orden + 2].attributes.dependencia.length > 0){
                      this.finalizarCuestionario = false;
                      console.log(this.finalizarCuestionario);
                    } else {
                      this.finalizarCuestionario = true;
                    }
                  }
                } else {
                  this.finalizarCuestionario = true;
                }
                
                console.log(this.finalizarCuestionario);
            });
      }
    );
    }

    if(pregunta.attributes.tipo == 'ABIERTA') {
      //Se envía el id 2 de la respuesta por defecto para las preguntas de tipo abierta
      preguntaRespuestaNueva.cuestionario_id = 2;
      preguntaRespuestaNueva.pregunta_id = parseInt(pregunta.id);
      preguntaRespuestaNueva.respuesta_id = 2;
      preguntaRespuestaNueva.respuesta_valor = this.respuestaValores[pregunta.attributes.orden].respuesta;
      preguntaRespuestaNueva.caso_innovacion_id = this.idCaso;
      jsonApiWrapper.data.attributes = preguntaRespuestaNueva;

        this.respuestaPreguntaBase.post('', jsonApiWrapper).subscribe(
          data => {
              console.log(data);
              this.toastr.success('Se ha guardado correctamente la respuesta', 'Ok');
              this.restangular.all('preguntas?filter[cuestionario_id]=2&&institucion='+this.idInstitucion+'&&caso='+this.idCaso).get('').subscribe(
                data => {
                    console.log(data.data);
                    this.preguntasLista = data.data;
                    this.restangular.all('preguntas/iniciativa').get('2/'+this.idCaso).subscribe( lista => {
                      this.respuestaValores = lista;
                    });
                    // this.cargarPuntajes();
                    if(this.preguntasLista[pregunta.attributes.orden + 1].attributes.dependencia.length == 0){
                      if(this.preguntasLista[pregunta.attributes.orden + 2].attributes.dependencia.length > 0){
                        this.finalizarCuestionario = false;
                        console.log(this.finalizarCuestionario);
                      } else {
                        this.finalizarCuestionario = true;
                      }
                    }
                    console.log(this.finalizarCuestionario);
                });
          }
      );
    } 

    /* jsonApiWrapper.data.attributes = preguntaRespuestaNueva;

    this.respuestaPreguntaBase.post('', jsonApiWrapper).subscribe(
      data => {
          console.log(data);
          this.toastr.success('Se ha guardado correctamente la respuesta', 'Ok');
          this.restangular.all('preguntas?filter[cuestionario_id]=1').get('').subscribe(
            data => {
                console.log(data.data);
                this.preguntasLista = data.data;
                this.cargarPuntajes();
            });
      }
  ); */
  }

  finalizarEncuesta(){
    console.log();
    let jsonApiWrapper = {
      data: {
          type: 'casoinnovacions',
          attributes: {
              estado: 'En-validacion'
          },
          id: ''
      }
  };
  jsonApiWrapper.data.id = this.idCaso;
  this.restangular.all('casoinnovacions/' + this.idCaso).patch(jsonApiWrapper).subscribe(
      data => {
        this.toastr.success('La encuesta se ha enviado correctamente', 'Ok');
        this.router.navigateByUrl('listado-casos-municipio');
        //this.cargarCasos();
      },
      error => {
        this.toastr.error('Hubo un error al enviar la encuesta', 'Error');
      }
  );
  }

  mostrarPregunta(pregunta){
    console.log('mostrando pregunta');
    if(this.mostrarDetallePregunta){
      this.mostrarDetallePregunta = false;
    } else {
      this.mostrarDetallePregunta = true;
    }
    console.log(this.mostrarDetallePregunta);
  }
}
