import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalle-admisibilidad',
  templateUrl: './detalle-admisibilidad.component.html',
  styleUrls: ['./detalle-admisibilidad.component.scss']
})
export class DetalleAdmisibilidadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
