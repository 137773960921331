import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dragula',
  templateUrl: './dragula.component.html',
  styleUrls: ['./dragula.component.scss']
})
export class DragulaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
