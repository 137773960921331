import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/api/modelos/Usuario';
import * as bcrypt from "bcryptjs";
import { Login } from 'src/app/api/modelos/Login';
import { Router } from '@angular/router';
import { Restangular } from 'ngx-restangular';
import { ToastrService } from "ngx-toastr";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login-invitado',
  templateUrl: './login-invitado.component.html',
  styleUrls: ['./login-invitado.component.scss']
})
export class LoginInvitadoComponent implements OnInit {

  nuevoUsuario: Usuario = {
    nombre_usuario: '',
    apellidos: '',
    contrasena: '',
    email: '',
    nombres: '',
    rut: '',
    estado: 'S',
    tipo: 'Usuario',
    discapacidad: 'N',
    dv: '0',
    institucion_id: 1
  }

  loginDatos: Login = {
    rut: "11111111-1",
    contrasena: "demo",
  };

  token: any;
  loginBase: Restangular;

  constructor(private route: Router, private restangular: Restangular,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal) { 
    this.loginBase = this.restangular.one("login");
  }

  ngOnInit(): void {
  }

  entrar() {
    const salt = bcrypt.genSaltSync(10);
    /*this.loginDatos.contrasena = bcrypt.hashSync(this.loginDatos.contrasena, salt);*/
    console.log(bcrypt.hashSync(this.loginDatos.contrasena, salt));
    this.loginBase.post("", this.loginDatos).subscribe(
      (data) => {
        console.log(data);
        this.token = data;
        localStorage.setItem("token", this.token.token);
        localStorage.setItem("usuario", JSON.stringify(this.token.usuario));
        localStorage.setItem(
          "institucion",
          JSON.stringify(this.token.institucion)
        );
        localStorage.setItem("cuestionario", "1");
        if (this.token != null) {
          //this.route.navigate(['']);
          this.route.navigate([""]);
        }
        this.activeModal.close();
      },
      (err) => {
        this.toastr.error(err.statusText, 'Error');
        // console.log(err.statusText);
      }
    );
  }

}
